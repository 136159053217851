import React, { useEffect, useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  Row,
  Col,
  Typography,
  message,
  Modal,
  DatePicker,
  Spin,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";

const { Title } = Typography;
const { confirm } = Modal;

function EditProject() {
  const location = useLocation();
  const { site } = location.state;
  const projectname = site.projectname || "";
  const id = site.id || "";

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const accountid = localStorage.getItem("accountid");

  const [loading, setLoading] = useState(false);

  message.config({
    top: 700,
  });

  useEffect(() => {
    const fetchProjectDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/project/getproject/${id}?accountid=${accountid}`
        );
        const matchedData = response.data.project;

        if (matchedData) {
          form.setFieldsValue({
            id: matchedData.id,
            projectname: matchedData.projectname,
            tendervalue: matchedData.tendervalue,
            startdate: dayjs(matchedData.startdate),
            enddate: dayjs(matchedData.enddate),
            stipulateddate: dayjs(matchedData.stipulateddate),
            location: matchedData.location,
            descriptions: matchedData.descriptions,
          });
        }
      } catch (error) {
        message.error("Internal Server Error");
      } finally {
        setLoading(false);
      }
    };

    fetchProjectDetails();
  }, [accountid, id, form]);

  const handleSubmit = async (values) => {
    const {
      projectname,
      tendervalue,
      startdate,
      enddate,
      location,
      stipulateddate,
      descriptions,
    } = values;

    try {
      const response = await axios.put(
        `${process.env.React_App_API_URL}/project/updateproject/${id}?accountid=${accountid}`,
        {
          projectname,
          accountid,
          tendervalue,
          startdate: startdate.format("YYYY-MM-DD"),
          enddate: enddate.format("YYYY-MM-DD"),
          location,
          stipulateddate: stipulateddate.format("YYYY-MM-DD"),
          descriptions,
        }
      );

      if (response.status === 200) {
        message.success("Project updated successfully");
        navigate("/project");
      } else {
        message.error("Failed to update project");
      }
    } catch (error) {
      message.error("Internal Server Error");
    }
  };

  const handleCancel = () => {
    navigate("/project");
  };

  const showDeleteConfirm = () => {
    confirm({
      title: (
        <span style={{ color: "#001233" }}>
          Are you sure you want to delete this project?
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <span style={{ color: "#001233" }}>
          'This action cannot be undone'{" "}
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: {
        style: {
          backgroundColor: "#E76F51",
          borderColor: "#E76F51",
          color: "#001233",
          fontWeight: "bold",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: "#D9DCE1",
          borderColor: "#D9DCE1",
          color: "#001233",
          fontWeight: "bold",
        },
      },
      onOk() {
        handleDeleteProject();
      },
      onCancel() {},
    });
  };

  const handleDeleteProject = async () => {
    try {
      const response = await axios.delete(
        `${process.env.React_App_API_URL}/project/deleteproject/${id}?accountid=${accountid}`
      );

      if (response.status === 200) {
        message.success("Project deleted successfully");
        navigate("/project");
      } else {
        message.error("Failed to delete project");
      }
    } catch (error) {
      message.error("Internal Server Error");
    }
  };

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span className="employee">Update Project</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            className="Exit"
            icon={<CloseOutlined />}
            onClick={handleCancel}
          >
            Exit
          </Button>
          <Button
            className="customDeleteButton"
            icon={<DeleteOutlined />}
            onClick={showDeleteConfirm}
          >
            <span>Delete</span>
          </Button>
        </div>
      </div>

      <div style={{ backgroundColor: "#f0f2f5", padding: "50px" }}>
        <Spin spinning={loading}>
          <div
            style={{
              padding: "20px",
              maxWidth: "700px",
              margin: "auto",
              backgroundColor: "#ffffff",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={4} className="title-color">
                  Project Details
                </Title>
              </Col>
              <Col span={24}>
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={handleSubmit}
                  initialValues={{
                    projectname: projectname,
                    id: id,
                  }}
                >
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Project ID</span>}
                        name="id"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Project ID",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Project Name</span>}
                        name="projectname"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Project Name",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Tender Value</span>}
                        name="tendervalue"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Tender Value",
                          },
                        ]}
                      >
                        <Input prefix="₹" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Start Date</span>}
                        name="startdate"
                        rules={[
                          {
                            required: true,
                            message: "Please select Start Date",
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD-MMM-YYYY"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">End Date</span>}
                        name="enddate"
                        rules={[
                          {
                            required: true,
                            message: "Please select End Date",
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD-MMM-YYYY"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={
                          <span className="label-name">
                            Stipulated End Date
                          </span>
                        }
                        name="stipulateddate"
                        rules={[
                          {
                            required: true,
                            message: "Please select Stipulated End Date",
                          },
                        ]}
                      >
                        <DatePicker
                          format="DD-MMM-YYYY"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={<span className="label-name">Location</span>}
                        name="location"
                        rules={[
                          { required: true, message: "Please enter Location" },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col xs={24}>
                      <Form.Item
                        label={<span className="label-name">Descriptions</span>}
                        name="descriptions"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Descriptions",
                          },
                        ]}
                      >
                        <Input.TextArea rows={4} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Spin>
        <Col span={24} style={{ maxWidth: "700px", margin: "auto" }}>
          <Button
            className="newcustomAddButton"
            icon={<PlusOutlined />}
            onClick={() => form.submit()}
          >
            <span className="newaddEmployee">Save</span>
          </Button>
        </Col>
      </div>
    </>
  );
}

export default EditProject;
