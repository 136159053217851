// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-size {
  width: 110px !important;
  height: 110px !important;
  height: auto;
  border-radius: 20px;
  object-fit: cover;
  margin: auto;
}

.image-selected {
  margin-top: 8px;
  color: #e76f51;
  font-size: 12px;
  font-weight: bold;
}

.company-box,
.personal-box {
  padding: 20px;
  max-width: 860px;
  margin: auto;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.company-box {
  height: auto;
}

.personal-box {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .company-box,
  .personal-box {
    padding: 10px;
  }

  .image-size {
    width: 80px;
  }

  .newcustomAddButton {
    width: 100%;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AdminProfie/profile.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,wBAAwB;EACxB,YAAY;EACZ,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;;EAEE,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,yBAAyB;EACzB,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;;IAEE,aAAa;EACf;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,WAAW;IACX,kBAAkB;EACpB;AACF","sourcesContent":[".image-size {\n  width: 110px !important;\n  height: 110px !important;\n  height: auto;\n  border-radius: 20px;\n  object-fit: cover;\n  margin: auto;\n}\n\n.image-selected {\n  margin-top: 8px;\n  color: #e76f51;\n  font-size: 12px;\n  font-weight: bold;\n}\n\n.company-box,\n.personal-box {\n  padding: 20px;\n  max-width: 860px;\n  margin: auto;\n  background-color: #ffffff;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  border-radius: 8px;\n}\n\n.company-box {\n  height: auto;\n}\n\n.personal-box {\n  margin-bottom: 20px;\n}\n\n@media (max-width: 768px) {\n  .company-box,\n  .personal-box {\n    padding: 10px;\n  }\n\n  .image-size {\n    width: 80px;\n  }\n\n  .newcustomAddButton {\n    width: 100%;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
