import React, { useState } from "react";
import { Button, Breadcrumb, DatePicker, Spin, Dropdown, Menu } from "antd";
import { DownloadOutlined, CalendarOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./reportNavBar.css";
import axios from "axios";
import jsPDF from "jspdf";
const accountid = localStorage.getItem("accountid");
const EmployeeVehicleFilterNavbar = ({ startDate, endDate, onDateChange }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const navigateToReport = () => {
    navigate("/report");
  };

  const handleExportToPdf = async () => {
    setLoading(true);
    try {
      let url = `${process.env.React_App_API_URL}/reports/getAttendances?accountid=${accountid}`;
      if (startDate) url += `&startdate=${startDate.format("YYYY-MM-DD")}`;
      if (endDate) url += `&enddate=${endDate.format("YYYY-MM-DD")}`;

      const response = await axios.get(url);
      const attendanceData = response.data.data;

      const doc = new jsPDF();
      const tableColumn = [
        "Sr. No",
        "Employee Name",
        "Phone Number",
        "Role",
        "Project Name",
        "Attendance Date",
        "Status",
      ];
      const tableRows = [];

      let index = 1;

      // Process present employees
      attendanceData.present.forEach((presentEntry) => {
        presentEntry.present_employees.forEach((employee) => {
          const rowData = [
            index++,
            employee.name,
            employee.phonenumber,
            employee.role_name,
            employee.projectname || "N/A",
            presentEntry.attendance_date,
            "Present",
          ];
          tableRows.push(rowData);
        });
      });

      // Process absent employees
      attendanceData.absent.forEach((absentEntry) => {
        absentEntry.absent_employees.forEach((employee) => {
          employee.projects.forEach((project) => {
            const rowData = [
              index++,
              employee.name,
              employee.phonenumber,
              employee.role_name,
              project.project_name,
              absentEntry.attendance_date,
              "Absent",
            ];
            tableRows.push(rowData);
          });
        });
      });

      doc.setFillColor(240, 240, 240);
      doc.rect(10, 10, 190, 10, "F");

      // Set the text color to black
      doc.setTextColor(0, 0, 0);
      doc.text("Attendance Report", 80, 17);
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 30,
        theme: "grid",
        tableWidth: "auto",
      });

      // Add logo to each page at right bottom corner
      const logo = `${process.env.PUBLIC_URL}/logopdf.png`;
      const logoWidth = 30;
      const logoHeight = 10;
      const marginRight = 15;
      const marginBottom = 5;
      const marginTop = 1;
      const x = doc.internal.pageSize.width - logoWidth - marginRight;
      const y =
        doc.internal.pageSize.height - logoHeight - marginBottom - marginTop;
      doc.addImage(logo, "PNG", x, y, logoWidth, logoHeight);

      doc.save("Attendance_Report.pdf");
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportToCsv = async () => {
    setLoading(true);
    try {
      let url = `${process.env.React_App_API_URL}/reports/getAttendances?accountid=${accountid}`;
      if (startDate) url += `&startdate=${startDate.format("YYYY-MM-DD")}`;
      if (endDate) url += `&enddate=${endDate.format("YYYY-MM-DD")}`;

      const response = await axios.get(url);
      const attendanceData = response.data.data;

      const csvRows = [];

      // Process present employees
      attendanceData.present.forEach((presentEntry) => {
        presentEntry.present_employees.forEach((employee) => {
          const rowData = [
            employee.name,
            employee.phonenumber,
            employee.role_name,
            employee.projectname || "N/A",
            presentEntry.attendance_date,
            "Present",
          ];
          csvRows.push(rowData.join(","));
        });
      });

      // Process absent employees
      attendanceData.absent.forEach((absentEntry) => {
        absentEntry.absent_employees.forEach((employee) => {
          employee.projects.forEach((project) => {
            const rowData = [
              employee.name,
              employee.phonenumber,
              employee.role_name,
              project.project_name,
              absentEntry.attendance_date,
              "Absent",
            ];
            csvRows.push(rowData.join(","));
          });
        });
      });

      const csvContent =
        `Employee Name,Phone Number,Role,Project Name,Attendance Date,Status\n` +
        csvRows.join("\n");

      const csvBlob = new Blob([csvContent], { type: "text/csv" });
      const csvUrl = URL.createObjectURL(csvBlob);
      const csvLink = document.createElement("a");
      csvLink.href = csvUrl;
      csvLink.download = "Attendance_Report.csv";
      csvLink.click();
    } catch (error) {
      console.error("Error fetching attendance data: ", error);
    } finally {
      setLoading(false);
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "pdf",
          label: "Export to PDF",
          onClick: handleExportToPdf,
          className: "export-menu-item",
        },
        {
          key: "csv",
          label: "Export to CSV",
          onClick: handleExportToCsv,
          className: "export-menu-item",
        },
      ]}
    />
  );
  const handleStartDateChange = (date) => {
    onDateChange(date, endDate);
  };

  const handleEndDateChange = (date) => {
    onDateChange(startDate, date);
  };

  return (
    <div className="FilterEmployeeVehicle">
      <div className="breadcrumb-container">
        <Breadcrumb separator=">">
          <Breadcrumb.Item onClick={navigateToReport}>
            <span className="breadcrumb-reports">Reports</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="breadcrumb-fleet-management">Attendance</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="right-container">
        <div className="custom-date-range-picker">
          <DatePicker
            format="DD-MMM-YYYY"
            value={startDate}
            onChange={handleStartDateChange}
            suffixIcon={<CalendarOutlined />}
          />
          <span className="separator">→</span>
          <DatePicker
            format="DD-MMM-YYYY"
            value={endDate}
            onChange={handleEndDateChange}
            suffixIcon={<CalendarOutlined />}
          />
        </div>
        <Spin spinning={loading}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              type="primary"
              className="customExportButton"
              icon={<DownloadOutlined />}
              disabled={loading}
            >
              Export
            </Button>
          </Dropdown>
        </Spin>
      </div>
    </div>
  );
};

export default EmployeeVehicleFilterNavbar;
