import React, { useState } from "react";
import { Row, Col } from "antd";
import EmployeeFilterMap from "./EmployeeFilterMap";
import LiveLocation from "../../../LiveLocation";
import EmployeeSideBar from "../EmployeeSideBar/EmployeeSideBar";

function EmployeeMapDasboard() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedEmployeeSidebar, setSelectedEmployeeSidebar] = useState(null);

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleClearFilters = () => {
    setSelectedProject(null);
    setSelectedRole(null);
    setSelectedStatus(null);
    setSearchQuery("");
  };

  const handleEmployeeSidebarSelect = (employee) => {
    setSelectedEmployeeSidebar(employee);
  };

  const handleClearSelectedEmployeeSidebar = () => {
    setSelectedEmployeeSidebar(null);
  };

  return (
    <>
      <EmployeeFilterMap
        onProjectSelect={handleProjectSelect}
        onRoleSelect={handleRoleSelect}
        onStatusSelect={handleStatusSelect}
        onClearFilters={handleClearFilters}
        onSearch={handleSearch}
        selectedProject={selectedProject}
        selectedRole={selectedRole}
        selectedStatus={selectedStatus}
        searchQuery={searchQuery}
      />
      <Row>
        <Col span={18} style={{ maxWidth: "65%" }}>
          <LiveLocation
            selectedProject={selectedProject}
            selectedRole={selectedRole}
            selectedStatus={selectedStatus}
            searchQuery={searchQuery}
            EmployeeSideBarClick={handleEmployeeSidebarSelect}
            selectedEmployeeSidebar={selectedEmployeeSidebar}
            setSelectedEmployeeSidebar={handleClearSelectedEmployeeSidebar}
          />
        </Col>
        <Col span={6} style={{ minWidth: "35%" }}>
          <EmployeeSideBar
            selectedProject={selectedProject}
            selectedRole={selectedRole}
            selectedStatus={selectedStatus}
            searchQuery={searchQuery}
            EmployeeSideBarClick={handleEmployeeSidebarSelect}
            selectedEmployeeSidebar={selectedEmployeeSidebar}
          />
        </Col>
      </Row>
    </>
  );
}

export default EmployeeMapDasboard;
