import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const GpsSocketContext = createContext();

export const useGpsSocket = () => useContext(GpsSocketContext);

export const GpsSocketProvider = ({ children }) => {
  const [gpsSocket, setGpsSocket] = useState(null);

  useEffect(() => {
    const accountid = localStorage.getItem("accountid");
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn && accountid) {
      const socketUrl = `${process.env.REACT_APP_GPS_SERVICE_URL}/civilbrain-gps`;
      const newGpsSocket = io(socketUrl, {
        query: { accountid },
      });
      setGpsSocket(newGpsSocket);

      newGpsSocket.emit("joinRoom", accountid);

      newGpsSocket.on("gps_update", (data) => {
        console.log("Received GPS update:", data);
      });

      return () => {
        newGpsSocket.emit("leaveRoom", accountid);
        newGpsSocket.disconnect();
        setGpsSocket(null);
      };
    }
  }, []);

  return (
    <GpsSocketContext.Provider value={gpsSocket}>
      {children}
    </GpsSocketContext.Provider>
  );
};
