import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import "./vehichlereportTable.css";
import axios from "axios";

const columns = [
  {
    title: "Sr No.",
    dataIndex: "serialNumber",
    key: "serialNumber",
    width: 80,
    render: (text, record, index) => (
      <span className="customTextColor">{index + 1}</span>
    ),
  },
  {
    title: "Reg No.",
    dataIndex: "regno",
    key: "regno",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Vin No.",
    dataIndex: "vehiclenumber",
    key: "vehiclenumber",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Driver Name",
    dataIndex: "drivername",
    key: "drivername",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Vehicle Type",
    dataIndex: "vehicletype",
    key: "vehicletype",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Vehicle Status",
    dataIndex: "vehiclestatus",
    key: "vehiclestatus",
    render: (vehiclestatus, record) => (
      <Tag
        color={
          vehiclestatus === false
            ? "red"
            : vehiclestatus === true && record.speed > 0
            ? "green"
            : vehiclestatus === true && record.speed === 0
            ? "gold"
            : "default"
        }
      >
        {vehiclestatus === false
          ? "Stopped"
          : vehiclestatus === true && record.speed > 0
          ? "Moving"
          : vehiclestatus === true && record.speed === 0
          ? "Idle"
          : "Unknown"}
      </Tag>
    ),
  },
  {
    title: "Current Location",
    dataIndex: "lastlocation",
    key: "lastlocation",
    render: (location) => (
      <span>
        <EnvironmentOutlined style={{ color: "#E76F51" }} />{" "}
        <span className="currentlocationColor">{location}</span>
      </span>
    ),
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Material Types*",
    dataIndex: "material ",
    key: "material",
    render: (text) => <span className="customTextColor">Empty</span>,
  },
  {
    title: "Fuel Level*",
    dataIndex: "level",
    key: "level",
    render: (text) => <span className="customTextColor">65%</span>,
  },
  {
    title: "Fuel Efficiency* ",
    dataIndex: "efficiency ",
    key: "efficiency",
    render: (text) => <span className="customTextColor">3 Km/L</span>,
  },

  {
    title: "Max Speed (kms/hr)",
    dataIndex: "maxspeed",
    key: "maxspeed",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Distance(kms)",
    dataIndex: "distance",
    key: "distance",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
];

function VehicleTable({
  selectedSpeed,
  selectedVehicleType,
  searchText,
  selectedStatus,
  startDate,
  endDate,
}) {
  const [vehicleData, setVehicleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // console.log(startDate,endDate);
      try {
        let url = `${process.env.React_App_API_URL}/reports/fleetmanagement?accountid=${accountid}`;
        if (selectedSpeed) {
          url += `&maxspeed=${selectedSpeed}`;
        }
        if (startDate && endDate) {
          url += `&startdate=${startDate.format(
            "YYYY-MM-DD"
          )}&enddate=${endDate.format("YYYY-MM-DD")}`;
        }
        if (selectedVehicleType) {
          url += `&vtype=${selectedVehicleType}`;
        }
        const response = await axios.get(url);
        const fetchedData = response.data;
        if (fetchedData && fetchedData.data && fetchedData.data.results) {
          let filteredData = fetchedData.data.results;
          if (searchText) {
            filteredData = filteredData.filter(
              (item) =>
                item.vehiclenumber
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                item.drivername.toLowerCase().includes(searchText.toLowerCase())
            );
          }
          if (selectedStatus) {
            filteredData = filteredData.filter((item) => {
              if (
                selectedStatus === "Moving" &&
                item.vehiclestatus === true &&
                item.speed > 0
              ) {
                return true;
              }
              if (
                selectedStatus === "Idle" &&
                item.vehiclestatus === true &&
                item.speed === 0
              ) {
                return true;
              }
              if (
                selectedStatus === "Stopped" &&
                item.vehiclestatus === false
              ) {
                return true;
              }
              return false;
            });
          }
          const vehicleDataWithKeys = filteredData.map((item, index) => ({
            ...item,
            key: index,
          }));
          setVehicleData(vehicleDataWithKeys);
        } else {
          console.error(
            "Error: Fetched data is not in the expected format:",
            fetchedData
          );
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    accountid,
    selectedSpeed,
    selectedVehicleType,
    searchText,
    selectedStatus,
    startDate,
    endDate,
  ]);
  return (
    <Table
      columns={columns}
      dataSource={vehicleData}
      pagination={{ pageSize: 100, showSizeChanger: false }}
      bordered
      style={{ margin: "20px" }}
      className="custom-table-header"
      loading={loading}
      scroll={{ x: 1000, y: 430 }}
    />
  );
}

export default VehicleTable;
