// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btnViewVehicle {
  padding: 8px 20px;
  width: 100%;
  max-width: 100%;
  height: 40px;
  color: #e76f51;
  font-weight: bold;
  background: #ffffff;
  border: 1px solid #d9dce1;
  border-radius: 8px;
  box-sizing: border-box;
}

.btnViewVehicle:hover {
  background-color: #e76f51 !important;
  color: white !important;
  border: none !important;
}

.image-class {
  width: 200px; /* Set the desired width */
  height: 200px; /* Set the desired height */
  object-fit: cover; /* Ensures the image covers the set dimensions without distortion */
}

.update {
  color: #7f8898;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/ProjectSites/ProjectView/ActivityLog.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,eAAe;EACf,YAAY;EACZ,cAAc;EACd,iBAAiB;EACjB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,sBAAsB;AACxB;;AAEA;EACE,oCAAoC;EACpC,uBAAuB;EACvB,uBAAuB;AACzB;;AAEA;EACE,YAAY,EAAE,0BAA0B;EACxC,aAAa,EAAE,2BAA2B;EAC1C,iBAAiB,EAAE,mEAAmE;AACxF;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".btnViewVehicle {\n  padding: 8px 20px;\n  width: 100%;\n  max-width: 100%;\n  height: 40px;\n  color: #e76f51;\n  font-weight: bold;\n  background: #ffffff;\n  border: 1px solid #d9dce1;\n  border-radius: 8px;\n  box-sizing: border-box;\n}\n\n.btnViewVehicle:hover {\n  background-color: #e76f51 !important;\n  color: white !important;\n  border: none !important;\n}\n\n.image-class {\n  width: 200px; /* Set the desired width */\n  height: 200px; /* Set the desired height */\n  object-fit: cover; /* Ensures the image covers the set dimensions without distortion */\n}\n\n.update {\n  color: #7f8898;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
