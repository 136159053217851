import React, { useState, useRef, useEffect } from "react";
import { Form, Input, Button, Typography, message, Modal, Card } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SignIn.css";
import CivilBrainlogo from "../assets/SignUp/Civil Brain Logo Color.svg";

const { Title, Paragraph, Text } = Typography;

function Forgot() {
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [timer, setTimer] = useState(0);
  const [resendEnabled, setResendEnabled] = useState(true);

  const otpRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (timer === 0) return;

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1000) {
          // Close to zero
          clearInterval(interval);
          setResendEnabled(true);
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const startTimer = () => {
    setResendEnabled(false);
    setTimer(1 * 60 * 1000);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `Resend in ${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}. `;
  };

  const onFinish = async (values) => {
    try {
      const response = await axios.post(
        `${process.env.React_App_API_URL}/otp/sendOTP/forget-password`,
        {
          email: values.email,
        }
      );
      console.log("OTP sent:", response.data);
      setEmail(values.email);
      setIsOtpModalVisible(true);
      setIsResetPassword(false);
      startTimer();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Failed to send OTP. Please try again.";
      message.error(errorMessage);
      console.error("Error sending OTP:", error);
    }
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");

    if (/^\d{6}$/.test(pastedData)) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);
      otpRefs.current[otp.length - 1].focus();
    } else {
      message.error("Please paste a valid 6-digit OTP.");
    }
  };

  const handleOtpVerification = async () => {
    const otpValue = otp.join("");
    try {
      const response = await axios.post(
        `${process.env.React_App_API_URL}/otp/verifyOTP`,
        {
          email,
          otp: otpValue,
        }
      );
      console.log("OTP verified:", response.data);
      if (response.data.success) {
        setIsOtpModalVisible(false);
        setIsResetPassword(true);
      } else {
        message.error(response.data.error || "Invalid OTP. Please try again.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        message.error(error.response.data.error);
      } else {
        message.error("Failed to verify OTP. Please try again.");
      }
      console.error("Error verifying OTP:", error);
    }
  };

  const handleResetPassword = async () => {
    if (password !== confirmPassword) {
      message.error("Passwords do not match!");
      return;
    }
    try {
      const response = await axios.patch(
        `${process.env.React_App_API_URL}/admin/forgetpassword`,
        {
          email,
          newPassword: password,
          confirmPassword,
        }
      );
      console.log("Password reset:", response.data);
      if (response.data) {
        message.success("Password reset successfully!");
        navigate("/");
      } else {
        message.error("Failed to reset password. Please try again.");
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        message.error(error.response.data.error);
      } else {
        message.error("Failed to reset password. Please try again.");
      }
      console.error("Error resetting password:", error);
    }
  };

  const resendOtp = async () => {
    if (resendEnabled) {
      await onFinish({ email });
      startTimer();
    }
  };

  return (
    <div className="sign-in-page">
      <img src={CivilBrainlogo} alt="CivilBrain Logo" className="logo" />
      <Card className="sign-in-card">
        <Title level={2} className="sign-in-title">
          {isResetPassword ? "Reset your password" : "Forgot your password?"}
        </Title>
        <Paragraph className="sign-in-text">
          {isResetPassword
            ? "Enter your new password below."
            : "Enter your email address and we will send you an OTP to reset your password."}
        </Paragraph>
        <Form
          name="forgotPassword"
          onFinish={isResetPassword ? handleResetPassword : onFinish}
          layout="vertical"
          className="sign-in-form"
        >
          {!isResetPassword ? (
            <>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  { required: true, message: "Please input your email!" },
                  { type: "email", message: "Please enter a valid email!" },
                ]}
                className="sign-in-form-item"
              >
                <Input />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" className="sign-in-button">
                  Send OTP
                </Button>
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label="New Password"
                rules={[
                  {
                    required: true,
                    message: "Please input your new password!",
                  },
                ]}
                className="sign-in-form-item"
              >
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter new password"
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your new password!",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value || value === password) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
                className="sign-in-form-item"
              >
                <Input.Password
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm new password"
                />
              </Form.Item>

              <Form.Item>
                <Button htmlType="submit" className="sign-in-button">
                  Submit
                </Button>
              </Form.Item>
            </>
          )}
        </Form>
      </Card>
      <Typography.Paragraph className="sign-in-text">
        Back to login page <a href="/">Sign in</a>
      </Typography.Paragraph>

      {/* OTP Modal */}
      <Modal
        title="Verify OTP"
        visible={!isResetPassword && isOtpModalVisible}
        onCancel={() => setIsOtpModalVisible(false)}
        footer={null}
        centered
        className="otp-modal"
        maskClosable={false}
      >
        <div className="otp-verification">
          <Text className="verification-email-text">
            We've sent an OTP to <strong>{email}</strong> to verify your email.
          </Text>
          <div className="otp-inputs">
            {otp.map((digit, index) => (
              <Input
                key={index}
                ref={(el) => (otpRefs.current[index] = el)}
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                maxLength={1}
                onPaste={handleOtpPaste}
                className="otp-input"
              />
            ))}
          </div>
          <div className="otp-timer">
            <span>{formatTime(timer)}</span>
            <span
              className="resend-btn"
              onClick={resendOtp}
              style={{
                color: "#E76F51",
                cursor: "pointer",
              }}
            >
              Resend OTP
            </span>
          </div>

          <Button
            className="cancel-btn"
            onClick={() => setIsOtpModalVisible(false)}
          >
            Cancel
          </Button>
          <Button className="verify-btn" onClick={handleOtpVerification}>
            Verify
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default Forgot;
