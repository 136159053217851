import React, { useEffect, useState } from "react";
import { Button, Breadcrumb, Tag,Spin } from "antd";
import { DownloadOutlined, EditFilled } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import jsPDF from 'jspdf';

const ProjectViewNavbar = () => {
  const [isActive, setIsActive] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const site = location.state?.site || {
    projectname: "Project name",
    id: null,
  };
  
  const { projectname, id } = site;
  const accountid = localStorage.getItem("accountid");

  const handleExportToPdf = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.React_App_API_URL}/project/getproject/${id}?accountid=${accountid}`);
      const project = response.data.project;
  
      const doc = new jsPDF();
      const tableColumn = ["Field", "Value"];
      const tableRows = [];
  
      const projectData = [
        ["Project Name", project.projectname],
        ["Tender Value", project.tendervalue],
        ["Start Date", new Date(project.startdate).toLocaleDateString()],
        ["End Date", new Date(project.enddate).toLocaleDateString()],
        ["Stipulated Date", new Date(project.stipulateddate).toLocaleDateString()],
        ["Location", project.location],
        ["Description", project.descriptions],
        ["Project Status", project.isdeleted ? "Completed" : "Active"],
        ["Total Employees", response.data.totalEmployees],
        ["Total Vehicles", response.data.totalVehicles],
      ];
  
      projectData.forEach(data => tableRows.push(data));
  
      doc.setFillColor(240, 240, 240);
      doc.rect(10, 10, 190, 10, 'F');
  
      doc.setTextColor(0, 0, 0);
      doc.text('Project Details', 80, 17);
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 30,
        theme: 'grid',
        tableWidth: 'auto',
      });
  
      // Fetch project activity details
      const activityResponse = await axios.get(`${process.env.React_App_API_URL}/project/viewprojectactivity/${id}?accountid=${accountid}`);
      const activityData = activityResponse.data.data;
  
      // Add activity details to PDF
      doc.addPage();
      doc.text('Project Activity Details', 80, 17);
     
      // Add images with names and activity details to PDF
      let imageYPosition = 30;
      for (const activity of activityData) {
        for (const image of activity.images) {
          if (imageYPosition > 250) {
            doc.addPage();
            imageYPosition = 10;
          }
          const createdAt = new Date(activity.created_at);
          createdAt.setHours(createdAt.getHours() + 5);
          createdAt.setMinutes(createdAt.getMinutes() + 30);
          const createdAtString = `${createdAt.toLocaleDateString()} ${createdAt.toLocaleTimeString()}`;
          const activityDetailsLines = doc.splitTextToSize(`Activity: ${activity.activitydetails}`, 180);
        
          // Add name and activity details
          doc.setFontSize(10);
          doc.text(`Updated by : ${activity.name}`, 10, imageYPosition);
          
          doc.text(`Date & Time: ${createdAtString}`, 10, imageYPosition + 5);
          doc.text(activityDetailsLines, 10, imageYPosition + 10);
          imageYPosition += 20 + (activityDetailsLines.length * 5);
          
          const imageUrl = `${process.env.React_App_API_URL}/project/stream-image/${image.fileName}`;
          const imageResponse = await axios.get(imageUrl, { responseType: 'arraybuffer' });
          const imageBlob = new Blob([imageResponse.data], { type: 'image/jpeg' });
          const imageUrlBlob = URL.createObjectURL(imageBlob);
          doc.addImage(imageUrlBlob, 'JPEG', 10, imageYPosition, 100, 100);
          imageYPosition += 110;
        }
      }
  
      const logo = `${process.env.PUBLIC_URL}/logopdf.png`;
      const logoWidth = 30;
      const logoHeight = 10;
      const marginRight = 15;
      const marginBottom = 5;
      const marginTop = 1;
      const x = doc.internal.pageSize.width - logoWidth - marginRight;
      const y = doc.internal.pageSize.height - logoHeight - marginBottom - marginTop;
      doc.addImage(logo, 'PNG', x, y, logoWidth, logoHeight);
  
      doc.save('Project_Details.pdf');
  
    } catch (error) {
      console.error('Error fetching project data: ', error);
    } finally {
      setLoading(false);
    }
  };
  

  useEffect(() => {
    const fetchProjectStatus = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/project/getprojects`,
          {
            params: {
              accountid: accountid,
            },
          }
        );
        const project = response.data.projects;
        const currentProject = project.find((p) => p.id === id);
        if (currentProject) {
          setIsActive(!currentProject.isdeleted);
        }
      } catch (error) {
        // console.error('Error fetching project data:', error);
      }
    };

    if (id) {
      fetchProjectStatus();
    }
  }, [projectname, accountid, id]);

  const navigateToReport = () => {
    navigate("/project");
  };

  const handleEditProjectClick = () => {
    navigate("/project/editProject", { state: { site } });
  };

  return (
    <div className="FilterEmployeeVehicle">
      <div className="breadcrumb-container">
        <Breadcrumb separator=">">
          <Breadcrumb.Item onClick={navigateToReport}>
            <span className="breadcrumb-reports">Projects</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="breadcrumb-fleet-management">{projectname}</span>
            <Tag style={{ marginLeft: 7 }} color={isActive ? "green" : "red"}>
              {isActive ? "Active" : "Inactive"}
            </Tag>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="right-container">
      <Spin spinning={loading}>
        <Button
          type="primary"
          className="customExportButton"
          icon={<DownloadOutlined />}
          onClick={handleExportToPdf}
          disabled={loading}
        >
          Export to PDF
        </Button>
        </Spin>
        <Button
          className="customAddButton"
          icon={<EditFilled />}
          onClick={handleEditProjectClick}
        >
          <span className="addEmployee"> Edit</span>
        </Button>
      </div>
    </div>
  );
};

export default ProjectViewNavbar;
