// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filterContainer {
  position: sticky;
  top: 140px;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #ffffff;
  margin: 21px;
  border-radius: 12px;
}
.leftSpace {
  display: flex;
  gap: 16px;
}
.customSelect .ant-select-selector {
  border-radius: 15px !important;
  color: #001233;
}
.customSelect .ant-select-arrow {
  color: #001233;
}

.customExportButton {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: #001233;
  border: none;
  background: none;
  box-shadow: none;
}

.customExportButton .anticon {
  font-size: 24px;
  margin-right: 8px;
}
.customExportButton:hover {
  background: none !important;
  color: #001233 !important;
}

.role-button {
  width: 150px;
  border-radius: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/Employee/EmployeeFilterNavbar/employeeFilter.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,aAAa;EACb,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,8BAA8B;EAC9B,cAAc;AAChB;AACA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;AACA;EACE,2BAA2B;EAC3B,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".filterContainer {\n  position: sticky;\n  top: 140px;\n  z-index: 1000;\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  background: #ffffff;\n  margin: 21px;\n  border-radius: 12px;\n}\n.leftSpace {\n  display: flex;\n  gap: 16px;\n}\n.customSelect .ant-select-selector {\n  border-radius: 15px !important;\n  color: #001233;\n}\n.customSelect .ant-select-arrow {\n  color: #001233;\n}\n\n.customExportButton {\n  display: flex;\n  align-items: center;\n  font-size: 16px;\n  font-weight: 600;\n  color: #001233;\n  border: none;\n  background: none;\n  box-shadow: none;\n}\n\n.customExportButton .anticon {\n  font-size: 24px;\n  margin-right: 8px;\n}\n.customExportButton:hover {\n  background: none !important;\n  color: #001233 !important;\n}\n\n.role-button {\n  width: 150px;\n  border-radius: 50px;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
