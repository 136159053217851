import React, { useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import VehicleDataNavbar from "./VehicleDataNavbar";
import VehicleDataFilter from "./VehicleDataFilter";
import VehicleListData from "./VehicleListData";

function VehicleDashboardData() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleVehicleTypeSelect = (vehicletype) => {
    setSelectedVehicleType(vehicletype);
  };

  const handleClearFilters = () => {
    setSelectedProject(null);
    setSelectedVehicleType(null);
    setSearchQuery("");
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <>
      <HeaderNav />
      <VehicleDataNavbar />
      <VehicleDataFilter
        onProjectSelect={handleProjectSelect}
        onClearFilters={handleClearFilters}
        selectedProject={selectedProject}
        onVehicleTypeSelect={handleVehicleTypeSelect}
        selectedVehicleType={selectedVehicleType}
        onSearch={handleSearch}
      />
      <VehicleListData
        selectedProject={selectedProject}
        selectedVehicleType={selectedVehicleType}
        searchQuery={searchQuery}
      />
    </>
  );
}

export default VehicleDashboardData;
