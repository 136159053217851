import React from "react";
import { Card, Row, Col, Button, Typography, Tag } from "antd";
import HeaderNav from "../Navbar/HeaderNav";
import "./ReportDashboard.css";
import { ReactComponent as VehicleReportIcon } from "../../assets/Report/vehicleReporticon.svg";
import { ReactComponent as EmployeeReportIcon } from "../../assets/Report/employeeReporticon.svg";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const Reports = () => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };

  return (
    <>
      <HeaderNav />
      <Title className="reportstyle">Reports</Title>
      <Row className="mt-20" gutter={[16, 16]}>
        <Col xs={24} sm={12} md={8}>
          <Card className="report-card" hoverable>
            <div className="report-icon-container">
              <EmployeeReportIcon className="reportEmployeeicon" />
              <Tag bordered={false} color="volcano" className="report-tag">
                Workforce
              </Tag>
            </div>
            <Title level={4}>Attendance</Title>
            <p>
              Effectively monitor employee attendance and essential workforce
              details with ease. This helps you make informed decisions and
              optimize your workforce management.
            </p>
            <Button
              block
              className="employeebutton"
              onClick={() => handleNavigation("/Employeereport")}
            >
              View Report
            </Button>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <Card className="report-card" hoverable>
            <div className="report-icon-container">
              <VehicleReportIcon className="reportvehicleimage" />
              <Tag bordered={false} color="volcano" className="report-tag">
                Inspection
              </Tag>
            </div>
            <Title level={4}>Fleet Management</Title>
            <p>
            Monitor real-time status, fuel usage metrics, and vehicle run-time. Manage your fleet effectively with our comprehensive reports. Optimize your operations and reduce costs with data-driven insights.
            </p>
            <Button
              block
              className="employeebutton"
              onClick={() => handleNavigation("/Vehiclereport")}
            >
              View Report
            </Button>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Reports;
