import React from "react";
import "./SignUpAck.css";

function SignUpAck() {
  return (
    <div className="containerAck">
      <span
        className="back-arrow"
        onClick={() => (window.location.href = "https://civilbrain.ai")}
      >
        &lt; Back
      </span>

      <h1>
        <strong>Thank You for Signing Up!</strong>
      </h1>
      <p>Dear Valued User,</p>
      <p>
        Thank you for registering with us! Your account has been successfully
        created, and we are excited to have you join our Civil Brain community.
      </p>
      <p>
        To ensure the highest level of security and to complete your
        registration, we have sent a notification to your company administrator
        for account activation. This step allows us to verify your details and
        maintain the integrity of our platform.
      </p>
      <p>
        <strong>What to Expect Next:</strong>
      </p>
      <ul>
        <li>Our company administrator will review your account information.</li>
        <li>
          Once your account is approved, you will receive an activation email.
        </li>
        <li>
          You will then gain access to all the features and services available
          to our members.
        </li>
      </ul>
      <p>
        Please note that your account will be activated within 48 hours. If you
        do not receive an activation email within this time frame, you may
        kindly reach out to your administrator for further assistance.
      </p>
      <p>
        We appreciate your patience during this process. Should you have any
        questions or need further assistance, please do not hesitate to reach
        out to our support team at{" "}
        <a href="mailto:support@civilbrain.ai">
          <span>support@civilbrain.ai</span>
        </a>{" "}
        or call us at <span>9354527330</span>.
      </p>
      <p>Thank you for your understanding and cooperation.</p>
      <p>
        Best regards,
        <br />
        The Civil Brain Team
      </p>
    </div>
  );
}

export default SignUpAck;
