import React, { useRef, useEffect, useState } from "react";
import {
  GoogleMap,
  Polygon,
  LoadScript,
  Marker,
  Polyline,
  OverlayView,
} from "@react-google-maps/api";
import { lineString, buffer as turfBuffer } from "@turf/turf";

const containerStyle = {
  width: "100%",
  height: "82vh",
  borderRadius: "8px",
};

const center = {
  lat: 28.4642455,
  lng: 77.4921608,
};

const polygonOptions = {
  fillColor: "blue",
  fillOpacity: 0.4,
  strokeColor: "blue",
  strokeOpacity: 1,
  strokeWeight: 2,
};

const markerOptions = {
  icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
};

const polylineOptions = {
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 5,
};

function PreviewMap({ surveyDetails, onMarkerUpdate, buffer: bufferDistance }) {
  const mapRef = useRef(null);
  const [markerPositions, setMarkerPositions] = useState(
    surveyDetails.survey_details || []
  );

  useEffect(() => {
    if (surveyDetails && surveyDetails.survey_details) {
      setMarkerPositions(surveyDetails.survey_details);
    }
  }, [surveyDetails]);

  const createGeofencePolygon = (data, isBuilding, bufferValue) => {
    if (!data || data.length < 3) {
      return null;
    }
    const coordinates = data.map((item) => [item.longitude, item.latitude]);

    if (isBuilding) {
      coordinates.push(coordinates[0]);
    }

    const line = lineString(coordinates);
    return turfBuffer(line, bufferValue, { units: "kilometers" });
  };

  const isBuilding = surveyDetails?.type === "Building";
  const geofence = createGeofencePolygon(
    markerPositions,
    isBuilding,
    bufferDistance
  );

  const convertGeoJsonToLatLngs = (geoJson) => {
    if (!geoJson) return [];
    return geoJson.geometry.coordinates[0].map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));
  };

  useEffect(() => {
    if (mapRef.current && geofence) {
      const latLngs = convertGeoJsonToLatLngs(geofence);
      if (latLngs.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        latLngs.forEach((latLng) => bounds.extend(latLng));
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geofence]);

  const handleMarkerDragEnd = (index, event) => {
    const newMarkerPositions = [...markerPositions];
    newMarkerPositions[index] = {
      ...newMarkerPositions[index],
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
    };
    setMarkerPositions(newMarkerPositions);

    if (onMarkerUpdate) {
      onMarkerUpdate(index, newMarkerPositions[index]);
    }
  };

  const polylinePath = isBuilding
    ? [...markerPositions, markerPositions[0]].map((marker) => ({
        lat: marker.latitude,
        lng: marker.longitude,
      }))
    : markerPositions.map((marker) => ({
        lat: marker.latitude,
        lng: marker.longitude,
      }));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {surveyDetails ? (
        <LoadScript
          googleMapsApiKey={process.env.React_App_GOOGLE_MAPS_API_KEY}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            onLoad={(map) => {
              mapRef.current = map;
            }}
            onIdle={() => {
              if (mapRef.current && geofence) {
                const latLngs = convertGeoJsonToLatLngs(geofence);
                if (latLngs.length > 0) {
                  const bounds = new window.google.maps.LatLngBounds();
                  latLngs.forEach((latLng) => bounds.extend(latLng));
                  mapRef.current.fitBounds(bounds);
                }
              }
            }}
          >
            {geofence && (
              <Polygon
                paths={convertGeoJsonToLatLngs(geofence)}
                options={polygonOptions}
              />
            )}
            {markerPositions.map((marker, index) => (
              <div key={index}>
                <Marker
                  position={{
                    lat: marker.latitude,
                    lng: marker.longitude,
                  }}
                  options={markerOptions}
                  draggable={true}
                  onDragEnd={(event) => handleMarkerDragEnd(index, event)}
                />
                <OverlayView
                  position={{
                    lat: marker.latitude,
                    lng: marker.longitude,
                  }}
                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div className="marker-label">{index + 1}</div>
                </OverlayView>
              </div>
            ))}
            <Polyline path={polylinePath} options={polylineOptions} />
          </GoogleMap>
        </LoadScript>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default PreviewMap;
