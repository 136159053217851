import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../Axios/AxiosInstance";
import { Input, Select, Space, Button } from "antd";
import { SearchOutlined, EnvironmentOutlined } from "@ant-design/icons";

const { Option } = Select;

const EmployeeDataFilter = ({
  onProjectSelect,
  onRoleSelect,
  onClearFilters,
  selectedRole,
  onSearch,
  searchQuery,
}) => {
  const [projects, setProjects] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedRoleId, setSelectedRoleId] = useState(null);
  const accountid = localStorage.getItem("accountid");
  const location = useLocation();
  const projectIdFromState = location.state?.projectId;

  const [localSelectedProject, setLocalSelectedProject] = useState(
    projectIdFromState || null
  );

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/project/getprojects");

        if (response.status === 200) {
          const projectData = response.data.projects;
          setProjects(projectData);
          if (
            projectIdFromState &&
            projectData.some((project) => project.id === projectIdFromState)
          ) {
            setLocalSelectedProject(projectIdFromState);
          }
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    const fetchDepartmentsAndRoles = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/role/getroles`
        );

        if (response.status === 200) {
          const departmentsData = response.data.data;
          setDepartments(departmentsData);
        } else {
          console.error("Failed to fetch departments and roles");
        }
      } catch (error) {
        console.error("Error fetching departments and roles:", error.message);
      }
    };

    fetchProjects();
    fetchDepartmentsAndRoles();
  }, [accountid, projectIdFromState]);

  useEffect(() => {
    if (localSelectedProject) {
      onProjectSelect(localSelectedProject);
    }
  }, [localSelectedProject, onProjectSelect]);

  const handleProjectChange = (value) => {
    setLocalSelectedProject(value);
    onProjectSelect(value);
  };

  const handleCheckboxChange = (projectId) => {
    // If the same project is clicked again, deselect it
    if (localSelectedProject === projectId) {
      setLocalSelectedProject(null); // Deselect if already selected
      onProjectSelect(null); // Inform parent of deselection
    } else {
      handleProjectChange(projectId); // Select new project
    }
  };

  const handleDepartmentChange = (departmentId) => {
    setSelectedDepartment(departmentId);
    const selectedDept = departments.find((dept) => dept.id === departmentId);
    if (selectedDept) {
      setRoles(selectedDept.roles);
    }
  };

  const handleRoleChange = (roleId) => {
    setSelectedRoleId(roleId);
    onRoleSelect(roleId);
  };

  const handleClearFilters = () => {
    setLocalSelectedProject(null);
    setSelectedDepartment(null);
    setSelectedRoleId(null);
    setRoles([]);
    onClearFilters();
  };

  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };

  const isDisabled = !!projectIdFromState;

  return (
    <div className="filterContainer">
      <Space className="leftSpace">
        <div className="customSelect">
          <EnvironmentOutlined className="dropdown-icon" />
          <Select
            style={{ width: 190 }}
            placeholder="Select Project"
            value={localSelectedProject}
            onChange={handleProjectChange}
            disabled={isDisabled}
          >
            {!isDisabled &&
              projects
                .filter((site) => !site.isdeleted)
                .map((site) => (
                  <Option key={site.id} value={site.id}>
                    <input
                      type="checkbox"
                      checked={localSelectedProject === site.id}
                      onChange={() => handleCheckboxChange(site.id)} // Trigger project selection
                      style={{ accentColor: "#E76F51", marginRight: 8 }}
                    />
                    {site.projectname}
                  </Option>
                ))}
            {isDisabled &&
              projects
                .filter((site) => site.id === localSelectedProject)
                .map((site) => (
                  <Option key={site.id} value={site.id}>
                    <input
                      type="checkbox"
                      checked
                      disabled
                      style={{ accentColor: "#E76F51", marginRight: 8 }}
                    />
                    {site.projectname}
                  </Option>
                ))}
          </Select>
        </div>
        <Input
          placeholder="Search by employee Name, Phone number"
          prefix={<SearchOutlined />}
          style={{ width: 250, borderRadius: 15, color: "#001233" }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <div className="customSelect">
          <Select
            style={{ width: 190 }}
            placeholder="Select Department"
            value={selectedDepartment}
            onChange={handleDepartmentChange}
          >
            {departments.map((department) => (
              <Option key={department.id} value={department.id}>
                {department.name}
              </Option>
            ))}
          </Select>
        </div>
        <div className="customSelect">
          <Select
            className="customSelect"
            placeholder="Select Role"
            value={selectedRoleId}
            onChange={handleRoleChange}
            disabled={!selectedDepartment}
          >
            {roles.map((role) => (
              <Option key={role.id} value={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </div>
        <Button className="customclearButton" onClick={handleClearFilters}>
          Clear all filters
        </Button>
      </Space>
    </div>
  );
};

export default EmployeeDataFilter;
