import React, { useState, useCallback } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Breadcrumb,
  Row,
  Col,
  Typography,
  message,
  DatePicker,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import axios from "axios";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";

const { Title } = Typography;
const { TextArea } = Input;
const apiKey = process.env.React_App_GOOGLE_MAPS_API_KEY;

function AddProject() {
  const [formData, setFormData] = useState({
    projectname: "",
    tendervalue: "",
    startdate: null,
    enddate: null,
    location: "",
    stipulateddate: null,
    descriptions: "",
  });
  const [errors, setErrors] = useState({});
  const [autocomplete, setAutocomplete] = useState(null);
  const accountid = localStorage.getItem("accountid");
  const navigate = useNavigate();

  message.config({
    top: 700,
  });

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: apiKey,
    libraries: ["places"],
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      projectname,
      tendervalue,
      startdate,
      enddate,
      location,
      stipulateddate,
      descriptions,
    } = formData;
    let errors = {};

    if (!projectname) errors.projectname = "Project Name is required";
    if (!tendervalue) errors.tendervalue = "Tender Value is required";
    if (!startdate) errors.startdate = "Start Date is required";
    if (!enddate) errors.enddate = "End Date is required";
    if (!location) errors.location = "Location is required";
    if (!stipulateddate)
      errors.stipulateddate = "Stipulated End Date is required";
    if (!descriptions) errors.descriptions = "Descriptions is required";

    setErrors(errors);

    if (Object.keys(errors).length > 0) return;

    try {
      const response = await axios.post(
        `${process.env.React_App_API_URL}/project/addproject`,
        {
          ...formData,
          accountid,
          startdate: startdate ? startdate.format("YYYY-MM-DD") : null,
          enddate: enddate ? enddate.format("YYYY-MM-DD") : null,
          stipulateddate: stipulateddate
            ? stipulateddate.format("YYYY-MM-DD")
            : null,
        }
      );

      if (response.status === 201) {
        setFormData({
          projectname: "",
          tendervalue: "",
          startdate: null,
          enddate: null,
          location: "",
          stipulateddate: null,
          descriptions: "",
        });
        message.success("Project has been successfully added", 3);
        navigate("/project");
      } else {
        message.error("Failed to add Project", 3);
      }
    } catch (error) {
      message.error("Internal Server Error", 3);
    }
  };

  const handleCancel = () => {
    navigate("/project");
  };

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place && place.formatted_address) {
        setFormData({
          ...formData,
          location: place.formatted_address,
        });
      }
    }
  };

  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete);
    autocomplete.setOptions({ componentRestrictions: { country: "IN" } });
  }, []);

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span className="employee">Project Registration</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            className="Exit"
            icon={<CloseOutlined />}
            onClick={handleCancel}
          >
            Exit
          </Button>
          <Button
            className="customAddButton"
            icon={<PlusOutlined />}
            onClick={handleSubmit}
          >
            <span className="addEmployee">Add</span>
          </Button>
        </div>
      </div>

      <div style={{ backgroundColor: "#f0f2f5", padding: "50px" }}>
        <div
          style={{
            padding: "20px",
            maxWidth: "700px",
            margin: "auto",
            backgroundColor: "#ffffff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4} className="title-color">
                Project Details
              </Title>
            </Col>
            <Col span={24}>
              <Form layout="vertical" onSubmit={handleSubmit}>
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Project Name</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter the name of the project",
                        },
                      ]}
                    >
                      <Input
                        value={formData.projectname}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            projectname: e.target.value,
                          })
                        }
                      />
                      {errors.projectname && (
                        <div style={{ color: "#E76F51" }}>
                          {errors.projectname}
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Tender Value</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter the total amount",
                        },
                      ]}
                    >
                      <Input
                        value={formData.tendervalue}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*\.?\d*$/.test(value)) {
                            setFormData({ ...formData, tendervalue: value });
                          }
                        }}
                        prefix="₹"
                      />
                      {errors.tendervalue && (
                        <div style={{ color: "#E76F51" }}>
                          {errors.tendervalue}
                        </div>
                      )}
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Row gutter={[16, 16]}>
                      <Col xs={24} md={8}>
                        <Form.Item
                          label={<span className="label-name">Start Date</span>}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please select the start date",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            value={formData.startdate}
                            onChange={(date) =>
                              setFormData({ ...formData, startdate: date })
                            }
                            format="DD-MMM-YYYY"
                          />
                          {errors.startdate && (
                            <div style={{ color: "#E76F51" }}>
                              {errors.startdate}
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <Form.Item
                          label={<span className="label-name">End Date</span>}
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please select the end date",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            value={formData.enddate}
                            onChange={(date) =>
                              setFormData({ ...formData, enddate: date })
                            }
                            format="DD-MMM-YYYY"
                          />
                          {errors.enddate && (
                            <div style={{ color: "#E76F51" }}>
                              {errors.enddate}
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                      <Col xs={24} md={8}>
                        <Form.Item
                          label={
                            <span className="label-name">
                              Stipulated End Date
                            </span>
                          }
                          required
                          rules={[
                            {
                              required: true,
                              message: "Please select the stipulated end date",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ width: "100%" }}
                            value={formData.stipulateddate}
                            onChange={(date) =>
                              setFormData({ ...formData, stipulateddate: date })
                            }
                            format="DD-MMM-YYYY"
                          />
                          {errors.stipulateddate && (
                            <div style={{ color: "#E76F51" }}>
                              {errors.stipulateddate}
                            </div>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={<span className="label-name">Location</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter the location",
                        },
                      ]}
                    >
                      {isLoaded ? (
                        <Autocomplete
                          onLoad={onLoad}
                          onPlaceChanged={onPlaceChanged}
                        >
                          <Input
                            value={formData.location}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                location: e.target.value,
                              })
                            }
                            placeholder="Enter location"
                          />
                        </Autocomplete>
                      ) : (
                        <Input placeholder="Loading..." disabled />
                      )}
                      {errors.location && (
                        <div style={{ color: "#E76F51" }}>
                          {errors.location}
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={<span className="label-name">Description</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter the project description",
                        },
                      ]}
                    >
                      <TextArea
                        rows={4}
                        value={formData.descriptions}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            descriptions: e.target.value,
                          })
                        }
                      />
                      {errors.descriptions && (
                        <div style={{ color: "#E76F51" }}>
                          {errors.descriptions}
                        </div>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddProject;
