import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import axios from "axios";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).format(date);
};

function EmployeeTable({
  startDate,
  endDate,
  selectedProject,
  selectedRole,
  selectedStatus,
  searchText,
}) {
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let url = `${process.env.React_App_API_URL}/reports/getAttendances?accountid=${accountid}`;

        const response = await axios.get(url);
        const fetchedData = response.data;

        if (fetchedData && fetchedData.data) {
          const { present, absent } = fetchedData.data;

          const presentData = present
            ? present.flatMap((item) =>
                item.present_employees.map((emp) => ({
                  ...emp,
                  key: `present-${emp.phonenumber}-${item.attendance_date}`,
                  attendance: true,
                  time: item.attendance_date,
                  project_names: emp.projectname,
                  project_ids: [emp.projectid],
                }))
              )
            : [];

          const absentData = absent
            ? absent.flatMap((item) =>
                item.absent_employees.map((emp) => ({
                  ...emp,
                  key: `absent-${emp.phonenumber}-${item.attendance_date}`,
                  attendance: false,
                  time: item.attendance_date,
                  project_names: emp.projects
                    .map((proj) => proj.project_name)
                    .join(", "),
                  project_ids: emp.projects.map((proj) => proj.project_id),
                }))
              )
            : [];

          let combinedData = [...presentData, ...absentData];

          // Client-side filtering based on selectedProject and selectedRole
          if (startDate && endDate) {
            combinedData = combinedData.filter(
              (emp) =>
                new Date(emp.time) >=
                  new Date(startDate.format("YYYY-MM-DD")) &&
                new Date(emp.time) <= new Date(endDate.format("YYYY-MM-DD"))
            );
          }

          if (selectedProject) {
            combinedData = combinedData.filter((emp) =>
              emp.project_ids.includes(selectedProject)
            );
          }

          if (selectedRole) {
            combinedData = combinedData.filter(
              (emp) =>
                emp.roleid === selectedRole || emp.role_id === selectedRole
            );
          }

          if (selectedStatus === "present") {
            combinedData = combinedData.filter(
              (emp) => emp.attendance === true
            );
          } else if (selectedStatus === "absent") {
            combinedData = combinedData.filter(
              (emp) => emp.attendance === false
            );
          }

          if (searchText) {
            combinedData = combinedData.filter(
              (item) =>
                item.name.toLowerCase().includes(searchText.toLowerCase()) ||
                item.phonenumber.includes(searchText)
            );
          }

          setEmployeeData(combinedData);
        } else {
          console.error(
            "Error: Fetched data is not in the expected format:",
            fetchedData
          );
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [
    accountid,
    startDate,
    endDate,
    selectedProject,
    selectedRole,
    selectedStatus,
    searchText,
  ]);

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 80,
      render: (text, record, index) => (
        <span className="customTextColor">
          {(currentPage - 1) * pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      render: (status) => (
        <Tag color={status ? "green" : "red"}>
          {status ? "Present" : "Absent"}
        </Tag>
      ),
    },
    {
      title: "Date",
      dataIndex: "time",
      key: "time",
      render: (text) => (
        <span className="customTextColor">{formatDate(text)}</span>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "project_names",
      key: "project_names",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
  ];

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  return (
    <Table
      columns={columns}
      dataSource={employeeData}
      pagination={{
        current: currentPage,
        pageSize: 100,
        showSizeChanger: false,
      }}
      onChange={handleTableChange}
      bordered
      style={{ margin: "20px" }}
      className="custom-table-header"
      loading={loading}
      scroll={{ x: 1000, y: 430 }}
    />
  );
}

export default EmployeeTable;
