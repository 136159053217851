import React, { useRef, useEffect } from "react";
import {
  GoogleMap,
  Polygon,
  LoadScript,
  Marker,
  Polyline,
} from "@react-google-maps/api";
import { lineString, buffer } from "@turf/turf";

const containerStyle = {
  width: "100%",
  height: "82vh",
  borderRadius: "8px",
};

const center = {
  lat: 28.4642455,
  lng: 77.4921608,
};

const polylineOptions = {
  strokeColor: "red",
  strokeOpacity: 1,
  strokeWeight: 2,
};

const polygonOptions = {
  fillColor: "blue",
  fillOpacity: 0.4,
  strokeColor: "blue",
  strokeOpacity: 1,
  strokeWeight: 2,
};

const markerOptions = {
  icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
};

function ViewSurveyMap({ surveyDetails }) {
  const mapRef = useRef(null); // Reference to the map instance

  // Function to create a GeoJSON Polygon from survey data
  const createGeofencePolygon = (data) => {
    if (!data || data.length < 3) {
      return null; // A polygon needs at least 3 points
    }
    const coordinates = data.map((item) => [item.longitude, item.latitude]);
    const line = lineString(coordinates);
    return buffer(line, 5, { units: "meters" });
  };

  // Create GeoJSON polygon if surveyDetails is available
  const geofence = createGeofencePolygon(surveyDetails.survey_details || []);

  // Convert GeoJSON Polygon to Google Maps LatLngLiteral array
  const convertGeoJsonToLatLngs = (geoJson) => {
    if (!geoJson) return [];
    return geoJson.geometry.coordinates[0].map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));
  };

  // Convert coordinates for markers
  const getMarkers = (data) => {
    return data.map((item, index) => ({
      position: {
        lat: item.latitude,
        lng: item.longitude,
      },
      key: index,
    }));
  };

  const markers = getMarkers(surveyDetails.survey_details || []);

  // Automatically fit the map bounds to the polygon
  useEffect(() => {
    if (mapRef.current && geofence) {
      const latLngs = convertGeoJsonToLatLngs(geofence);
      if (latLngs.length > 0) {
        const bounds = new window.google.maps.LatLngBounds();
        latLngs.forEach((latLng) => bounds.extend(latLng));
        mapRef.current.fitBounds(bounds);
      }
    }
  }, [geofence]);

  // Extract the lat/lng positions for Polyline path
  const polylinePath = surveyDetails.survey_details.map((item) => ({
    lat: item.latitude,
    lng: item.longitude,
  }));

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {surveyDetails ? (
        <LoadScript
          googleMapsApiKey={process.env.React_App_GOOGLE_MAPS_API_KEY}
        >
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            onLoad={(map) => {
              mapRef.current = map;
            }} // Store map reference
            onIdle={() => {
              // Ensure fitBounds is called only when map is idle
              if (mapRef.current && geofence) {
                const latLngs = convertGeoJsonToLatLngs(geofence);
                if (latLngs.length > 0) {
                  const bounds = new window.google.maps.LatLngBounds();
                  latLngs.forEach((latLng) => bounds.extend(latLng));
                  mapRef.current.fitBounds(bounds);
                }
              }
            }}
          >
            {geofence && (
              <Polygon
                paths={convertGeoJsonToLatLngs(geofence)}
                options={polygonOptions}
              />
            )}
            {markers.map((marker) => (
              <Marker
                key={marker.key}
                position={marker.position}
                options={markerOptions}
              />
            ))}
            {/* Polyline to connect the markers */}
            {polylinePath.length > 1 && (
              <Polyline path={polylinePath} options={polylineOptions} />
            )}
          </GoogleMap>
        </LoadScript>
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
}

export default ViewSurveyMap;
