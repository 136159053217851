import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { SocketProvider } from "./Socket/SocketContext";
import { ProfileImageProvider } from "./Socket/ProfileImageContext";
import { GpsSocketProvider } from "./Socket/GpsSpocketContext";

ReactDOM.render(
  <React.StrictMode>
    <SocketProvider>
      <GpsSocketProvider>
        <ProfileImageProvider>
          <App />
        </ProfileImageProvider>
      </GpsSocketProvider>
    </SocketProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
