import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";

function ReverseGeocoding({ lat, lng }) {
  const [placeName, setPlaceName] = useState("");
  const [error, setError] = useState(null);
  const OLAMapApiKey = process.env.REACT_APP_OLA_MAP_API_KEY;

  const getPlaceNameFromCoordinates = useCallback(
    async (lat, lng) => {
      try {
        const response = await axios.get(
          `https://api.olamaps.io/places/v1/reverse-geocode?latlng=${lat},${lng}&api_key=${OLAMapApiKey}`
        );

        const data = response.data;

        if (data.status === "ok") {
          const results = data.results[0]?.formatted_address;
          if (results) {
            const address = removeCountryName(results);
            setPlaceName(address);
          } else {
            setError("No results found");
          }
        } else {
          setError("Geocoding API error: " + data.status);
        }
      } catch (err) {
        setError("Error fetching data from Geocoding API: " + err.message);
      }
    },
    [OLAMapApiKey]
  );

  useEffect(() => {
    if (lat && lng) {
      getPlaceNameFromCoordinates(lat, lng);
    }
  }, [lat, lng, getPlaceNameFromCoordinates]);

  const removeCountryName = (formattedAddress) => {
    const parts = formattedAddress.split(", ");
    if (parts.length > 1) {
      return parts.slice(0, -2).join(", ");
    }
    return formattedAddress;
  };

  if (error) {
    return <span>Error: {error}</span>;
  }

  return <span>{placeName}</span>;
}

export default ReverseGeocoding;
