import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LiveLocation from "./components/New/Dashboard/MapDashboard";
import EmployeeList from "./components/New/EmployeeData/employeeDashboard";
import VehicleDashboardData from "./components/New/VehicleData/VehicleDashboardData";
import AddEmployeeForm from "./components/New/EmployeeData/addEmployee";
import EditEmployeeForm from "./components/New/EmployeeData/editEmployee";
// import Login from "./components/Login/login";
// import Whatsapp from './components/WhatsappIng/whatsappList'
// import AddWhatsappMessage from './components/WhatsappIng/addMessage'
import Report from "./components/New/Report/ReportDashboard";
import DrawGeofence from "./components/New/Geofence/DrawGeofence";
import Preview from './components/New/Geofence/Preview/Preview';

import SignIn from "./components/Login/SignIn";
import Register from "./components/Login/Register";
import AdditionalInfo from "./components/Login/AdditionalInfo";
import SignUpAck from "./components/Login/SignUpAck";
import Forgot from "./components/Login/Forgot";
import Profile from "./components/AdminProfie/profile";

// import VehicleList from './components/vehicle/VehicleList';
import EmployeeDashboard from "./components/New/Employee/EmployeeDashboard";
import VehicleDashboard from "./components/New/Vehicle/VehicleDashboard";
import VehicleReport from "./components/New/Report/vehicleReport";
import EmployeeReport from "./components/New/Report/employeeReport";
import VehicleMap from "./components/New/Dashboard/VehicleMap/vehicleMapDashboard";
import EmployeeMap from "./components/New/Dashboard/MapDashboard";
import AddVehicle from "./components/New/VehicleData/AddVehicle";
import EditVehicle from "./components/New/VehicleData/EditVehicle";
import ProjectDashboard from "./components/New/ProjectSites/ProjectDashboard";
import AddProject from "./components/New/ProjectSites/AddProject";
import ViewSurvey from "./components/New/Geofence/Preview/ViewSurvey";
import EditProject from "./components/New/ProjectSites/EditProject";
import ProjectViewDashboard from "./components/New/ProjectSites/ProjectView/projectViewDashboard";
import Gps from "./components/New/test/gps";
//import Maintence from './components/New/maintenacePage/maintence';

function App() {
  const isLoggedIn = localStorage.getItem("isLoggedIn") === "true"; // Check logged-in status on each render

  return (
    <Router>
      <Routes>
        {isLoggedIn ? (
          <>
            <Route path="/" element={<LiveLocation />} />
            <Route path="/EmployeeData" element={<EmployeeList />} />
            <Route
              path="/VehicleDashboardData"
              element={<VehicleDashboardData />}
            />
            <Route path="/addemployee" element={<AddEmployeeForm />} />
            <Route path="/editemployee" element={<EditEmployeeForm />} />
            <Route path="/report" element={<Report />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/DrawGeofence" element={<DrawGeofence />} />
            <Route path="/EmployeeDashboard" element={<EmployeeDashboard />} />
            <Route path="/VehicleDashboard" element={<VehicleDashboard />} />
            <Route path="/Vehiclereport" element={<VehicleReport />} />
            <Route path="/Employeereport" element={<EmployeeReport />} />
            <Route path="/VehicleMap" element={<VehicleMap />} />
            <Route path="/EmployeeMap" element={<EmployeeMap />} />
            <Route path="/AddVehicle" element={<AddVehicle />} />
            <Route path="/EditVehicle" element={<EditVehicle />} />
            <Route path="/project" element={<ProjectDashboard />} />
            <Route path="/project/addProject" element={<AddProject />} />
            <Route path="/project/editProject" element={<EditProject />} />
            <Route path="/project/viewProject" element={<ProjectViewDashboard />} />
            <Route path="/project/viewSurvey" element={<ViewSurvey />} />
            <Route path="/geofence/preview" element={<Preview />} />
            <Route path="/gps" element={<Gps />} />
            {/* <Route path="/Maintence" element={<Maintence />} /> */}

            {/* remove below  */}
            {/* <Route path="/" element={<Maintence />} />  */}
          </>
        ) : (
          <Route path="/" element={<SignIn />} />
          // remove below
          // <Route path="/" element={<Maintence />} />
        )}
        
        <Route path="/signup" element={<Register />} />
        <Route path="/additional-info" element={<AdditionalInfo />} />
        <Route path="/SignUpAck" element={<SignUpAck />} />
        <Route path="/forgotpassword" element={<Forgot />} />
        <Route path="/*" element={<Navigate to="/" />} />
        {/* <Route path="/SignUp" element={<Maintence />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
