import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Collapse, Space, Tag, Row, Col } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import ReverseGeocoding from "../../Utils/ReverseGeocoding";
import { useGpsSocket } from "../../../../Socket/GpsSpocketContext";

const { Panel } = Collapse;

const StatusTag = ({ vehiclestatus, speed }) => {
  let color = "gray"; // Default color is gray (Offline)

  if (vehiclestatus) {
    color = speed > 0 ? "green" : "gold"; // Moving or Idle
  } else {
    color = "red"; // Stop
  }

  return (
    <Tag color={color} style={{ marginLeft: "2rem" }}>
      {vehiclestatus ? (speed > 0 ? "Moving" : "Idle") : "Stop"}
    </Tag>
  );
};

const CustomCardHeader = ({ count }) => (
  <div className="custom-card-header">
    <span className="header-title">Vehicles&nbsp;</span>
    <span className="header-badge">{count}</span>
  </div>
);

const VehicleSideBar = ({
  selectedProject,
  selectedVehicleType,
  searchText,
  selectedStatus,
  VehicleSideBarClick,
}) => {
  const [vehicleData, setVehicleData] = useState([]);
  const accountid = localStorage.getItem("accountid");
  const gpsSocket = useGpsSocket();

  const updateVehicleWithGpsData = (gpsDataArray) => {
    setVehicleData((prevVehicleData) =>
      prevVehicleData.map((vehicle) => {
        gpsDataArray.forEach((gpsData) => {
          if (vehicle.regno === gpsData.imei) {
            vehicle = {
              ...vehicle,
              speed: gpsData.speed,
              lastlocation: {
                lat: gpsData.latitude,
                lng: gpsData.longitude,
              },
              vehiclestatus: gpsData.ignitionStatus === "02:1",
              altitude: gpsData.altitude,
              cog: gpsData.cog,
              date: gpsData.date,
              time: gpsData.time,
            };
          }
        });

        return vehicle;
      })
    );
  };

  const filterVehicles = (vehicles) => {
    return vehicles.filter((vehicle) => {
      const vehicleNumber = vehicle.vehiclenumber
        ? vehicle.vehiclenumber.toLowerCase()
        : "";
      const driverName = vehicle.drivername
        ? vehicle.drivername.toLowerCase()
        : "";
      const searchLower = searchText ? searchText.toLowerCase() : "";

      const matchesSearchText =
        vehicleNumber.includes(searchLower) || driverName.includes(searchLower);

      const matchesStatus =
        !selectedStatus ||
        (selectedStatus === "Moving" &&
          vehicle.vehiclestatus &&
          vehicle.speed > 0) ||
        (selectedStatus === "Idle" &&
          vehicle.vehiclestatus &&
          vehicle.speed === 0) ||
        (selectedStatus === "Stopped" && !vehicle.vehiclestatus);

      // Matches selected project
      const matchesProject =
        !selectedProject ||
        vehicle.projects.some(
          (project) => project.project_id === selectedProject
        );

      // Matches selected vehicle type
      const matchesVehicleType =
        !selectedVehicleType || vehicle.vehicletype === selectedVehicleType;

      return (
        matchesSearchText &&
        matchesStatus &&
        matchesProject &&
        matchesVehicleType
      );
    });
  };

  // Fetch vehicle data from the API
  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/home/sidebar/vehicles?accountid=${accountid}`
        );
        setVehicleData(response.data.data);
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      }
    };

    fetchVehicleData();
  }, [accountid]);

  useEffect(() => {
    if (gpsSocket) {
      gpsSocket.on("gps_update", (data) => {
        updateVehicleWithGpsData(data);
      });
    }

    return () => {
      if (gpsSocket) {
        gpsSocket.off("gps_update");
      }
    };
  }, [gpsSocket]);

  const filteredVehicles = filterVehicles(vehicleData);

  return (
    <Card
      title={<CustomCardHeader count={filteredVehicles.length} />}
      className="Employee-sidebar-card"
    >
      <Collapse accordion className="ant-collapse-borderless">
        {filteredVehicles.map((vehicle) => (
          <Panel
            header={
              <Space>
                <div className="employee-info">
                  {vehicle.vehiclenumber}
                  <StatusTag
                    vehiclestatus={vehicle.vehiclestatus}
                    speed={vehicle.speed}
                  />
                  <div className="employee-role">{vehicle.drivername}</div>
                </div>
                <div style={{ fontWeight: "bold" }}>{vehicle.timeAgo}</div>
              </Space>
            }
            key={vehicle.regno}
          >
            <Row
              className="employee-details"
              onClick={() => VehicleSideBarClick(vehicle)}
            >
              <Col span={8} className="left-column">
                <p>
                  <strong className="description-color">Reg no</strong>
                  <br />
                  {vehicle.regno}
                </p>
                <p className="margin-top">
                  <strong className="description-color">
                    Current Location
                  </strong>
                  <br />
                  <EnvironmentOutlined className="location-color" />{" "}
                  <span className="location-color">
                    {vehicle.lastlocation.lat && vehicle.lastlocation.lng ? (
                      <ReverseGeocoding
                        lat={vehicle.lastlocation.lat}
                        lng={vehicle.lastlocation.lng}
                      />
                    ) : (
                      vehicle.lastlocation
                    )}
                  </span>
                </p>
              </Col>
              <Col span={8} className="middle-column">
                <p>
                  <strong className="description-color">Vin no</strong>
                  <br />
                  {vehicle.vehiclenumber}
                </p>
                <p className="margin-top">
                  <strong className="description-color">Distance (kms)</strong>
                  <br />
                  {vehicle.distance}
                </p>
              </Col>
              <Col span={8} className="right-column">
                <p>
                  <strong className="description-color">Speed (kms/hr)</strong>
                  <br />
                  <span>{vehicle.speed}</span>
                </p>
                <p className="margin-top">
                  <strong className="description-color">Project Name</strong>
                  <br />
                  <span>{vehicle.projects[0]?.project_name}</span>
                </p>
              </Col>
            </Row>
          </Panel>
        ))}
      </Collapse>
    </Card>
  );
};

export default VehicleSideBar;
