import React, { useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import VehicleFilter from "../../New/Vehicle/Table/vehicleFilter/VehicleFilterNavbar";
import VehicleTable from "./VehiclereportTable";
import ReportNavBar from "./VehicleReportNavBar";

export default function VehicleReport() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedSpeed, setSelectedSpeed] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const handleStartDateChange = (start) => {
    setStartDate(start);
  };

  const handleEndDateChange = (end) => {
    setEndDate(end);
  };

  const handleSpeedFilter = (speed) => {
    setSelectedSpeed(speed);
  };

  const handleVehicleTypeFilter = (vtype) => {
    setSelectedVehicleType(vtype);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const handleClearFilters = () => {
    setSelectedSpeed(null);
    setSelectedVehicleType(null);
    setSearchText("");
    setSelectedStatus("");
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <HeaderNav />
      <ReportNavBar
        startDate={startDate}
        endDate={endDate}
        onStartDateChange={handleStartDateChange}
        onEndDateChange={handleEndDateChange}
      />
      <VehicleFilter
        onSpeedSelect={handleSpeedFilter}
        selectedSpeed={selectedSpeed}
        onVehicleTypeSelect={handleVehicleTypeFilter}
        selectedVehicleType={selectedVehicleType}
        onSearch={handleSearch}
        searchText={searchText}
        onStatusSelect={handleStatusSelect}
        selectedStatus={selectedStatus}
        onClearFilters={handleClearFilters}
      />
      <VehicleTable
        selectedSpeed={selectedSpeed}
        selectedVehicleType={selectedVehicleType}
        searchText={searchText}
        selectedStatus={selectedStatus}
        startDate={startDate}
        endDate={endDate}
      />
    </>
  );
}
