// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
    overflow-x: hidden;
  }
.project-details-card{
    margin: 15px;
}
.stats-card{
    margin: 15px;
}
.ProjectViewValue{
    color: #001233 ;
    font-weight: bold;
    font-size: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/ProjectSites/ProjectView/projectView.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;EACpB;AACF;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":["body, html {\n    overflow-x: hidden;\n  }\n.project-details-card{\n    margin: 15px;\n}\n.stats-card{\n    margin: 15px;\n}\n.ProjectViewValue{\n    color: #001233 ;\n    font-weight: bold;\n    font-size: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
