// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table-header .ant-table-cell {
  padding: 8px;
}

.project-name-projectlist {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  color:steelblue;
}

.location-projectlist {
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.anchor-projectlist {
  color: #e76f51;
  cursor: pointer;
  margin-left: 5px;
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/ProjectSites/ProjectList.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;EACjB,eAAe;EACf,0BAA0B;EAC1B,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".custom-table-header .ant-table-cell {\n  padding: 8px;\n}\n\n.project-name-projectlist {\n  white-space: normal;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  font-weight: bold;\n  cursor: pointer;\n  text-decoration: underline;\n  color:steelblue;\n}\n\n.location-projectlist {\n  white-space: normal;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n.anchor-projectlist {\n  color: #e76f51;\n  cursor: pointer;\n  margin-left: 5px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
