import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../Axios/AxiosInstance";
import HeaderNav from "../Navbar/HeaderNav";
import {
  Form,
  Input,
  Select,
  Button,
  Breadcrumb,
  Row,
  Col,
  Typography,
  message,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Option } = Select;

const AddVehicle = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    imei: "",
    vehicleNumber: "",
    vehicleType: "",
    driverPhoneNumber: "",
    driverName: "",
    projectId: "",
  });
  const [imeiOptions, setImeiOptions] = useState([]);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [projects, setProjects] = useState([]);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    // Fetch IMEI options
    axios
      .get(
        `${process.env.React_App_API_URL}/imeimapping/imei-account-mappings?accountid=${accountid}`
      )
      .then((response) => {
        setImeiOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching IMEI numbers:", error);
      });

    // Fetch vehicle types
    axios
      .get(`${process.env.React_App_API_URL}/vehicletype/fetchvehicletype`)
      .then((response) => {
        setVehicleTypeOptions(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching vehicle type data:", error);
        setVehicleTypeOptions([]);
      });

    // Fetch Projects
    axiosInstance
      .get(`/project/getprojects`)
      .then((response) => {
        setProjects(response.data.projects);
      })
      .catch((error) => {
        console.error("Error fetching Working Sites:", error);
      });
  }, [accountid]);

  useEffect(() => {
    // Fetch driver phone numbers when projectId changes
    if (formData.projectId) {
      axios
        .post(
          `${process.env.React_App_API_URL}/employee/drivernumber?accountid=${accountid}`,
          { projectIds: [formData.projectId] }
        )
        .then((response) => {
          setDriverOptions(response.data.availableDrivers || []);

          // Clear driver fields when project changes
          setFormData((prevFormData) => ({
            ...prevFormData,
            driverPhoneNumber: "",
            driverName: "",
          }));
        })
        .catch((error) => {
          console.error("Error fetching driver phone numbers:", error);
        });
    }
  }, [formData.projectId, accountid]);

  const handleChange = (name, value) => {
    setFormData((prevFormData) => {
      const updatedFormData = {
        ...prevFormData,
        [name]: value,
      };

      // Set driver name and user_id based on selected phone number
      if (name === "driverPhoneNumber") {
        const selectedDriver = driverOptions.find(
          (driver) => driver.phonenumber === value
        );
        if (selectedDriver) {
          updatedFormData.driverName = selectedDriver.name;
          updatedFormData.userId = selectedDriver.id; // Keep this for reference if needed
        }
      }

      return updatedFormData;
    });
  };

  const handleCancel = () => {
    setFormData({
      imei: "",
      vehicleNumber: "",
      vehicleType: "",
      driverPhoneNumber: "",
      driverName: "",
      projectId: "",
    });
    navigate("/VehicleDashboardData");
  };

  const handleSubmit = async () => {
    const { imei, vehicleNumber, vehicleType, projectId } = formData;

    // Validate mandatory fields only
    if (!imei || !vehicleNumber || !vehicleType || !projectId) {
      message.error("Please fill in all mandatory fields before submitting.");
      return;
    }

    // Create request data without userId and driverPhoneNumber
    const requestData = {
      imei: imei,
      vehiclenumber: vehicleNumber,
      vehicletype: vehicleType,
      account_id: accountid,
      project_ids: [projectId],
    };

    if (formData.userId) {
      requestData.user_id = formData.userId; // Include userId only if it exists
    }

    try {
      const response = await axios.post(
        `${process.env.React_App_API_URL}/vehicle/addvehicle`,
        requestData
      );
      if (response.status === 201) {
        message.success("Vehicle added successfully");
        navigate("/VehicleDashboardData");
      } else {
        message.error("Failed to add vehicle");
      }
    } catch (error) {
      message.error("Error adding vehicle");
    }
  };

  const navigateToAddDriver = () => {
    navigate("/addemployee", { state: { role: "Driver" } });
  };

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span className="employee">Vehicle Registration</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            className="Exit"
            icon={<CloseOutlined />}
            onClick={handleCancel}
          >
            Exit
          </Button>
          <Button
            className="customAddButton"
            icon={<PlusOutlined />}
            onClick={handleSubmit}
          >
            <span className="addEmployee">Add</span>
          </Button>
        </div>
      </div>

      <div style={{ backgroundColor: "#f0f2f5", padding: "50px" }}>
        <div
          style={{
            padding: "20px",
            maxWidth: "700px",
            margin: "auto",
            backgroundColor: "#ffffff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4} className="title-color">
                Vehicle Details
              </Title>
            </Col>
            <Col span={24}>
              <Form layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">IMEI Number</span>}
                      required
                    >
                      <Select
                        placeholder="Select IMEI"
                        value={formData.imei}
                        onChange={(value) => handleChange("imei", value)}
                      >
                        {imeiOptions.map((option) => (
                          <Option key={option.imei} value={option.imei}>
                            {option.imei}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Vehicle Number</span>}
                      required
                    >
                      <Input
                        value={formData.vehicleNumber}
                        onChange={(e) =>
                          handleChange("vehicleNumber", e.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Vehicle Type</span>}
                      required
                    >
                      <Select
                        placeholder="Select Vehicle Type"
                        value={formData.vehicleType}
                        onChange={(value) => handleChange("vehicleType", value)}
                      >
                        {vehicleTypeOptions.map((option) => (
                          <Option
                            key={option.vtypeid}
                            value={option.vehicletype}
                          >
                            {option.vehicletype}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item
                      label={<span className="label-name">Project Name</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please Select a Project",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select a Project"
                        value={formData.projectId}
                        onChange={(value) =>
                          setFormData({ ...formData, projectId: value })
                        }
                      >
                        {projects
                          .filter((site) => !site.isdeleted)
                          .map((site) => (
                            <Option key={site.id} value={site.id}>
                              {site.projectname}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={
                        <span className="label-name">Driver Phone Number</span>
                      }
                    >
                      <Select
                        placeholder="Select Driver Phone Number (Optional)"
                        value={formData.driverPhoneNumber}
                        onChange={(value) =>
                          handleChange("driverPhoneNumber", value)
                        }
                      >
                        {driverOptions.map((driver) => (
                          <Option
                            key={driver.phonenumber}
                            value={driver.phonenumber}
                          >
                            {driver.phonenumber}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <span
                      onClick={navigateToAddDriver}
                      style={{
                        color: "steelblue",
                        cursor: "pointer",
                        display: "block",
                        textAlign: "left",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      Add new driver
                    </span>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Driver Name</span>}
                    >
                      <Input
                        value={formData.driverName}
                        onChange={(e) =>
                          handleChange("driverName", e.target.value)
                        }
                        disabled
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddVehicle;
