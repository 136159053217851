import React, { useState } from "react";
import VehicleLiveLocation from "./VehicleLiveLocation";
import VehicleSideBar from "./vehicleSidebar";
import VehicleFilter from "./VehicleFilter";
import MapVehicleEmployeeFilter from "../MapFilter/MapVehicleEmployeeFilter";
import HeaderNav from "../../Navbar/HeaderNav";
import { Row, Col } from "antd";

function VehicleMapDashboard() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleVehicleTypeFilter = (vtype) => {
    setSelectedVehicleType(vtype);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleClearFilters = () => {
    setSelectedProject(null);
    setSelectedVehicleType(null);
    setSelectedStatus(null);
    setSearchText(null);
  };

  const handleVehicleSelect = (vehicle) => {
    setSelectedVehicle(vehicle);
  };

  const handleClearSelectedVehicle = () => {
    setSelectedVehicle(null);
  };

  return (
    <>
      <HeaderNav />
      <MapVehicleEmployeeFilter />
      <VehicleFilter
        onProjectSelect={handleProjectSelect}
        selectedProject={selectedProject}
        onVehicleTypeSelect={handleVehicleTypeFilter}
        selectedVehicleType={selectedVehicleType}
        onStatusSelect={handleStatusChange}
        selectedStatus={selectedStatus}
        onSearch={handleSearchChange}
        searchText={searchText}
        onClearFilters={handleClearFilters}
      />
      <Row>
        <Col span={18} style={{ maxWidth: "65%" }}>
          <VehicleLiveLocation
            selectedProject={selectedProject}
            selectedStatus={selectedStatus}
            searchText={searchText}
            selectedVehicleType={selectedVehicleType}
            VehicleSideBarClick={handleVehicleSelect}
            selectedVehicle={selectedVehicle}
            setSelectedVehicle={handleClearSelectedVehicle}
          />
        </Col>
        <Col span={6} style={{ minWidth: "35%" }}>
          <VehicleSideBar
            selectedProject={selectedProject}
            selectedVehicleType={selectedVehicleType}
            selectedStatus={selectedStatus}
            searchText={searchText}
            VehicleSideBarClick={handleVehicleSelect}
            selectedVehicle={selectedVehicle}
          />
        </Col>
      </Row>
    </>
  );
}

export default VehicleMapDashboard;
