import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Badge,
  Button,
  Modal,
  Typography,
  Card,
  Row,
  Col,
  Divider,
  message,
} from "antd";
import {
  BellOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./TempGeofence.css";

const { Title, Paragraph } = Typography;
const { confirm } = Modal;

function TempGeofence() {
  const [count, setCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [tempData, setTempData] = useState([]);
  const navigate = useNavigate();
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchTempGeofenceData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/project-survey/survey-details/${accountid}`
        );
        const tempData = response.data;
        setCount(tempData.data.length);
        setTempData(tempData.data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchTempGeofenceData();
  }, [accountid]);

  const togglePopup = () => {
    if (count > 0) {
      setShowPopup(!showPopup);
    }
  };

  const handleReject = async (survey_id) => {
    try {
      const response = await axios.patch(
        `${process.env.React_App_API_URL}/project-survey/reject/${accountid}?surveyid=${survey_id}`
      );
      if (response.status === 200) {
        message.success("Survey Rejected Successfully!");

        // Remove the rejected item from tempData and update the count
        setTempData((prevTempData) =>
          prevTempData.filter((item) => item.survey_id !== survey_id)
        );
        setCount((prevCount) => prevCount - 1);
      } else {
        message.error("Failed to Reject Survey");
      }
    } catch (error) {
      message.error("Error while rejecting survey");
    }
  };

  // Show confirmation dialog before rejecting the survey
  const showDeleteConfirm = (survey_id) => {
    confirm({
      title: (
        <span className="modal-confirm-title">
          WARNING: Deleting this Survey is Permanent!
        </span>
      ),
      icon: <ExclamationCircleOutlined className="modal-confirm-icon" />,
      content: (
        <span className="modal-confirm-content">
          Are you absolutely sure you want to delete this Survey? This action{" "}
          <strong>cannot be undone</strong>, and all associated data will be
          lost forever.
        </span>
      ),
      okText: "Yes, Reject",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: {
        className: "modal-confirm-ok-button",
      },
      cancelButtonProps: {
        className: "modal-confirm-cancel-button",
      },
      onOk() {
        handleReject(survey_id);
      },
      onCancel() {
        console.log("Cancel rejection");
      },
    });
  };

  return (
    <div>
      <Badge
        count={count}
        overflowCount={99}
        onClick={togglePopup}
        className="notification-badge"
      >
        <BellOutlined style={{ fontSize: "24px", color: "white" }} />
      </Badge>
      <Modal
        visible={showPopup}
        onCancel={togglePopup}
        footer={null}
        width={800}
        className="notification-modal"
      >
        <div className="modal-header">
          <Title level={4} className="modal-title">
            Notifications
          </Title>
        </div>
        <div className="modal-content">
          <Row gutter={16}>
            {tempData.map((item, index) => (
              <Col span={24} key={item.survey_id}>
                <Title level={5} className="modal-title">
                  Geofence Approval
                </Title>
                <Paragraph className="sent-by">
                  You have received a request to approve Geofence coordinates.
                </Paragraph>
                <Card className="notification-card">
                  <Typography className="project-name-id">
                    <strong>{item.project_name}</strong>
                  </Typography>
                  <Typography className="sent-by">
                    Sent By:
                    <br />{" "}
                    <span className="user-name-phone">
                      {item.user_name} ({item.user_phone})
                    </span>
                  </Typography>
                  <div className="button-group">
                    <Button
                      type="primary"
                      icon={<EyeOutlined />}
                      onClick={() => {
                        setShowPopup(false);
                        navigate("/geofence/preview", {
                          state: { survey_id: item.survey_id },
                        });
                      }}
                      className="accept-btn"
                    >
                      Preview
                    </Button>
                    <Button
                      type="default"
                      icon={<CloseCircleOutlined />}
                      onClick={() => showDeleteConfirm(item.survey_id)}
                      className="reject-btn"
                    >
                      Reject
                    </Button>
                  </div>
                </Card>
                {index < tempData.length - 1 && (
                  <Divider className="divider-temp" />
                )}
              </Col>
            ))}
          </Row>
        </div>
      </Modal>
    </div>
  );
}

export default TempGeofence;
