import React, { useState } from "react";
import { jwtDecode } from "jwt-decode";
import {
  Form,
  Input,
  Button,
  Typography,
  Checkbox,
  Row,
  Col,
  Modal,
  Card,
  Spin,
} from "antd";
import { useNavigate } from "react-router-dom";
import "./SignIn.css";
import CivilBrainlogo from "../assets/SignUp/Civil Brain Logo Color.svg";

const { Title } = Typography;

function SignIn() {
  const [errorMessage, setErrorMessage] = useState("");
  const [popupMessage, setPopupMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    handleLogin(values);
  };

  const handleLogin = async (values) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.React_App_API_URL}/login/admin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailid: values.email,
            password: values.password,
          }),
        }
      );

      const data = await response.json();
      console.log("API Response Data:", data);

      if (response.ok && data.adminUser) {
        localStorage.setItem("isLoggedIn", "true");
        localStorage.setItem("accountid", data.adminUser.accountid);
        localStorage.setItem("adminid", data.adminUser.adminid);
        localStorage.setItem("token", data.token);

        const decodedToken = jwtDecode(data.token);
        const expiryTime = decodedToken.exp * 1000;
        localStorage.setItem("expiryTime", expiryTime);

        navigate("/");
        window.location.reload();
      } else {
        setErrorMessage(data.error || "Invalid Email ID / Password!");
      }
    } catch (error) {
      setErrorMessage("Error during login. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleOk = () => {
    setOpen(false);
  };

  return (
    <div className="sign-in-page">
      {loading && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 9998,
            }}
          />
          <Spin
            size="large"
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 9999,
            }}
          />
        </>
      )}
      <img src={CivilBrainlogo} alt="CivilBrain Logo" className="logo" />
      <Card className="sign-in-card">
        <Title level={2} className="sign-in-title">
          Sign In
        </Title>
        <Form
          name="signIn"
          onFinish={onFinish}
          layout="vertical"
          className="sign-in-form"
        >
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
            className="sign-in-form-item"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
            className="sign-in-form-item"
            validateStatus={errorMessage ? "error" : ""}
            help={errorMessage}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Row justify="space-between" align="middle">
              <Col>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                  <Checkbox className="remember-me">Remember me</Checkbox>
                </Form.Item>
              </Col>
              <Col>
                <a href="/forgotpassword" className="forgot-password-link">
                  Forgot password
                </a>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item>
            <Button htmlType="submit" className="sign-in-button">
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Typography.Paragraph className="sign-in-text">
        Don't have an account? <a href="/signup">Sign up</a>
      </Typography.Paragraph>
      <Modal
        title={
          <span style={{ color: "#001233", fontWeight: "bold" }}>
            Login Error
          </span>
        }
        visible={open}
        centered
        onCancel={handleOk}
        footer={[
          <Button
            key="ok"
            type="primary"
            style={{
              backgroundColor: "#E76F51",
              borderColor: "#E76F51",
              color: "#001233",
              fontWeight: "bold",
            }}
            onClick={handleOk}
          >
            OK
          </Button>,
        ]}
      >
        <p style={{ color: "#001233" }}>{popupMessage}</p>
      </Modal>
    </div>
  );
}

export default SignIn;
