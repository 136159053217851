import React, { useState } from "react";
import {
  GoogleMap,
  LoadScript,
  DrawingManager,
  Polygon,
} from "@react-google-maps/api";
import { Box, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

const center = {
  lat: 20.5937,
  lng: 78.9629,
};

function DrawGeofence() {
  const [polygons, setPolygons] = useState([]);
  const [selectedPolygon, setSelectedPolygon] = useState(null);

  const handlePolygonComplete = (polygon) => {
    const path = polygon
      .getPath()
      .getArray()
      .map((latlng) => ({
        lat: latlng.lat(),
        lng: latlng.lng(),
      }));

    setPolygons([...polygons, { id: polygons.length, path }]);
    polygon.setMap(null); // Remove the drawing polygon to avoid duplicates
  };

  const handlePolygonClick = (index) => {
    setSelectedPolygon(index);
  };

  const handleDeleteSelectedPolygon = () => {
    if (selectedPolygon !== null) {
      const updatedPolygons = polygons.filter(
        (_, index) => index !== selectedPolygon
      );
      setPolygons(updatedPolygons);
      setSelectedPolygon(null);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={process.env.React_App_GOOGLE_MAPS_API_KEY}
      libraries={["drawing"]}
    >
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={5}>
        <DrawingManager
          onPolygonComplete={handlePolygonComplete}
          options={{
            drawingControlOptions: {
              drawingModes: ["polygon"],
            },
            polygonOptions: {
              editable: true,
              draggable: true,
            },
          }}
        />
        {polygons.map((polygonData, index) => (
          <Polygon
            key={index}
            path={polygonData.path}
            editable={selectedPolygon === index}
            draggable={selectedPolygon === index}
            onClick={() => handlePolygonClick(index)}
          />
        ))}
      </GoogleMap>
      <Box
        position="absolute"
        top="70px"
        right="15px"
        bgcolor="white"
        borderRadius="50%"
        boxShadow="0 0 5px rgba(0, 0, 0, 0.5)"
      >
        <IconButton onClick={handleDeleteSelectedPolygon} color="secondary">
          <DeleteIcon />
        </IconButton>
      </Box>
    </LoadScript>
  );
}

export default DrawGeofence;
