// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mt-20 {
  margin: 20px !important;
}

.reportstyle {
  font-weight: bold !important;
  margin: 24px !important;
  font-size: 24px !important;
  color: #001233 !important;
}

.newreportstyle {
  font-weight: bold !important;
  margin: 18px !important;
  font-size: 24px !important;
  color: #001233 !important;
}

.report-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.report-icon-container {
  position: relative;
  display: flex;
  align-items: center;
}

.report-tag {
  position: absolute;
  top: 10px;
  right: 10px;
}

.reportvehicleimage,
.reportEmployeeicon {
  width: 56px !important;
  height: 56px !important;
  margin: 0.3rem;
}

.employeebutton {
  width: 100% !important;
  height: 45px;
  background: #e6e8eb;
  border-radius: 8px;
  margin-top: 1rem;
  color: #001233 !important;
  font-weight: bold !important;
  transition: background 0.3s;
}

.employeebutton:hover {
  background: #e76f51 !important;
  border: none;
  color: #001233 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/Report/ReportDashboard.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,0BAA0B;EAC1B,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;;AAEA;;EAEE,sBAAsB;EACtB,uBAAuB;EACvB,cAAc;AAChB;;AAEA;EACE,sBAAsB;EACtB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,4BAA4B;EAC5B,2BAA2B;AAC7B;;AAEA;EACE,8BAA8B;EAC9B,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".mt-20 {\n  margin: 20px !important;\n}\n\n.reportstyle {\n  font-weight: bold !important;\n  margin: 24px !important;\n  font-size: 24px !important;\n  color: #001233 !important;\n}\n\n.newreportstyle {\n  font-weight: bold !important;\n  margin: 18px !important;\n  font-size: 24px !important;\n  color: #001233 !important;\n}\n\n.report-card {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n}\n\n.report-icon-container {\n  position: relative;\n  display: flex;\n  align-items: center;\n}\n\n.report-tag {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n}\n\n.reportvehicleimage,\n.reportEmployeeicon {\n  width: 56px !important;\n  height: 56px !important;\n  margin: 0.3rem;\n}\n\n.employeebutton {\n  width: 100% !important;\n  height: 45px;\n  background: #e6e8eb;\n  border-radius: 8px;\n  margin-top: 1rem;\n  color: #001233 !important;\n  font-weight: bold !important;\n  transition: background 0.3s;\n}\n\n.employeebutton:hover {\n  background: #e76f51 !important;\n  border: none;\n  color: #001233 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
