import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

const Gps = () => {
  const [gpsData, setGpsData] = useState([]);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const socket = io('http://localhost:8081/civilbrain-gps');

    // Join the room for the specific accountId
    socket.emit('joinRoom', accountid);

    socket.on('gps_update', (data) => {
      console.log('Received GPS update:', data);
      setGpsData(data);
    });

    return () => {
      socket.emit('leaveRoom', accountid);
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      <h1>GPS Data</h1>
      {gpsData.length > 0 ? (
        <div>
          {gpsData.map((item, index) => (
            <div key={index}>
              <p><strong>IMEI:</strong> {item.imei}</p>
              <p><strong>Date:</strong> {item.date}</p>
              <p><strong>Time:</strong> {item.time}</p>
              <p><strong>Latitude:</strong> {item.latitude}</p>
              <p><strong>Longitude:</strong> {item.longitude}</p>
              <p><strong>Speed:</strong> {item.speed}</p>
              <p><strong>Altitude:</strong> {item.altitude}</p>
              <p><strong>COG:</strong> {item.cog}</p>
              <p><strong>Ignition Status:</strong> {item.ignitionStatus}</p>
              <p><strong>Account ID:</strong> {item.accountid}</p>
              <p><strong>Vehicle ID:</strong> {item.vehicleid}</p>
              <hr />
            </div>
          ))}
        </div>
      ) : (
        <p>No GPS data available</p>
      )}
    </div>
  );
};

export default Gps;
