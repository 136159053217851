import React, { useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import VehicleTable from "./Table/VehicleTable";
import EmployeeVehicleFilterNavbar from "../Employee/EmployeeVehicleFilterNavbar/EmployeeVehicleFilterNavbar";
import VehicleFilter from "../Dashboard/VehicleMap/VehicleFilter";

function VehicleDashboard() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchText, setSearchText] = useState("");

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleVehicleTypeFilter = (vtype) => {
    setSelectedVehicleType(vtype);
  };

  const handleStatusChange = (status) => {
    setSelectedStatus(status);
  };

  const handleSearchChange = (text) => {
    setSearchText(text);
  };

  const handleClearFilters = () => {
    setSelectedProject(null);
    setSelectedVehicleType(null);
    setSelectedStatus(null);
    setSearchText(null);
  };
  return (
    <>
      <HeaderNav />
      <EmployeeVehicleFilterNavbar />
      <VehicleFilter
        onProjectSelect={handleProjectSelect}
        selectedProject={selectedProject}
        onVehicleTypeSelect={handleVehicleTypeFilter}
        selectedVehicleType={selectedVehicleType}
        onStatusSelect={handleStatusChange}
        selectedStatus={selectedStatus}
        onSearch={handleSearchChange}
        searchText={searchText}
        onClearFilters={handleClearFilters}
      />
      <VehicleTable
        selectedProject={selectedProject}
        selectedVehicleType={selectedVehicleType}
        selectedStatus={selectedStatus}
        searchText={searchText}
      />
    </>
  );
}

export default VehicleDashboard;
