import React, { useState, useEffect } from "react";
import { ReactComponent as CbLogo } from "../assets/SignUp/Civil Brain Logo Color.svg";
import { ReactComponent as CbFrame } from "../assets/SignUp/Frame.svg";
import { ReactComponent as Backarrow } from "../assets/SignUp/backarrow.svg";
import { Form, Input, Button, Card, Typography, message } from "antd";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import "./SignUp.css";

const { Text } = Typography;

const AdditionalInfo = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");

  const navigate = useNavigate();
  const location = useLocation();
  const { email, password } = location.state || {};

  message.config({
    top: 700,
  });

  useEffect(() => {
    if (!email || !password) {
      message.error("Missing email or password. Please sign up again.");
      navigate("/signup");
    }
  }, [email, password, navigate]);

  const sendSupportEmail = async (accountId, adminId) => {
    try {
      await axios.post(
        `${process.env.React_App_API_URL}/admin/support/sendemail?accountid=${accountId}&adminid=${adminId}`
      );
      console.log("Support email sent successfully.");
    } catch (error) {
      console.error("Failed to send support email:", error);
      message.error("Failed to send support email. Please try again.");
    }
  };

  const handleAdditionalInfoSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.React_App_API_URL}/admin/signup`,
        {
          emailid: email,
          firstname: firstName,
          lastname: lastName,
          phonenumber: phoneNumber,
          companyname: companyName,
          address: address,
          password: password,
        }
      );

      const { accountid, adminid } = response.data;

      message.success("Registration completed successfully!");

      // Call the API to send the support email with the correct accountid and adminid
      await sendSupportEmail(accountid, adminid);
      navigate("/SignUpAck");
    } catch (error) {
      console.error("Failed to register user:", error);
      message.error("Failed to complete registration. Please try again.");
    }
  };

  return (
    <div className="signup-container">
      <div className="frame-container">
        <Text className="frame-text">
          Embark on a transformative journey with Civil Brain AI
        </Text>
        <CbFrame className="frame" />
      </div>

      <div className="cb-logo-container">
        <CbLogo className="cb-logo-ad" />

        <Card className="form-card-ad">
          <div className="back-cnt">
            <Backarrow className="back-arrow" />
            <a href="/signup" className="back-link">
              Back
            </a>
          </div>

          <Form
            name="additional-info-form"
            layout="vertical"
            onFinish={handleAdditionalInfoSubmit}
          >
            <Form.Item
              label="First Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your first name!",
                },
              ]}
            >
              <Input
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your first name"
              />
            </Form.Item>

            <Form.Item
              label="Last Name"
              rules={[
                { required: true, message: "Please enter your last name!" },
              ]}
            >
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Enter your last name"
              />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please enter your phone number!",
                },
              ]}
            >
              <Input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                placeholder="Enter your phone number"
              />
            </Form.Item>

            <Form.Item
              label="Company Name"
              rules={[
                {
                  required: true,
                  message: "Please enter your company name!",
                },
              ]}
            >
              <Input
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Enter your company name"
              />
            </Form.Item>

            <Form.Item
              label="Address"
              rules={[
                { required: true, message: "Please enter your address!" },
              ]}
            >
              <Input
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter your address"
              />
            </Form.Item>

            <Form.Item>
              <Button className="complete-setup-btn" htmlType="submit">
                Complete Setup
              </Button>
            </Form.Item>
          </Form>
        </Card>
        <Typography.Text className="signin-text">
          Already have an account? <a href="/signin">Sign in</a>
        </Typography.Text>
      </div>
    </div>
  );
};

export default AdditionalInfo;
