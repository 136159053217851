import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { Switch } from "antd";
import axios from "axios";
import OnlineSvgIcon from "../components/assets/EmployeeIcon/OnlineEmployee.svg";
import OfflineSvgIcon from "../components/assets/EmployeeIcon/OfflineEmployee.svg";
import { useSocket } from "../Socket/SocketContext";

const containerStyle = {
  width: "100%",
  height: "70vh",
  borderRadius: "12px",
};

const LiveLocation = ({
  selectedProject,
  selectedRole,
  selectedStatus,
  searchQuery,
  selectedEmployeeSidebar,
  setSelectedEmployeeSidebar,
}) => {
  const [geofences, setGeofences] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const mapRef = useRef(null);
  const [mapCenter] = useState({ lat: 24.3531, lng: 85.2965 });
  const [showGeofences, setShowGeofences] = useState(false);

  const accountid = localStorage.getItem("accountid");
  const apiKey = process.env.React_App_GOOGLE_MAPS_API_KEY;
  const socket = useSocket();

  useEffect(() => {
    const fetchGeofenceData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/geofence/getgeofences?accountid=${accountid}`
        );
        if (response.status === 200 && Array.isArray(response.data.geofences)) {
          setGeofences(response.data.geofences);
        } else {
          console.error("Invalid geofences data format");
          setGeofences([]);
        }
      } catch (error) {
        console.error("Error fetching geofence data:", error.message);
        setGeofences([]);
      }
    };

    const fetchEmployeeData = async () => {
      try {
        let url = `${process.env.React_App_API_URL}/home/map/getemployees?accountid=${accountid}`;
        if (selectedRole) {
          url += `&roleid=${selectedRole}`;
        }

        const response = await axios.get(url);
        if (
          response.status === 200 &&
          response.data.data &&
          Array.isArray(response.data.data.results)
        ) {
          setLocations(
            response.data.data.results.map((location) => ({
              lat: location.latitude,
              lng: location.longitude,
              name: location.name,
              role: location.role_name,
              phonenumber: location.phonenumber,
              onlinestatus: location.onlinestatus,
              projectid: location.project_ids,
            }))
          );
        } else {
          console.error("Invalid employee data format");
          setLocations([]);
        }
      } catch (error) {
        console.error("Error fetching employee data:", error.message);
        setLocations([]);
      }
    };

    fetchGeofenceData();
    fetchEmployeeData();
  }, [accountid, selectedRole]);

  useEffect(() => {
    if (socket) {
      socket.on("locationsData", (data) => {
        console.log("Received socket data:", data);
        setLocations((prevLocations) => {
          const updatedLocations = data.map((location) => ({
            lat: location.latitude,
            lng: location.longitude,
            name: location.name,
            role: location.role_name,
            phonenumber: location.phonenumber,
            onlinestatus: location.onlinestatus,
            projectid: location.project_ids,
          }));

          return updatedLocations;
        });
      });

      socket.on("adminIdAcknowledged", () => {
        console.log("Admin ID has been passed to the backend successfully");
      });

      return () => {
        socket.off("locationsData");
        socket.off("adminIdAcknowledged");
      };
    }
  }, [socket, accountid]);

  const handleMarkerClick = (location) => {
    setSelectedMarker(location);
  };

  const handleMapClick = () => {
    setSelectedMarker(null);
  };

  if (selectedProject && showGeofences) {
    const selectedSiteGeofence = geofences.find(
      (geofence) => geofence.projectid === selectedProject
    );
    if (selectedSiteGeofence) {
      const coordinates = selectedSiteGeofence.coordinates;
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coord) =>
        bounds.extend(
          new window.google.maps.LatLng(coord.latitude, coord.longitude)
        )
      );

      // Zoom to the geofence bounds
      mapRef.current.fitBounds(bounds);
    }
  }

  const filteredLocations = locations
    .filter((location) => {
      if (selectedStatus === "active") {
        return location.onlinestatus === true;
      } else if (selectedStatus === "inactive") {
        return location.onlinestatus === false;
      } else {
        return true;
      }
    })
    .filter(
      (location) =>
        (selectedProject
          ? location.projectid.includes(selectedProject)
          : true) &&
        (searchQuery === "" ||
          location.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          location.phonenumber.includes(searchQuery))
    );

  const getMarkerIcon = (onlinestatus) => {
    if (window.google && window.google.maps) {
      return {
        url: onlinestatus ? OnlineSvgIcon : OfflineSvgIcon,
        scaledSize: new window.google.maps.Size(60, 60),
      };
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (selectedEmployeeSidebar && locations.length > 0) {
      const matchingLocation = locations.find(
        (location) =>
          location.phonenumber === selectedEmployeeSidebar.phonenumber
      );

      if (matchingLocation) {
        const position = {
          lat: matchingLocation.lat,
          lng: matchingLocation.lng,
        };

        mapRef.current.panTo(position);
        mapRef.current.setZoom(22);
        setSelectedMarker(matchingLocation);
        setSelectedEmployeeSidebar(null);
      }
    }
  }, [selectedEmployeeSidebar, locations, setSelectedEmployeeSidebar]);

  return (
    <div className="bodycolor">
      <LoadScript googleMapsApiKey={apiKey}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={7}
          options={{
            zoomControl: true,
            scrollwheel: true,
            gestureHandling: "cooperative",
          }}
          onLoad={(map) => (mapRef.current = map)}
          onClick={handleMapClick}
        >
          {showGeofences && (
            <React.Fragment>
              {selectedProject
                ? geofences
                    .filter(
                      (geofence) => geofence.projectid === selectedProject
                    )
                    .map((geofence, index) => {
                      const coordinates = geofence.coordinates;
                      return (
                        <Polygon
                          key={index}
                          paths={coordinates.map((coord) => ({
                            lat: coord.latitude,
                            lng: coord.longitude,
                          }))}
                          options={{
                            fillColor: "blue",
                            fillOpacity: 0.3,
                            strokeColor: "#E76F51",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                          }}
                        />
                      );
                    })
                : geofences.map((geofence, index) => {
                    const coordinates = geofence.coordinates;
                    return (
                      <Polygon
                        key={index}
                        paths={coordinates.map((coord) => ({
                          lat: coord.latitude,
                          lng: coord.longitude,
                        }))}
                        options={{
                          fillColor: "blue",
                          fillOpacity: 0.3,
                          strokeColor: "#E76F51",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                        }}
                      />
                    );
                  })}
            </React.Fragment>
          )}

          <div
            style={{
              position: "absolute",
              width: 206,
              height: 52,
              bottom: 20,
              left: 20,
              border: "1px solid #ddd",
              borderRadius: 12,
              padding: 8,
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
          >
            <Switch
              checked={showGeofences}
              onChange={(checked) => setShowGeofences(checked)}
              style={{ backgroundColor: showGeofences ? "#E76F51" : "" }}
            />
            <span style={{ marginLeft: 8 }}>Show Geofences</span>
          </div>

          <MarkerClusterer>
            {(clusterer) =>
              filteredLocations.map((location, index) => (
                <Marker
                  key={index}
                  position={{ lat: location.lat, lng: location.lng }}
                  icon={getMarkerIcon(location.onlinestatus)}
                  clusterer={clusterer}
                  onClick={() => handleMarkerClick(location)}
                />
              ))
            }
          </MarkerClusterer>

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: selectedMarker.lat,
                lng: selectedMarker.lng,
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <div>
                <p>
                  <strong style={{ color: "#001233", fontWeight: "bold" }}>
                    Name:
                  </strong>{" "}
                  <span style={{ color: "#E76F51", fontWeight: "bold" }}>
                    {selectedMarker.name}
                  </span>
                </p>
                <p>
                  <strong style={{ color: "#001233", fontWeight: "bold" }}>
                    Role:
                  </strong>{" "}
                  <span style={{ color: "#E76F51", fontWeight: "bold" }}>
                    {selectedMarker.role}
                  </span>
                </p>
                <p>
                  <strong style={{ color: "#001233", fontWeight: "bold" }}>
                    Phone:
                  </strong>{" "}
                  <span style={{ color: "#E76F51", fontWeight: "bold" }}>
                    {selectedMarker.phonenumber}
                  </span>
                </p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default LiveLocation;
