import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Button, Card, Image, Divider } from "antd";
import axios from "axios";
import "./ActivityLog.css";
import { useLocation, useNavigate } from "react-router-dom";

const { Paragraph } = Typography;

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  return date.toLocaleString();
};

const ActivityLog = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { site } = location.state;
  const projectId = site.id;
  const geofence = site.isgeofence;
  const accountid = localStorage.getItem("accountid");
  const [activityData, setActivityData] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.React_App_API_URL}/project/viewprojectactivity/${projectId}?accountid=${accountid}`,
        {}
      )
      .then((response) => {
        const data = response.data.data;
        setActivityData(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [projectId, accountid]);

  // Group data by name, activity details, and created date
  const groupedData = activityData.reduce((acc, activity) => {
    const key = `${activity.name}_${activity.activitydetails}_${formatDate(
      activity.created_at
    )}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(activity);
    return acc;
  }, {});

  return (
    <Row gutter={[23, 23]}>
      <Col span={16}>
        <Card
          title={
            <span style={{ color: "#001233", fontWeight: "bold" }}>
              Activity log
            </span>
          }
          className="project-details-card"
          style={{ maxHeight: "500px", overflowY: "auto", overflowX: "auto" }}
        >
          {Object.keys(groupedData).map((key, index) => {
            const activities = groupedData[key];
            return (
              <div key={index}>
                <div style={{ whiteSpace: "nowrap" }}>
                  <Row gutter={16} align="middle">
                    {activities.map((activity, idx) =>
                      activity.images.map((image, imgIdx) => (
                        <Col key={imgIdx}>
                          <div
                            style={{
                              width: "88px",
                              height: "88px",
                              borderRadius: "8px",
                              overflow: "hidden",
                              marginRight: "10px",
                              border: `1px solid #FDF1EE`,
                            }}
                          >
                            <Image
                              src={`${process.env.React_App_API_URL}/project/stream-image/${image.fileName}`}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </Col>
                      ))
                    )}
                  </Row>
                </div>
                <Paragraph style={{ color: "#001233", marginTop: "10px" }}>
                  {activities[0].activitydetails}
                </Paragraph>
                <div style={{ textAlign: "start", marginTop: "10px" }}>
                  <span className="update">
                    Updated by {activities[0].name}
                  </span>
                  <br />
                  <span className="update">
                    at {formatDate(activities[0].created_at)}
                  </span>
                </div>
                <Divider />
              </div>
            );
          })}
        </Card>
      </Col>
      {/* right side box  */}
      <Col span={8}>
        <Card
          title={
            <span style={{ color: "#001233", fontWeight: "bold" }}>
              Quick Links
            </span>
          }
          bordered={true}
          className="stats-card"
        >
          <Row gutter={[16, 8]}>
            {/* Box for Total Employees */}
            <Col span={24}>
              <Button
                onClick={() =>
                  navigate(`/EmployeeData`, { state: { projectId } })
                }
                className="btnViewVehicle"
              >
                View Employees
              </Button>
            </Col>
            {/* Box for Total Vehicles */}
            <Col span={24}>
              <Button
                onClick={() =>
                  navigate(`/VehicleDashboardData`, { state: { projectId } })
                }
                className="btnViewVehicle"
              >
                View Vehicles
              </Button>
            </Col>
            <Col span={24}>
              <Button
                onClick={() =>
                  navigate("/project/viewSurvey", {
                    state: { survey_id: site.survey_id },
                  })
                }
                className="btnViewVehicle"
                disabled={!geofence}
              >
                View Geofence
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ActivityLog;
