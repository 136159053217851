import React, { useState } from "react";
import HeaderNav from "../Navbar/HeaderNav"
import EmployeeTable from "./table/EmployeeTable";
import EmployeeFilterMap from "../Dashboard/EmployeeMap/EmployeeFilterMap";
import EmployeeVehicleFilterNavbar from "./EmployeeVehicleFilterNavbar/EmployeeVehicleFilterNavbar";

import "./EmployeeDashboard.css";

function EmployeeDashboard() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleClearFilters = () => {
    setSelectedProject(null);
    setSelectedRole(null);
    setSelectedStatus(null);
    setSearchQuery("");
  };

  return (
    <>
      <HeaderNav />
      <EmployeeVehicleFilterNavbar />
      <EmployeeFilterMap
        onProjectSelect={handleProjectSelect}
        onRoleSelect={handleRoleSelect}
        onStatusSelect={handleStatusSelect}
        onClearFilters={handleClearFilters}
        onSearch={handleSearch}
        selectedProject={selectedProject}
        selectedRole={selectedRole}
        selectedStatus={selectedStatus}
        searchQuery={searchQuery}
      />
      <EmployeeTable
        selectedProject={selectedProject}
        selectedRole={selectedRole}
        selectedStatus={selectedStatus}
        searchQuery={searchQuery}
      />
    </>
  );
}

export default EmployeeDashboard;
