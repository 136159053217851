import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import "./EmployeeTable.css";

function EmployeeTable({
  selectedProject,
  selectedRole,
  selectedStatus,
  searchQuery,
}) {
  const [EmployeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    if (!accountid) {
      console.error("Account ID is required");
      return;
    }

    const url = new URL(`${process.env.React_App_API_URL}/home/getemployees`);
    url.searchParams.append("accountid", accountid);
    if (selectedProject) {
      url.searchParams.append("projectid", selectedProject);
    }
    if (selectedRole) {
      url.searchParams.append("roleid", selectedRole);
    }

    const eventSource = new EventSource(url);

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);

      if (data.status === "success") {
        let employeeData = data.data.results;

        if (Array.isArray(employeeData)) {
          if (searchQuery) {
            employeeData = employeeData.filter(
              (item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.phonenumber.includes(searchQuery)
            );
          }
          const employeeDataWithKeys = employeeData.map((item, index) => ({
            ...item,
            key: index,
          }));

          let filteredData;
          if (selectedStatus === "active") {
            filteredData = employeeDataWithKeys.filter(
              (item) => item.onlinestatus === true
            );
          } else if (selectedStatus === "inactive") {
            filteredData = employeeDataWithKeys.filter(
              (item) => item.onlinestatus === false
            );
          } else {
            filteredData = employeeDataWithKeys;
          }

          setEmployeeData(filteredData);
        } else {
          console.error(
            "Error: Fetched data is not in the expected format:",
            data
          );
        }
      } else {
        console.error("Error: Failed to fetch employee details", data.message);
      }
    };

    eventSource.onerror = (error) => {
      console.error("Error in SSE connection:", error);
      eventSource.close();
    };

    return () => {
      eventSource.close();
    };
  }, [selectedProject, selectedRole, selectedStatus, accountid, searchQuery]);
  const columns = [
    {
      title: "Sr No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 80,
      render: (text, record, index) => (
        <span className="customTextColor">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Current Status",
      dataIndex: "onlinestatus",
      key: "onlinestatus",
      render: (status) => (
        <Tag color={status ? "green" : "default"}>
          {status ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "projectnames",
      key: "projectnames",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Battery Status",
      dataIndex: "batterystatus",
      key: "batteryststus",
      render: (text) => <span className="customTextColor">{text + " %"}</span>,
    },
    {
      title: "Last activity updated",
      dataIndex: "lastactivity",
      key: "lastactivity",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Current Location",
      dataIndex: "location",
      key: "location",
      render: (location) => (
        <span>
          <EnvironmentOutlined style={{ color: "#E76F51" }} />{" "}
          <span className="currentlocationColor">{location}</span>
        </span>
      ),
    },
    {
      title: "Geofence Status",
      dataIndex: "geofencestatus",
      key: "geofencestatus",
      render: (text, record) => {
        const status = record.onlinestatus ? text : "Outside";
        const color = status === "Inside" ? "purple" : "red";
        return (
          <Tag color={color}>{status === "Inside" ? "Inside" : "Outside"}</Tag>
        );
      },
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={EmployeeData}
      pagination={{ pageSize: 100, showSizeChanger: false }}
      bordered
      style={{ margin: "20px" }}
      className="custom-table-header"
      loading={loading}
      scroll={{ x: 1000, y: 430 }}
    />
  );
}

export default EmployeeTable;
