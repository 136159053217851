// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.icon-buttons {
    display: flex;
    align-items: center;
  }
  
  .live-indicator-container {
    display: flex;
    align-items: center;
    margin-right: 16px; 
  }
  
  .live-indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
    animation: pulsate 2s infinite;
    margin-right: 8px;
    
  }
  
  .live-text {
    font-size: 15px; 
    color: #001233; 
    font-weight: bold;
  }
  
  @keyframes pulsate {
    0% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 10px 2px rgba(0, 255, 0, 0.6);
    }
    50% {
      transform: scale(1.2);
      opacity: 0.7;
      box-shadow: 0 0 15px 3px rgba(0, 255, 0, 0.8);
    }
    100% {
      transform: scale(1);
      opacity: 1;
      box-shadow: 0 0 10px 2px rgba(0, 255, 0, 0.6);
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/New/Dashboard/MapFilter/MapVehicleEmployeeFilter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,uBAAuB;IACvB,8BAA8B;IAC9B,iBAAiB;;EAEnB;;EAEA;IACE,eAAe;IACf,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE;MACE,mBAAmB;MACnB,UAAU;MACV,6CAA6C;IAC/C;IACA;MACE,qBAAqB;MACrB,YAAY;MACZ,6CAA6C;IAC/C;IACA;MACE,mBAAmB;MACnB,UAAU;MACV,6CAA6C;IAC/C;EACF","sourcesContent":[".icon-buttons {\n    display: flex;\n    align-items: center;\n  }\n  \n  .live-indicator-container {\n    display: flex;\n    align-items: center;\n    margin-right: 16px; \n  }\n  \n  .live-indicator {\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n    background-color: green;\n    animation: pulsate 2s infinite;\n    margin-right: 8px;\n    \n  }\n  \n  .live-text {\n    font-size: 15px; \n    color: #001233; \n    font-weight: bold;\n  }\n  \n  @keyframes pulsate {\n    0% {\n      transform: scale(1);\n      opacity: 1;\n      box-shadow: 0 0 10px 2px rgba(0, 255, 0, 0.6);\n    }\n    50% {\n      transform: scale(1.2);\n      opacity: 0.7;\n      box-shadow: 0 0 15px 3px rgba(0, 255, 0, 0.8);\n    }\n    100% {\n      transform: scale(1);\n      opacity: 1;\n      box-shadow: 0 0 10px 2px rgba(0, 255, 0, 0.6);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
