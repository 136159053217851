import React, { useEffect, useState, useRef } from "react";
import {
  GoogleMap,
  LoadScript,
  Polygon,
  Marker,
  InfoWindow,
  MarkerClusterer,
} from "@react-google-maps/api";
import { Switch } from "antd";
import axios from "axios";
import OnlineVehicleIcon from "../../../assets/gpsicon/OnlineVehicle.svg";
import OfflineVehicleIcon from "../../../assets/gpsicon/OfflineVehicle.svg";
import IdleVehicleIcon from "../../../assets/gpsicon/IdleVehicle.svg";
import { useGpsSocket } from "../../../../Socket/GpsSpocketContext";

const containerStyle = {
  width: "100%",
  height: "70vh",
  borderRadius: "12px",
};

const VehicleLiveLocation = ({
  selectedProject,
  selectedVehicleType,
  searchText,
  selectedStatus,
  selectedVehicle,
  setSelectedVehicle,
}) => {
  const [geofences, setGeofences] = useState([]);
  const [gpsLocations, setGpsLocations] = useState([]);
  const [gpsApiData, setGpsApiData] = useState([]);
  const mapRef = useRef(null);
  const [mapCenter] = useState({ lat: 24.3531, lng: 85.2965 });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showGeofences, setShowGeofences] = useState(false);
  const [isApiLoaded, setIsApiLoaded] = useState(false);
  const accountid = localStorage.getItem("accountid");
  const apiKey = process.env.React_App_GOOGLE_MAPS_API_KEY;
  const gpsSocket = useGpsSocket();

  useEffect(() => {
    const fetchGeofenceData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/geofence/getgeofences?accountid=${accountid}`
        );
        if (response.status === 200 && Array.isArray(response.data.geofences)) {
          setGeofences(response.data.geofences);
        } else {
          setGeofences([]);
        }
      } catch (error) {
        setGeofences([]);
      }
    };

    fetchGeofenceData();
  }, [accountid]);

  useEffect(() => {
    const fetchVehicleData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/home/map/getvehicles?accountid=${accountid}`
        );
        if (response.status === 200 && response.data.data.results) {
          const apiData = response.data.data.results.map((vehicleLocation) => ({
            imei: vehicleLocation.imei,
            lat: vehicleLocation.latitude,
            lng: vehicleLocation.longitude,
            tsp: vehicleLocation.timestamp,
            cg: vehicleLocation.cog,
            speed: vehicleLocation.speed,
            vehiclenumber: vehicleLocation.vehiclenumber,
            vehicletype: vehicleLocation.vehicletype,
            drivername: vehicleLocation.drivername,
            phonenumber: vehicleLocation.phonenumber,
            igstatus: vehicleLocation.ignitionstatus,
            projectid: vehicleLocation.project_ids[0],
          }));

          setGpsLocations(apiData);
          setGpsApiData(apiData);
        } else {
          setGpsLocations([]);
          setGpsApiData([]);
        }
      } catch (error) {
        setGpsLocations([]);
        setGpsApiData([]);
      }
    };

    fetchVehicleData();

    if (gpsSocket) {
      gpsSocket.on("gps_update", (data) => {
        console.log("Received gpsData:", data);
        const socketData = data.map((vehicleLocation) => ({
          imei: vehicleLocation.imei,
          lat: parseFloat(vehicleLocation.latitude),
          lng: parseFloat(vehicleLocation.longitude),
          time: vehicleLocation.time,
          date: vehicleLocation.date,
          cg: vehicleLocation.cog,
          speed: vehicleLocation.speed,
          igstatus: vehicleLocation.ignitionStatus.split(":")[1],
          altitude: vehicleLocation.altitude,
          vehicleid: vehicleLocation.vehicleid,
        }));

        setGpsLocations((prevLocations) => {
          // Create a new array with updated data from the socket
          const updatedLocations = prevLocations.map((prevLocation) => {
            const socketUpdate = socketData.find(
              (loc) => loc.imei === prevLocation.imei
            );
            return socketUpdate
              ? { ...prevLocation, ...socketUpdate }
              : prevLocation;
          });

          // Add any new vehicles from socket that are not already in the list
          socketData.forEach((socketLocation) => {
            if (
              !updatedLocations.some((loc) => loc.imei === socketLocation.imei)
            ) {
              updatedLocations.push(socketLocation);
            }
          });

          return updatedLocations;
        });
      });
    }

    return () => {
      if (gpsSocket) {
        gpsSocket.off("gps_update");
      }
    };
  }, [gpsSocket, accountid]);

  const onLoad = () => {
    setIsApiLoaded(true);
  };

  const getMarkerIcon = (igstatus, speed) => {
    if (!isApiLoaded) return null;

    if (Number(igstatus) === 1) {
      if (Number(speed) > 0) {
        return {
          url: OnlineVehicleIcon,
          scaledSize: new window.google.maps.Size(50, 50),
        };
      } else {
        return {
          url: IdleVehicleIcon,
          scaledSize: new window.google.maps.Size(50, 50),
        };
      }
    } else {
      return {
        url: OfflineVehicleIcon,
        scaledSize: new window.google.maps.Size(50, 50),
      };
    }
  };
  if (selectedProject && showGeofences && isApiLoaded) {
    const selectedSiteGeofence = geofences.find(
      (geofence) => geofence.projectid === Number(selectedProject)
    );
    if (selectedSiteGeofence) {
      const coordinates = selectedSiteGeofence.coordinates.map((coord) => ({
        lat: coord.latitude,
        lng: coord.longitude,
      }));
      const bounds = new window.google.maps.LatLngBounds();
      coordinates.forEach((coord) =>
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng))
      );

      // Zoom to the geofence bounds
      mapRef.current.fitBounds(bounds);
    }
  }

  const handleMarkerClick = (location) => {
    if (selectedMarker?.imei === location.imei) {
      setSelectedMarker(null);
    } else {
      setSelectedMarker(location);
    }
  };

  const handleMapClick = () => {
    setSelectedMarker(null);
  };

  const groupedLocations = gpsLocations.reduce((acc, location) => {
    if (!acc[location.imei]) {
      acc[location.imei] = [];
    }
    acc[location.imei].push(location);
    return acc;
  }, {});

  const gpsfilteredLocations = Object.values(groupedLocations).flatMap(
    (locations) =>
      locations.filter((location) => {
        const vehicleNumber = location.vehiclenumber
          ? location.vehiclenumber.toLowerCase()
          : "";
        const driverName = location.drivername
          ? location.drivername.toLowerCase()
          : "";
        const searchLower = searchText ? searchText.toLowerCase() : "";

        const matchesSearchText =
          vehicleNumber.includes(searchLower) ||
          driverName.includes(searchLower);

        const matchesStatus =
          !selectedStatus ||
          (selectedStatus === "Moving" &&
            Number(location.igstatus) === 1 &&
            Number(location.speed) > 0) ||
          (selectedStatus === "Idle" &&
            Number(location.igstatus) === 1 &&
            Number(location.speed) === 0) ||
          (selectedStatus === "Stopped" && Number(location.igstatus) === 0);

        // Check for project and vehicle type filters
        const matchesProject =
          !selectedProject || location.projectid === Number(selectedProject);
        const matchesVehicleType =
          !selectedVehicleType || location.vehicletype === selectedVehicleType;

        return (
          matchesSearchText &&
          matchesStatus &&
          matchesProject &&
          matchesVehicleType
        );
      })
  );

  useEffect(() => {
    if (selectedVehicle && gpsLocations.length > 0) {
      const matchingLocation = gpsLocations.find(
        (location) => location.imei === selectedVehicle.regno
      );

      if (matchingLocation) {
        const position = {
          lat: matchingLocation.lat,
          lng: matchingLocation.lng,
        };

        mapRef.current.panTo(position);
        mapRef.current.setZoom(22);
        setSelectedMarker(matchingLocation);
        setSelectedVehicle(null);
      }
    }
  }, [selectedVehicle, gpsLocations, setSelectedVehicle]);

  return (
    <div className="bodycolor">
      <LoadScript googleMapsApiKey={apiKey} onLoad={onLoad}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={mapCenter}
          zoom={7}
          options={{
            zoomControl: true,
            scrollwheel: true,
            gestureHandling: "cooperative",
          }}
          onLoad={(map) => (mapRef.current = map)}
          onClick={handleMapClick}
        >
          {showGeofences && isApiLoaded && (
            <React.Fragment>
              {selectedProject
                ? geofences
                    .filter(
                      (geofence) =>
                        geofence.projectid === Number(selectedProject)
                    )
                    .map((geofence, index) => {
                      const coordinates = geofence.coordinates.map((coord) => ({
                        lat: coord.latitude,
                        lng: coord.longitude,
                      }));

                      return (
                        <Polygon
                          key={index}
                          paths={coordinates}
                          options={{
                            fillColor: "blue",
                            fillOpacity: 0.3,
                            strokeColor: "#E76F51",
                            strokeOpacity: 0.8,
                            strokeWeight: 2,
                          }}
                        />
                      );
                    })
                : geofences.map((geofence, index) => {
                    const coordinates = geofence.coordinates.map((coord) => ({
                      lat: coord.latitude,
                      lng: coord.longitude,
                    }));

                    return (
                      <Polygon
                        key={index}
                        paths={coordinates}
                        options={{
                          fillColor: "blue",
                          fillOpacity: 0.3,
                          strokeColor: "#E76F51",
                          strokeOpacity: 0.8,
                          strokeWeight: 2,
                        }}
                      />
                    );
                  })}
            </React.Fragment>
          )}
          <div
            style={{
              position: "absolute",
              width: 206,
              height: 52,
              bottom: 20,
              left: 20,
              border: "1px solid #ddd",
              borderRadius: 12,
              padding: 8,
              display: "flex",
              alignItems: "center",
              backgroundColor: "white",
              fontWeight: "bold",
            }}
          >
            <Switch
              checked={showGeofences}
              onChange={(checked) => setShowGeofences(checked)}
              style={{
                backgroundColor: showGeofences ? "#E76F51" : "",
              }}
            />
            <span style={{ marginLeft: 8 }}>Show Geofences</span>
          </div>

          <MarkerClusterer>
            {(clusterer) =>
              gpsfilteredLocations.map((location, index) => {
                // Find the matching API data
                const matchedApiData = gpsApiData.find(
                  (data) => data.imei === location.imei
                );

                return (
                  <Marker
                    key={index}
                    position={{ lat: location.lat, lng: location.lng }}
                    icon={getMarkerIcon(location.igstatus, location.speed)}
                    onClick={() => handleMarkerClick(location)}
                    clusterer={clusterer}
                  >
                    {selectedMarker?.imei === location.imei && (
                      <InfoWindow onCloseClick={() => setSelectedMarker(null)}>
                        <div>
                          <p>
                            <strong
                              style={{ color: "#001233", fontWeight: "bold" }}
                            >
                              Driver:
                            </strong>{" "}
                            <span
                              style={{ color: "#E76F51", fontWeight: "bold" }}
                            >
                              {matchedApiData?.drivername || "N/A"}
                            </span>
                          </p>
                          <p>
                            <strong
                              style={{ color: "#001233", fontWeight: "bold" }}
                            >
                              Phone:
                            </strong>{" "}
                            <span
                              style={{ color: "#E76F51", fontWeight: "bold" }}
                            >
                              {matchedApiData?.phonenumber || "N/A"}
                            </span>
                          </p>
                          <p>
                            <strong
                              style={{ color: "#001233", fontWeight: "bold" }}
                            >
                              V No:
                            </strong>{" "}
                            <span
                              style={{ color: "#E76F51", fontWeight: "bold" }}
                            >
                              {matchedApiData.vehiclenumber}
                            </span>
                          </p>
                          <p>
                            <strong
                              style={{ color: "#001233", fontWeight: "bold" }}
                            >
                              V Type:
                            </strong>{" "}
                            <span
                              style={{ color: "#E76F51", fontWeight: "bold" }}
                            >
                              {matchedApiData.vehicletype}
                            </span>
                          </p>
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                );
              })
            }
          </MarkerClusterer>
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default VehicleLiveLocation;
