// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Exit{
    background-color: transparent;
    color: #E76F51;
    font-weight: bold;
    border-color: #E76F51 ;
}
.Exit:hover {
    border: none !important;
    background: #e76f51cc !important;
    color: #001233 !important;
}
.title-color{

font-family: 'Work Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;
color: #001233 !important;
}
.label-name{
    color: #001233;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/EmployeeData/addEmployee.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,cAAc;IACd,iBAAiB;IACjB,sBAAsB;AAC1B;AACA;IACI,uBAAuB;IACvB,gCAAgC;IAChC,yBAAyB;AAC7B;AACA;;AAEA,wBAAwB;AACxB,kBAAkB;AAClB,gBAAgB;AAChB,eAAe;AACf,iBAAiB;AACjB,yBAAyB;AACzB;AACA;IACI,cAAc;IACd,iBAAiB;AACrB","sourcesContent":[".Exit{\n    background-color: transparent;\n    color: #E76F51;\n    font-weight: bold;\n    border-color: #E76F51 ;\n}\n.Exit:hover {\n    border: none !important;\n    background: #e76f51cc !important;\n    color: #001233 !important;\n}\n.title-color{\n\nfont-family: 'Work Sans';\nfont-style: normal;\nfont-weight: 600;\nfont-size: 20px;\nline-height: 28px;\ncolor: #001233 !important;\n}\n.label-name{\n    color: #001233;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
