import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import axios from "axios";
import { useGpsSocket } from "../../../../Socket/GpsSpocketContext";
import ReverseGeocoding from "../../Utils/ReverseGeocoding";

const columns = [
  {
    title: "Sr No.",
    dataIndex: "serialNumber",
    key: "serialNumber",
    width: 80,
    render: (text, record, index) => (
      <span className="customTextColor">{index + 1}</span>
    ),
  },
  {
    title: "Reg No.",
    dataIndex: "regno",
    key: "regno",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Vehicle No.",
    dataIndex: "vehiclenumber",
    key: "vehiclenumber",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Driver Name",
    dataIndex: "drivername",
    key: "drivername",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Vehicle Type",
    dataIndex: "vehicletype",
    key: "vehicletype",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Vehicle Status",
    dataIndex: "vehiclestatus",
    key: "vehiclestatus",
    render: (vehiclestatus, record) => (
      <Tag
        color={
          vehiclestatus === false
            ? "red"
            : vehiclestatus === true && record.speed > 0
            ? "green"
            : vehiclestatus === true && record.speed === 0
            ? "gold"
            : "default"
        }
      >
        {vehiclestatus === false
          ? "Stopped"
          : vehiclestatus === true && record.speed > 0
          ? "Moving"
          : vehiclestatus === true && record.speed === 0
          ? "Idle"
          : "Unknown"}
      </Tag>
    ),
  },
  {
    title: "Project Name",
    dataIndex: "projectname",
    key: "projectname",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Current Location",
    dataIndex: "lastlocation",
    key: "lastlocation",
    render: (location) => (
      <span>
        <EnvironmentOutlined style={{ color: "#E76F51" }} />{" "}
        {location.lat && location.lng ? (
          <span className="currentlocationColor">
            <ReverseGeocoding lat={location.lat} lng={location.lng} />
          </span>
        ) : (
          <span className="currentlocationColor">{location}</span>
        )}
      </span>
    ),
  },
  {
    title: "Speed (kms/hr)",
    dataIndex: "speed",
    key: "speed",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Distance(kms)",
    dataIndex: "distance",
    key: "distance",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
  {
    title: "Last Activity",
    dataIndex: "timeAgo",
    key: "timeAgo",
    render: (text) => <span className="customTextColor">{text}</span>,
  },
];

function VehicleTable({
  selectedProject,
  selectedVehicleType,
  searchText,
  selectedStatus,
}) {
  const [vehicleData, setVehicleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const accountid = localStorage.getItem("accountid");
  const gpsSocket = useGpsSocket();

  const updateVehicleWithGpsData = (gpsDataArray) => {
    setVehicleData((prevVehicleData) =>
      prevVehicleData.map((vehicle) => {
        gpsDataArray.forEach((gpsData) => {
          if (vehicle.regno === gpsData.imei) {
            vehicle = {
              ...vehicle,
              speed: gpsData.speed,
              lastlocation: {
                lat: gpsData.latitude,
                lng: gpsData.longitude,
              },
              vehiclestatus: gpsData.ignitionStatus === "02:1",
            };
          }
        });
        return vehicle;
      })
    );
  };

  useEffect(() => {
    const fetchVehicleData = async () => {
      setLoading(true);
      try {
        const url = `${process.env.React_App_API_URL}/home/sidebar/vehicles?accountid=${accountid}`;

        const response = await axios.get(url);
        const fetchedData = response.data;

        if (fetchedData && fetchedData.data) {
          const vehicleDataWithKeys = fetchedData.data.map((item, index) => ({
            ...item,
            key: index,
            projectname:
              item.projects && item.projects.length > 0
                ? item.projects[0].project_name
                : "No Project",
          }));

          let filteredData = vehicleDataWithKeys;

          // Define filterVehicles inside useEffect to avoid eslint warning
          const filterVehicles = (vehicles) => {
            return vehicles.filter((vehicle) => {
              const vehicleNumber = vehicle.vehiclenumber
                ? vehicle.vehiclenumber.toLowerCase()
                : "";
              const driverName = vehicle.drivername
                ? vehicle.drivername.toLowerCase()
                : "";
              const searchLower = searchText ? searchText.toLowerCase() : "";

              const matchesSearchText =
                vehicleNumber.includes(searchLower) ||
                driverName.includes(searchLower);

              const matchesStatus =
                !selectedStatus ||
                (selectedStatus === "Moving" &&
                  vehicle.vehiclestatus &&
                  vehicle.speed > 0) ||
                (selectedStatus === "Idle" &&
                  vehicle.vehiclestatus &&
                  vehicle.speed === 0) ||
                (selectedStatus === "Stopped" && !vehicle.vehiclestatus);

              const matchesProject =
                !selectedProject ||
                vehicle.projects.some(
                  (project) => project.project_id === selectedProject
                );

              const matchesVehicleType =
                !selectedVehicleType ||
                vehicle.vehicletype === selectedVehicleType;

              return (
                matchesSearchText &&
                matchesStatus &&
                matchesProject &&
                matchesVehicleType
              );
            });
          };

          filteredData = filterVehicles(vehicleDataWithKeys);
          setVehicleData(filteredData);
        } else {
          console.error(
            "Error: Fetched data is not in the expected format:",
            fetchedData
          );
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleData();
  }, [
    selectedProject,
    selectedVehicleType,
    accountid,
    selectedStatus,
    searchText,
  ]);

  useEffect(() => {
    if (gpsSocket) {
      gpsSocket.on("gps_update", (data) => {
        updateVehicleWithGpsData(data);
      });
    }

    return () => {
      if (gpsSocket) {
        gpsSocket.off("gps_update");
      }
    };
  }, [gpsSocket]);

  return (
    <Table
      columns={columns}
      dataSource={vehicleData}
      pagination={{ pageSize: 100, showSizeChanger: false }}
      bordered
      style={{ margin: "20px" }}
      className="custom-table-header"
      loading={loading}
      scroll={{ x: 1000, y: 430 }}
    />
  );
}

export default VehicleTable;
