// src/Axios/AxiosInstance.js
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

const axiosInstance = axios.create({
    baseURL: process.env.React_App_API_URL,
});

// Function to check if the token is expired
const isTokenExpired = (token) => {
    if (!token) return true; 
    const decodedToken = jwtDecode(token);
    return Date.now() >= decodedToken.exp * 1000;
};

const logout = () => { 
    localStorage.clear();
    window.location.reload();
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token");
        if (isTokenExpired(token)) {
            console.log('token valid ',isTokenExpired(token));
            logout();
            return Promise.reject(new Error("Token expired")); 
        }
        if (token) {
            console.log('token valid ',false);
            config.headers.Authorization = `Bearer ${token}`; 
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
