import React from "react";
import { Input, Select, Space, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

function ProjectDataFilter({
  onStatusSelect,
  selectedStatus,
  onSearch,
  searchQuery,
  onClearFilters,
}) {
  const handleStatusChange = (value) => {
    onStatusSelect(value);
  };

  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };

  const handleClearFilters = () => {
    onClearFilters();
  };

  return (
    <div className="filterContainer">
      <Space className="leftSpace">
        <Input
          placeholder="Search by project"
          prefix={<SearchOutlined />}
          style={{ width: 250, borderRadius: 15, color: "#001233" }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <Select
          placeholder="Select Status"
          value={selectedStatus}
          className="customSelect"
          style={{ width: 150 }}
          onChange={handleStatusChange}
        >
          <Option value="active">
            <span style={{ marginRight: 8 }}>
              <input
                type="checkbox"
                checked={selectedStatus === "active"}
                onChange={() => onStatusSelect("active")}
                style={{
                  accentColor: "#E76F51",
                }}
              />
            </span>
            Active
          </Option>
          <Option value="inactive">
            <span style={{ marginRight: 8 }}>
              <input
                type="checkbox"
                checked={selectedStatus === "inactive"}
                onChange={() => onStatusSelect("inactive")}
                style={{
                  accentColor: "#E76F51",
                }}
              />
            </span>
            Inactive
          </Option>
        </Select>
        <Button className="customclearButton" onClick={handleClearFilters}>
          Clear all filters
        </Button>
      </Space>
    </div>
  );
}

export default ProjectDataFilter;
