// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ant-pagination-item {
    color: #001233 !important;
    background-color: #E76F51; 
    border-color: #E76F51; 
}


.ant-pagination-item a {
    color: #001233 !important;
    color: white;
}
.ant-pagination-item-active a{
    color: #001233 !important;
}
.ant-pagination-item-active {
    color: #001233 !important;
    border-color: #E76F51 !important;
}

/* Change the hover effects */
.ant-pagination-item:hover {
    border-color: #E76F51; 
}

.ant-pagination-item:hover a {
    color: #ffffff; 
}


.ant-pagination-item-link {
    background-color: #E76F51; 
    border-color: #E76F51; 
    color: white; 
}

.ant-pagination-item-link:hover {
    background-color: #E76F51; 
    border-color: #E76F51; 
    color: white; 
}
`, "",{"version":3,"sources":["webpack://./src/components/New/Report/vehichlereportTable.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,qBAAqB;AACzB;;;AAGA;IACI,yBAAyB;IACzB,YAAY;AAChB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,gCAAgC;AACpC;;AAEA,6BAA6B;AAC7B;IACI,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;;AAGA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;IACrB,YAAY;AAChB","sourcesContent":["\n.ant-pagination-item {\n    color: #001233 !important;\n    background-color: #E76F51; \n    border-color: #E76F51; \n}\n\n\n.ant-pagination-item a {\n    color: #001233 !important;\n    color: white;\n}\n.ant-pagination-item-active a{\n    color: #001233 !important;\n}\n.ant-pagination-item-active {\n    color: #001233 !important;\n    border-color: #E76F51 !important;\n}\n\n/* Change the hover effects */\n.ant-pagination-item:hover {\n    border-color: #E76F51; \n}\n\n.ant-pagination-item:hover a {\n    color: #ffffff; \n}\n\n\n.ant-pagination-item-link {\n    background-color: #E76F51; \n    border-color: #E76F51; \n    color: white; \n}\n\n.ant-pagination-item-link:hover {\n    background-color: #E76F51; \n    border-color: #E76F51; \n    color: white; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
