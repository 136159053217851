import React, { useState, Suspense, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Breadcrumb, Card, Typography, Radio, Collapse, Image } from "antd";
import axios from "axios";
import HeaderNav from "../../Navbar/HeaderNav";
import ViewSurveyMap from "./ViewSurveyMap";

const { Panel } = Collapse;

function ViewSurvey() {
  const [openPanel, setOpenPanel] = useState(null);
  const [loadedImages, setLoadedImages] = useState([]);
  const [surveyDetails, setSurveyDetails] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const surveyid = location.state?.survey_id;
  const accountid = localStorage.getItem("accountid");

  const navigateToProject = () => {
    navigate(-1);
  };

  const handlePanelClick = (index, imageName) => {
    if (openPanel === index) {
      setOpenPanel(null);
    } else {
      setOpenPanel(index);
      loadImage(index, imageName);
    }
  };

  const loadImage = (index, imageName) => {
    if (!loadedImages[index] && imageName) {
      const imageUrl = `${process.env.React_App_API_URL}/project-survey/streams-image/${imageName}`;
      setLoadedImages((prev) => ({
        ...prev,
        [index]: imageUrl,
      }));
    }
  };

  useEffect(() => {
    const fetchSurveyDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/project-survey/getdetails/${accountid}?surveyid=${surveyid}`
        );
        setSurveyDetails(response.data.data);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error);
      }
    };

    fetchSurveyDetails();
  }, [accountid, surveyid]);

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb separator=">">
            <Breadcrumb.Item onClick={navigateToProject}>
              <span className="breadcrumb-reports">Project</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="breadcrumb-fleet-management">
                {surveyDetails.project_name || "No Project Name"}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <div className="preview-container">
        <Card
          className="preview-card custom-card"
          title={
            <div className="card-header">
              <Typography className="card-title">
                {surveyDetails.project_name || "No Project Name"}
              </Typography>
              <Typography className="sent-by">
                Sent By:
                <br />
                <span className="user-name-phone">
                  {surveyDetails.user_name || "No User Name"} (
                  {surveyDetails.user_phone || "No User Phone"})
                </span>
              </Typography>
            </div>
          }
        >
          <Typography className="building-type">Building Type</Typography>
          <Radio.Group
            className="building-type-radio-group"
            value={surveyDetails?.type || null}
          >
            <Radio value="Non-Building">Non Building (Open Area)</Radio>
            <Radio value="Building">Building (Closed Area)</Radio>
          </Radio.Group>

          <Typography className="preview-card-title">
            Geofence Co-ordinates
          </Typography>
          <div className="card-scrollable-content">
            {surveyDetails.survey_details?.map((item, index) => (
              <Collapse
                key={index}
                activeKey={openPanel === index ? [`panel-${index}`] : []}
                onChange={() => handlePanelClick(index, item.image_name)}
                className="custom-collapse"
              >
                <Panel
                  header={
                    <div className="preview-panel-header">
                      <span className="panel-serial-number">{index + 1}</span>
                      <span className="panel-latitude">
                        Latitude: {item.latitude}
                      </span>
                      <span className="panel-longitude">
                        Longitude: {item.longitude}
                      </span>
                    </div>
                  }
                  key={`panel-${index}`}
                >
                  <>
                    <p className="panel-tag">Tag: {item.tag}</p>
                    <p className="panel-tag">Distance: {item.distance}</p>
                    {loadedImages[index] ? (
                      <div className="survey-image-container">
                        <Suspense fallback={<div>Loading Image...</div>}>
                          <Image
                            src={loadedImages[index]}
                            alt={`Survey ${index + 1}`}
                            preview={{ src: loadedImages[index] }}
                            className="preview-survey-image"
                          />
                        </Suspense>
                      </div>
                    ) : (
                      <Typography className="preview-normal-text">
                        No image provided
                      </Typography>
                    )}
                  </>
                </Panel>
              </Collapse>
            ))}
          </div>
        </Card>
        <div className="preview-map-container">
          {surveyDetails.survey_details?.length > 0 && (
            <ViewSurveyMap surveyDetails={surveyDetails} />
          )}
        </div>
      </div>
    </>
  );
}

export default ViewSurvey;
