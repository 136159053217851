import React, { useState } from "react";
import { Button, Breadcrumb, Spin } from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
const accountid = localStorage.getItem("accountid");

function ProjectNavbar() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddProjectClick = () => {
    navigate("/project/addProject");
  };
  const handleExportToPdf = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.React_App_API_URL}/project/getprojects?accountid=${accountid}`
      );
      const projects = response.data.projects;

      const doc = new jsPDF();
      const tableColumn = [
        "Sr. No",
        "Project Name",
        "Tender Value",
        "Start Date",
        "End Date",
        "Stipulated Date",
        "Location",
        "Project Status",
      ];
      const tableRows = [];

      projects.forEach((project, index) => {
        const projectStatus = project.isdeleted ? "Completed" : "Active";
        const projectData = [
          index + 1, // Sr. No
          project.projectname,
          project.tendervalue,
          new Date(project.startdate).toLocaleDateString(),
          new Date(project.enddate).toLocaleDateString(),
          new Date(project.stipulateddate).toLocaleDateString(),
          project.location,
          projectStatus,
        ];
        tableRows.push(projectData);
      });

      doc.setFillColor(240, 240, 240);
      doc.rect(10, 10, 190, 10, "F");

      // Set the text color to black
      doc.setTextColor(0, 0, 0);
      doc.text("Project List", 80, 17);
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 30,
        theme: "grid",
        tableWidth: "auto",
      });

      // Add logo to each page at right bottom corner
      const logo = `${process.env.PUBLIC_URL}/logopdf.png`;
      const logoWidth = 30;
      const logoHeight = 10;
      const marginRight = 15;
      const marginBottom = 5;
      const marginTop = 1;
      const x = doc.internal.pageSize.width - logoWidth - marginRight;
      const y =
        doc.internal.pageSize.height - logoHeight - marginBottom - marginTop;
      doc.addImage(logo, "PNG", x, y, logoWidth, logoHeight);

      doc.save("Project_List.pdf");
    } catch (error) {
      // console.error('Error fetching projects: ', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="FilterEmployeeVehicle">
      <div className="breadcrumb-container">
        <Breadcrumb>
          <Breadcrumb.Item>
            <span className="employee">Projects</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="right-container">
        <Spin spinning={loading}>
          <Button
            type="primary"
            className="customExportButton"
            icon={<DownloadOutlined />}
            onClick={handleExportToPdf}
            disabled={loading}
          >
            Export to PDF
          </Button>
        </Spin>
        <Button
          className="customAddButton"
          icon={<PlusOutlined />}
          onClick={handleAddProjectClick}
        >
          <span className="addEmployee">New Project</span>
        </Button>
      </div>
    </div>
  );
}

export default ProjectNavbar;
