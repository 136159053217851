import React, { useState, useEffect } from "react";
import axiosInstance from "../Axios/AxiosInstance";
import { Table, Spin } from "antd";
import {
  ClockCircleOutlined,
  EditOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./ProjectList.css";

function ProjectList({ selectedStatus, searchQuery }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Component mounted, fetching projects...");
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/project/getprojects");

        if (response.status === 200) {
          let filteredProjects = response.data.projects;

          if (selectedStatus) {
            if (selectedStatus === "active") {
              filteredProjects = filteredProjects.filter(
                (project) => project.isdeleted === false
              );
            } else if (selectedStatus === "inactive") {
              filteredProjects = filteredProjects.filter(
                (project) => project.isdeleted === true
              );
            }
          }

          if (searchQuery) {
            filteredProjects = filteredProjects.filter((project) =>
              project.projectname
                .toLowerCase()
                .includes(searchQuery.toLowerCase())
            );
          }

          setProjects(filteredProjects);
          setLoading(false);
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    fetchProjects();
  }, [selectedStatus, searchQuery]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const formatIndianCurrency = (num) => {
    return num.toString().replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
  };

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 80,
      render: (text, record, index) => (
        <span className="customTextColor">{index + 1}</span>
      ),
    },
    {
      title: "Project Name",
      dataIndex: "projectname",
      key: "projectname",
      width: 200,
      render: (text, record) => (
        <span
          className="project-name-projectlist"
          onClick={() =>
            navigate("/project/viewProject", {
              state: {
                site: record,
                projectName: record.projectname,
                projectId: record.id,
              },
            })
          }
        >
          {text.length > 20 ? `${text.substring(0, 20)}....` : text}
        </span>
      ),
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      width: 250,
      render: (text, record) => (
        <span className="customTextColor location-projectlist">
          {text.length > 30 ? `${text.substring(0, 30)}.....` : text}
        </span>
      ),
    },
    {
      title: "Tender Value",
      dataIndex: "tendervalue",
      key: "tendervalue",
      render: (text) => (
        <span className="customTextColor">₹ {formatIndianCurrency(text)}</span>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startdate",
      key: "startdate",
      render: (text) => (
        <span className="customTextColor">{formatDate(text)}</span>
      ),
    },
    {
      title: "End Date",
      dataIndex: "enddate",
      key: "enddate",
      render: (text) => (
        <span className="customTextColor">{formatDate(text)}</span>
      ),
    },
    {
      title: "Geofence",
      dataIndex: "isgeofence",
      key: "isgeofence",
      render: (isgeofence, record) =>
        isgeofence ? (
          <span
            className="customEditColor"
            style={{ marginRight: 8, cursor: "pointer" }}
            onClick={() =>
              navigate("/project/viewSurvey", {
                state: { survey_id: record.survey_id },
              })
            }
          >
            <EyeOutlined style={{ marginRight: 8 }} />
            View
          </span>
        ) : (
          <span className="customEditColor" style={{ marginRight: 8 }}>
            <ClockCircleOutlined style={{ marginRight: 8 }} />
            Pending
          </span>
        ),
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <span
          className="customEditColor"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate("/project/editProject", { state: { site: record } })
          }
        >
          <EditOutlined style={{ marginRight: 8 }} />
          Edit
        </span>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={projects.map((item) => ({ ...item, key: item.id }))}
        pagination={{ pageSize: 100, showSizeChanger: false }}
        bordered
        style={{ margin: "20px" }}
        className="custom-table-header"
        scroll={{ x: 1000, y: 430 }}
      />
    </Spin>
  );
}

export default ProjectList;
