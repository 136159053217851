import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "./employeeList.css";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function EmployeeList({ selectedProject, selectedRole, searchQuery }) {
  const navigate = useNavigate();
  const [employeeData, setEmployeeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let url = `${process.env.React_App_API_URL}/employee/users?accountid=${accountid}`;
        if (selectedProject) {
          url += `&projectid=${selectedProject}`;
        }
        if (selectedRole) {
          url += `&roleid=${selectedRole}`;
        }
        const response = await axios.get(url);
        let fetchedData = response.data;

        if (Array.isArray(fetchedData)) {
          if (searchQuery) {
            fetchedData = fetchedData.filter(
              (item) =>
                item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                item.phonenumber.includes(searchQuery)
            );
          }
          const employeeDataWithKeys = fetchedData.map((item, index) => ({
            ...item,
            key: index,
          }));
          setEmployeeData(employeeDataWithKeys);
        } else {
          console.error(
            "Error: Fetched data is not in the expected format:",
            fetchedData
          );
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedProject, selectedRole, searchQuery, accountid]);

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 80,
      render: (text, record, index) => (
        <span className="customTextColor">{index + 1}</span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Role",
      dataIndex: "role_name",
      key: "role_name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Phone Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Project Name",
      dataIndex: "projectname",
      key: "projectname",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Aadhar Card",
      dataIndex: "aadhaarcard",
      key: "aadhaarcard",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Pan Card",
      dataIndex: "pancard",
      key: "pancard",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Department",
      dataIndex: "department_name",
      key: "department_name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <span
          className="customEditColor"
          onClick={() =>
            navigate("/editemployee", {
              state: {
                id: record.id,
              },
            })
          }
          style={{ cursor: "pointer" }}
        >
          <EditOutlined style={{ marginRight: 8 }} />
          Edit
        </span>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={employeeData}
      pagination={{ pageSize: 100, showSizeChanger: false }}
      bordered
      style={{ margin: "20px" }}
      className="custom-table-header"
      loading={loading}
      scroll={{ x: 1000, y: 430 }}
    />
  );
}

export default EmployeeList;
