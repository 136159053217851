import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../Axios/AxiosInstance";
import { Input, Select, Space, Button } from "antd";
import { SearchOutlined, EnvironmentOutlined } from "@ant-design/icons";

const { Option } = Select;

const EmployeeFilterMap = ({
  onProjectSelect,
  selectedProject,
  onRoleSelect,
  selectedRole,
  onStatusSelect,
  selectedStatus,
  onSearch,
  searchQuery,
  onClearFilters,
}) => {
  const [projects, setProjects] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/project/getprojects");
        if (response.status === 200) {
          const projectData = response.data.projects;
          setProjects(projectData);
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    const fetchDepartmentsAndRoles = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/role/getroles`
        );
        if (response.status === 200) {
          const departmentsData = response.data.data;
          setDepartments(departmentsData);
        } else {
          console.error("Failed to fetch departments and roles");
        }
      } catch (error) {
        console.error("Error fetching departments and roles:", error.message);
      }
    };

    fetchProjects();
    fetchDepartmentsAndRoles();
  }, [accountid]);

  const handleProjectChange = (projectId) => {
    if (selectedProject === projectId) {
      onProjectSelect(null);
    } else {
      onProjectSelect(projectId);
    }
  };

  const handleDepartmentChange = (departmentId) => {
    setSelectedDepartment(departmentId);
    const selectedDept = departments.find((dept) => dept.id === departmentId);
    if (selectedDept) {
      setRoles(selectedDept.roles);
    } else {
      setRoles([]);
    }
  };

  const handleRoleChange = (roleId) => {
    onRoleSelect(roleId);
  };

  const handleStatusChange = (value) => {
    onStatusSelect(value);
  };

  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };

  const handleClearFilters = () => {
    onClearFilters();
    setSelectedDepartment(null);
    setRoles([]);
  };

  return (
    <div className="filterContainer">
      <Space className="leftSpace">
        <div className="customSelect">
          <EnvironmentOutlined className="dropdown-icon" />
          <Select
            placeholder="Select Project"
            className="customSelect"
            style={{ width: 200 }}
            value={selectedProject}
            onChange={handleProjectChange}
          >
            {projects &&
              projects
                .filter((site) => !site.isdeleted)
                .map((site) => (
                  <Option key={site.id} value={site.id}>
                    <input
                      type="checkbox"
                      checked={selectedProject === site.id}
                      onChange={() => handleProjectChange(site.id)}
                      style={{
                        accentColor: "#E76F51",
                        marginRight: 8,
                      }}
                    />
                    {site.projectname}
                  </Option>
                ))}
          </Select>
        </div>

        <Input
          placeholder="Search by name, phone"
          prefix={<SearchOutlined />}
          style={{ width: 250, borderRadius: 15, color: "#001233" }}
          value={searchQuery}
          onChange={handleSearchChange}
        />

        <Select
          placeholder="Select Department"
          className="customSelect"
          style={{ width: 150 }}
          value={selectedDepartment}
          onChange={handleDepartmentChange}
        >
          {departments.map((department) => (
            <Option key={department.id} value={department.id}>
              {department.name}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Select Role"
          className="customSelect"
          style={{ width: 150 }}
          value={selectedRole}
          onChange={handleRoleChange}
          disabled={!selectedDepartment}
        >
          {roles.map((role) => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>

        <Select
          placeholder="Current Status"
          className="customSelect"
          style={{ width: 150 }}
          value={selectedStatus}
          onChange={handleStatusChange}
        >
          <Option value="active">Active</Option>
          <Option value="inactive">Inactive</Option>
        </Select>

        <Button className="customclearButton" onClick={handleClearFilters}>
          Clear all filters
        </Button>
      </Space>
    </div>
  );
};

export default EmployeeFilterMap;
