import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";

const ProfileImageContext = createContext();

export const ProfileImageProvider = ({ children }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const accountid = localStorage.getItem("accountid");
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  useEffect(() => {
    const fetchImageUrl = async () => {
      if (isLoggedIn) {
        try {
          const response = await axios.get(
            `${process.env.React_App_API_URL}/admin/getprofile-picture/${accountid}`,
            {
              responseType: "blob",
            }
          );
          const url = URL.createObjectURL(response.data);
          setImageUrl(url);
        } catch (error) {
          console.error("Error fetching image:", error);
        }
      }
    };
    fetchImageUrl();
  }, [accountid, isLoggedIn]);

  return (
    <ProfileImageContext.Provider value={{ imageUrl }}>
      {children}
    </ProfileImageContext.Provider>
  );
};

export const useProfileImage = () => useContext(ProfileImageContext);
