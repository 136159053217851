import React, { useState, useEffect, useRef } from "react";
import HeaderNav from "../../components/New/Navbar/HeaderNav";
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Spin,
  Button,
  message,
  Image,
} from "antd";
import AvatarSvg from "../assets/Profile/Avatar.svg";
import axios from "axios";
import { useProfileImage } from "../../Socket/ProfileImageContext";
import "./profile.css";

const { Title } = Typography;

function Profile() {
  const [personalData, setPersonalData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [companyData, setCompanyData] = useState({
    companyName: "",
    address: "",
  });

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState(null);
  const accountid = localStorage.getItem("accountid");
  const [adminId, setAdminId] = useState(null);
  const { imageUrl } = useProfileImage(); // Use the context hook

  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const apiUrl = `${process.env.React_App_API_URL}/admin/adminuser/${accountid}`;
        const response = await axios.get(apiUrl);
        setPersonalData({
          firstName: response.data.data.firstname,
          lastName: response.data.data.lastname,
          email: response.data.data.emailid,
          phoneNumber: response.data.data.phonenumber,
        });
        setAdminId(response.data.data.adminid);
        setCompanyData({
          companyName: response.data.data.companyname || "",
          address: response.data.data.address || "",
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accountid]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setSelectedFileName(e.target.files[0].name);
  };

  const handleUpload = async () => {
    if (!file) {
      message.error("Please select a file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("profileImage", file);

    try {
      setLoading(true);
      const uploadUrl = `${process.env.React_App_API_URL}/admin/upload-image/${accountid}`;
      const response = await axios.post(uploadUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success(response.data.message);
      setSelectedFileName(null);
      setFile(null);
      // Optionally, you can update the state or perform any necessary UI updates
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error("Failed to upload file.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      setLoading(true);
      const updateUrl = `${process.env.React_App_API_URL}/admin/update-profiledetails`;
      const response = await axios.put(updateUrl, {
        adminid: adminId,
        firstname: personalData.firstName,
        lastname: personalData.lastName,
        phonenumber: personalData.phoneNumber,
      });

      if (response.status === 200) {
        message.success(response.data.message);
      } else {
        throw new Error("Failed to update profile");
      }
    } catch (error) {
      message.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleTextClick = () => {
    if (file) {
      handleUpload();
    } else {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <HeaderNav />
      <Title className="newreportstyle">Profile</Title>
      <div style={{ backgroundColor: "#f0f2f5", padding: "50px" }}>
        {/* Personal Details Box */}
        <div className="personal-box">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4} className="title-color">
                Personal Details
              </Title>
            </Col>
            <Col span={24}>
              <Spin spinning={loading}>
                <Form layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <Row align="middle">
                        <Col flex="0 1 120px">
                          <Image
                            src={imageUrl || AvatarSvg}
                            alt="Profile"
                            className="image-size"
                            preview={{ src: imageUrl || AvatarSvg }}
                          />
                          <div style={{ textAlign: "center" }}>
                            {selectedFileName && (
                              <div className="image-selected">
                                {selectedFileName}
                              </div>
                            )}
                            <label
                              onClick={handleTextClick}
                              className="customTextColor"
                              style={{
                                fontWeight: "bold",
                                cursor: "pointer",
                                color: "#E76F51",
                              }}
                            >
                              {imageUrl
                                ? selectedFileName
                                  ? "Upload Photo"
                                  : "Change Photo"
                                : "Add Photo"}
                            </label>
                            <input
                              ref={fileInputRef}
                              type="file"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                              accept="image/*"
                            />
                          </div>
                        </Col>
                        <Col flex="1 0 0">
                          <Form.Item
                            label={
                              <span className="label-name">First Name</span>
                            }
                            required
                            rules={[
                              {
                                required: true,
                                message: "Please enter first name",
                              },
                            ]}
                          >
                            <Input
                              value={personalData.firstName}
                              onChange={(e) =>
                                setPersonalData({
                                  ...personalData,
                                  firstName: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                          <Form.Item
                            label={
                              <span className="label-name">Email Address</span>
                            }
                            required
                            rules={[
                              {
                                required: true,
                                message: "Please enter email address",
                              },
                            ]}
                            style={{ marginTop: "24px" }}
                          >
                            <Input
                              value={personalData.email}
                              disabled
                              onChange={(e) =>
                                setPersonalData({
                                  ...personalData,
                                  email: e.target.value,
                                })
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Last Name</span>}
                        required
                        rules={[
                          { required: true, message: "Please enter last name" },
                        ]}
                      >
                        <Input
                          value={personalData.lastName}
                          onChange={(e) =>
                            setPersonalData({
                              ...personalData,
                              lastName: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={<span className="label-name">Phone Number</span>}
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone number",
                          },
                        ]}
                        style={{ marginTop: "24px" }}
                      >
                        <Input
                          addonBefore="+91"
                          value={personalData.phoneNumber}
                          onChange={(e) =>
                            setPersonalData({
                              ...personalData,
                              phoneNumber: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Col>
          </Row>
        </div>
        {/* Company Details Box */}
        <div className="company-box">
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4} className="title-color">
                Company Details
              </Title>
            </Col>
            <Col span={24}>
              <Spin spinning={loading}>
                <Form layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Company Name</span>}
                      >
                        <Input
                          value={companyData.companyName}
                          disabled
                          onChange={(e) =>
                            setCompanyData({
                              ...companyData,
                              companyName: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Address</span>}
                      >
                        <Input
                          value={companyData.address}
                          disabled
                          onChange={(e) =>
                            setCompanyData({
                              ...companyData,
                              address: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Col>
          </Row>
        </div>
        <Col
          span={24}
          style={{ maxWidth: "880px", margin: "auto", textAlign: "right" }}
        >
          <Button className="newcustomAddButton" onClick={handleUpdateProfile}>
            <span className="newaddEmployee">Save Changes</span>
          </Button>
        </Col>
      </div>
    </>
  );
}

export default Profile;
