import React, { useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import EmployeeDataNavBar from "./employeeDataNavBar";
import EmployeeFilter from "./employeeDataFilter";
import EmployeeList from "./employeeList";

function EmployeeDashboard() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleClearFilters = () => {
    setSelectedProject(null);
    setSelectedRole(null);
    setSearchQuery("");
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <>
      <HeaderNav />
      <EmployeeDataNavBar />
      <EmployeeFilter
        onProjectSelect={handleProjectSelect}
        onRoleSelect={handleRoleSelect}
        onClearFilters={handleClearFilters}
        selectedProject={selectedProject}
        selectedRole={selectedRole}
        onSearch={handleSearch}
        searchQuery={searchQuery}
      />
      <EmployeeList
        selectedProject={selectedProject}
        selectedRole={selectedRole}
        searchQuery={searchQuery}
      />
    </>
  );
}

export default EmployeeDashboard;
