import React, { useState, Suspense, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderNav from "../../Navbar/HeaderNav";
import axios from "axios";
import {
  Button,
  Breadcrumb,
  Collapse,
  Card,
  Typography,
  Radio,
  Input,
  Form,
  Row,
  Col,
  Image,
  Divider,
  message,
  Modal,
  Slider,
  InputNumber,
} from "antd";
import { EditOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import PreviewMap from "../Preview/PreviewMap";
import "./Preview.css";

const { Panel } = Collapse;
const { confirm } = Modal;

function Preview() {
  const location = useLocation();
  const navigate = useNavigate();
  const surveyid = location.state?.survey_id;
  const accountid = localStorage.getItem("accountid");

  const [openPanel, setOpenPanel] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loadedImages, setLoadedImages] = useState({});
  const [surveyDetails, setSurveyDetails] = useState([]);
  const [buildingType, setBuildingType] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [form] = Form.useForm();
  const [buffer, setBuffer] = useState(0.001);

  const loadImage = (index, imageName) => {
    if (!loadedImages[index] && imageName) {
      const imageUrl = `${process.env.React_App_API_URL}/project-survey/streams-image/${imageName}`;
      setLoadedImages((prev) => ({
        ...prev,
        [index]: imageUrl,
      }));
    }
  };

  // Handle Image Upload
  const handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      // Update the preview and store the new image in state
      const imageUrl = URL.createObjectURL(file);
      setUploadedImage(file);
      setLoadedImages((prev) => ({
        ...prev,
        [index]: imageUrl,
      }));
    }
  };

  const onBufferChange = (newValue) => {
    setBuffer(newValue);
  };

  const navigateToProject = () => {
    navigate("/project");
  };

  const handleEditClick = (index, imageName) => {
    loadImage(index, imageName);
    if (editingIndex === index) {
      setEditingIndex(null);
      setOpenPanel(null);
    } else {
      setEditingIndex(index);
      setOpenPanel(index);
      const currentSurveyDetails = surveyDetails.survey_details[index];
      form.setFieldsValue({
        latitude: currentSurveyDetails.latitude || "",
        longitude: currentSurveyDetails.longitude || "",
        tag: currentSurveyDetails.tag || "",
        distance: currentSurveyDetails.distance || "",
      });
    }
  };

  const handlePanelClick = (index, imageName) => {
    if (openPanel === index) {
      setOpenPanel(null);
      setEditingIndex(null);
    } else {
      setOpenPanel(index);
      setEditingIndex(null);
      loadImage(index, imageName);
    }
  };

  const fetchSurveyDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_API_URL}/project-survey/getdetails/${accountid}?surveyid=${surveyid}`
      );
      const updatedDetails = response.data.data;
      setSurveyDetails(updatedDetails);
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  }, [accountid, surveyid]);

  useEffect(() => {
    fetchSurveyDetails();
  }, [fetchSurveyDetails]);

  const handleMarkerUpdate = (index, updatedMarker) => {
    // Set the editingIndex and openPanel to show the edit form
    setOpenPanel(index);
    setEditingIndex(index);

    // Get the current values for the selected survey point
    const currentSurveyDetails = surveyDetails.survey_details[index];

    // Update only latitude and longitude, keep other fields the same
    form.setFieldsValue({
      latitude: updatedMarker.latitude,
      longitude: updatedMarker.longitude,
      tag: currentSurveyDetails.tag || "", // retain current tag
      distance: currentSurveyDetails.distance || "", // retain current distance
    });
    loadImage(index, currentSurveyDetails.image_name);

    // Update the survey details without modifying project_name, user_name, or user_phone
    const updatedSurveyDetails = [...surveyDetails.survey_details];
    updatedSurveyDetails[index] = {
      ...updatedSurveyDetails[index],
      latitude: updatedMarker.latitude,
      longitude: updatedMarker.longitude,
      // Keep project_name, user_name, and user_phone unchanged
    };

    setSurveyDetails((prevDetails) => ({
      ...prevDetails,
      survey_details: updatedSurveyDetails,
      // Ensure project_name, user_name, and user_phone are not updated
      project_name: prevDetails.project_name,
      user_name: prevDetails.user_name,
      user_phone: prevDetails.user_phone,
    }));
  };

  const handleFormSubmit = async (values) => {
    // console.log("Form Values:", values);
    // // Ensure editingIndex and surveyDetails are correctly set
    // console.log("Editing Index:", editingIndex);
    // console.log("Survey Details:", surveyDetails);

    // Debugging the survey_point_id
    const surveyPointId =
      surveyDetails.survey_details[editingIndex]?.survey_point_id || "";
    // console.log("Survey Point ID:", surveyPointId);

    const formData = new FormData();
    formData.append("survey_details", JSON.stringify(values));
    formData.append("survey_id", surveyid);
    formData.append("survey_point_id", surveyPointId);

    // If a new image was uploaded, append it to the form data
    if (uploadedImage) {
      formData.append("image", uploadedImage);
    }

    try {
      const response = await axios.put(
        `${process.env.React_App_API_URL}/project-survey/update`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        message.success("Survey entry updated successfully!");
        setEditingIndex(null);
        setOpenPanel(null);
        setUploadedImage(null);
        fetchSurveyDetails();
      } else {
        message.error("Failed to update survey entry.");
      }
    } catch (error) {
      message.error("Error updating survey entry.");
      console.error("Error updating survey entry:", error);
    }
  };

  const handleApprove = async () => {
    try {
      const response = await axios.patch(
        `${process.env.React_App_API_URL}/project-survey/accept/${accountid}?surveyid=${surveyid}`
      );
      if (response.status === 200) {
        message.success("Survey Approved Successfully!");
        navigate("/project");
      } else {
        message.error("Failed to Approve Survey");
      }
    } catch (error) {
      message.error("Error while approving survey");
    }
  };

  const handleReject = async () => {
    try {
      const response = await axios.patch(
        `${process.env.React_App_API_URL}/project-survey/reject/${accountid}?surveyid=${surveyid}`
      );
      if (response.status === 200) {
        message.success("Survey Rejected Successfully!");
        navigate("/project");
      } else {
        message.error("Failed to Reject Survey");
      }
    } catch (error) {
      message.error("Error while rejecting survey");
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: (
        <span className="modal-confirm-title">
          WARNING: Deleting this Survey is Permanent!
        </span>
      ),
      icon: <ExclamationCircleOutlined className="modal-confirm-icon" />,
      content: (
        <span className="modal-confirm-content">
          Are you absolutely sure you want to delete this Survey? This action{" "}
          <strong>cannot be undone</strong>, and all associated data will be
          lost forever.
        </span>
      ),
      okText: "Yes, Delete",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: {
        className: "modal-confirm-ok-button",
      },
      cancelButtonProps: {
        className: "modal-confirm-cancel-button",
      },
      onOk() {
        handleReject();
      },
      onCancel() {},
    });
  };

  const showApproveConfirm = () => {
    confirm({
      title: (
        <span className="modal-confirm-save-title">
          CONFIRMATION: Approving this Survey
        </span>
      ),
      icon: <ExclamationCircleOutlined className="modal-confirm-icon" />,
      content: (
        <span className="modal-confirm-content">
          Are you sure you want to approve this Survey? This action{" "}
          <strong>cannot be reversed</strong>, and any pending actions will be
          finalized.
        </span>
      ),
      okText: "Yes, Approve",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: {
        className: "modal-confirm-ok-button",
      },
      cancelButtonProps: {
        className: "modal-confirm-cancel-button",
      },
      onOk() {
        handleApprove();
      },
      onCancel() {},
    });
  };

  const showSaveConfirm = () => {
    confirm({
      title: (
        <span className="modal-confirm-save-title">
          CONFIRMATION: Changing the Survey Point data
        </span>
      ),
      icon: <ExclamationCircleOutlined className="modal-confirm-icon" />,
      content: (
        <span className="modal-confirm-content">
          Are you sure you want to change this Survey point data? This action{" "}
          <strong>cannot be reversed .</strong>
        </span>
      ),
      okText: "Yes, Change",
      okType: "primary",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: {
        className: "modal-confirm-ok-button",
      },
      cancelButtonProps: {
        className: "modal-confirm-cancel-button",
      },
      onOk() {
        form.submit();
      },
      onCancel() {},
    });
  };

  useEffect(() => {
    if (surveyDetails?.type) {
      setBuildingType(surveyDetails.type);
    }
  }, [surveyDetails]);

  // Handle user changes
  const handleBuildingTypeChange = (e) => {
    setBuildingType(e.target.value);
  };

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb separator=">">
            <Breadcrumb.Item onClick={navigateToProject}>
              <span className="breadcrumb-reports">Project</span>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="breadcrumb-fleet-management">
                {surveyDetails.project_name || "No Project Name"}
              </span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="right-container">
          <Button className="customAddButton" onClick={showApproveConfirm}>
            <span className="addEmployee">Approve</span>
          </Button>
          <Button className="reject-button" onClick={showDeleteConfirm}>
            Reject
          </Button>
        </div>
      </div>
      <div className="preview-container">
        <Card
          className="preview-card custom-card"
          title={
            <div className="card-header">
              <Typography className="card-title">
                {surveyDetails.project_name || "No Project Name"}
              </Typography>
              <Typography className="sent-by">
                Sent By:
                <br />
                <span className="user-name-phone">
                  {surveyDetails.user_name || "No User Name"} (
                  {surveyDetails.user_phone || "No User Phone"})
                </span>
              </Typography>
            </div>
          }
        >
          <Typography className="building-type">Building Type</Typography>
          <Radio.Group
            className="building-type-radio-group"
            value={buildingType}
            onChange={handleBuildingTypeChange}
          >
            <Radio value="Non-Building">Non Building (Open Area)</Radio>
            <Radio value="Building">Building (Closed Area)</Radio>
          </Radio.Group>
          <div>
            <Typography className="building-type">
              Buffer (in kilometers)
            </Typography>
            <Row>
              <Col span={12}>
                <Slider
                  className="custom-slider"
                  min={0.001}
                  max={10}
                  onChange={onBufferChange}
                  value={typeof buffer === "number" ? buffer : 0}
                  step={0.001}
                />
              </Col>
              <Col span={4}>
                <InputNumber
                  min={0.001}
                  max={10}
                  style={{ margin: "0 16px" }}
                  value={buffer}
                  onChange={onBufferChange}
                  step={0.001}
                />
              </Col>
            </Row>
          </div>

          <Typography className="preview-card-title">
            Geofence Co-ordinates
          </Typography>
          <div className="card-scrollable-content">
            {surveyDetails.survey_details?.map((item, index) => (
              <Collapse
                key={index}
                activeKey={openPanel === index ? [`panel-${index}`] : []}
                onChange={() => handlePanelClick(index, item.image_name)}
                className="custom-collapse"
              >
                <Panel
                  header={
                    <div className="preview-panel-header">
                      <span className="panel-serial-number">{index + 1}</span>
                      <span className="panel-latitude">
                        Latitude: {item.latitude}
                      </span>
                      <span className="panel-longitude">
                        Longitude: {item.longitude}
                      </span>
                      <EditOutlined
                        className="edit-icon-end"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEditClick(index, item.image_name);
                        }}
                      />
                    </div>
                  }
                  key={`panel-${index}`}
                >
                  {editingIndex === index ? (
                    <Form
                      form={form}
                      layout="vertical"
                      onFinish={handleFormSubmit}
                    >
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Latitude" name="latitude">
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Longitude" name="longitude">
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Add Tag" name="tag">
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Distance" name="distance">
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      {loadedImages[index] ? (
                        <div className="survey-image-container">
                          <Suspense fallback={<div>Loading Image...</div>}>
                            <Image
                              src={loadedImages[index]}
                              alt={`Survey ${index + 1}`}
                              preview={{ src: loadedImages[index] }}
                              className="preview-survey-image"
                            />
                          </Suspense>
                        </div>
                      ) : (
                        <Typography preview-normal-text>
                          No image provided
                        </Typography>
                      )}
                      <span className="collapse-upload-media">
                        <label
                          htmlFor={`file-upload-${index}`}
                          style={{ cursor: "pointer" }}
                        >
                          Upload Media
                        </label>
                        <input
                          id={`file-upload-${index}`}
                          type="file"
                          name="images"
                          style={{ display: "none" }}
                          onChange={(e) => handleImageUpload(e, index)}
                        />
                      </span>
                      <Divider className="preview-collapse-divider" />
                      <div className="collapse-button-group">
                        <span
                          className="collapse-cancel-button"
                          onClick={() => {
                            setEditingIndex(null);
                            setOpenPanel(null);
                          }}
                        >
                          Cancel
                        </span>
                        <span
                          className="collapse-save-button"
                          onClick={showSaveConfirm}
                        >
                          Save
                        </span>
                      </div>
                    </Form>
                  ) : (
                    <>
                      <p className="panel-tag">Tag: {item.tag}</p>
                      <p className="panel-tag">Distance: {item.distance}</p>
                      {loadedImages[index] ? (
                        <div className="survey-image-container">
                          <Suspense fallback={<div>Loading Image...</div>}>
                            <Image
                              src={loadedImages[index]}
                              alt={`Survey ${index + 1}`}
                              preview={{ src: loadedImages[index] }}
                              className="preview-survey-image"
                            />
                          </Suspense>
                        </div>
                      ) : (
                        <Typography className="preview-normal-text">
                          No image provided
                        </Typography>
                      )}
                    </>
                  )}
                </Panel>
              </Collapse>
            ))}
          </div>
        </Card>
        <div className="preview-map-container">
          {surveyDetails.survey_details?.length > 0 && (
            <PreviewMap
              surveyDetails={surveyDetails}
              onMarkerUpdate={handleMarkerUpdate}
              buffer={buffer}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Preview;
