import React, { useState } from "react";
import { Button, Breadcrumb, DatePicker, Spin, Dropdown, Menu } from "antd";
import { DownloadOutlined, CalendarOutlined } from "@ant-design/icons";
import "./reportNavBar.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
const accountid = localStorage.getItem("accountid");

const VehicleReportNavBar = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const navigateToReport = () => {
    navigate("/report");
  };

  const handleExportToPdf = async () => {
    setLoading(true);
    try {
      let url = `${process.env.React_App_API_URL}/reports/fleetmanagement?accountid=${accountid}`;
      if (startDate) url += `&startdate=${startDate.format("YYYY-MM-DD")}`;
      if (endDate) url += `&enddate=${endDate.format("YYYY-MM-DD")}`;

      const response = await axios.get(url);
      const vehicles = response.data.data.results;

      const doc = new jsPDF();
      const tableColumn = [
        "Sr. No",
        "Vehicle Number",
        "Vehicle Type",
        "Max Speed",
        "Date",
        "Last Location",
        "Driver Name",
        "Distance",
        "Project Name",
      ];
      const tableRows = [];

      vehicles.forEach((vehicle, index) => {
        const vehicleData = [
          index + 1,
          vehicle.vehiclenumber,
          vehicle.vehicletype,
          vehicle.maxspeed,
          new Date(vehicle.date).toLocaleDateString(),
          vehicle.lastlocation,
          vehicle.drivername,
          vehicle.distance,
          vehicle.projects.length > 0
            ? vehicle.projects[0].project_name
            : "N/A",
        ];
        tableRows.push(vehicleData);
      });

      doc.setFillColor(240, 240, 240);
      doc.rect(10, 10, 190, 10, "F");

      // Set the text color to black
      doc.setTextColor(0, 0, 0);
      doc.text("Vehicle Report", 80, 17);
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 30,
        theme: "grid",
        tableWidth: "auto",
      });

      // Add logo to each page at right bottom corner
      const logo = `${process.env.PUBLIC_URL}/logopdf.png`;
      const logoWidth = 30;
      const logoHeight = 10;
      const marginRight = 15;
      const marginBottom = 5;
      const marginTop = 1;
      const x = doc.internal.pageSize.width - logoWidth - marginRight;
      const y =
        doc.internal.pageSize.height - logoHeight - marginBottom - marginTop;
      doc.addImage(logo, "PNG", x, y, logoWidth, logoHeight);

      doc.save("Vehicle_Report.pdf");
    } catch (error) {
      console.error("Error fetching vehicles: ", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportToCsv = async () => {
    setLoading(true);
    try {
      let url = `${process.env.React_App_API_URL}/reports/fleetmanagement?accountid=${accountid}`;
      if (startDate) url += `&startdate=${startDate.format("YYYY-MM-DD")}`;
      if (endDate) url += `&enddate=${endDate.format("YYYY-MM-DD")}`;

      const response = await axios.get(url);
      const vehicles = response.data.data.results;

      const csvData = [
        [
          "Sr. No",
          "Vehicle Number",
          "Vehicle Type",
          "Max Speed",
          "Date",
          "Last Location",
          "Driver Name",
          "Distance",
          "Project Name",
        ],
      ];

      vehicles.forEach((vehicle, index) => {
        const vehicleData = [
          index + 1,
          vehicle.vehiclenumber,
          vehicle.vehicletype,
          vehicle.maxspeed,
          new Date(vehicle.date).toLocaleDateString(),
          vehicle.lastlocation,
          vehicle.drivername,
          vehicle.distance,
          vehicle.projects.length > 0
            ? vehicle.projects[0].project_name
            : "N/A",
        ];
        csvData.push(vehicleData);
      });

      const csvContent = csvData.map((e) => e.join(",")).join("\n");
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvContent}`);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "Vehicle_Report.csv");
      link.click();
    } catch (error) {
      console.error("Error fetching vehicles: ", error);
    } finally {
      setLoading(false);
    }
  };

  const menu = (
    <Menu
      items={[
        {
          key: "pdf",
          label: "Export to PDF",
          onClick: handleExportToPdf,
          className: "export-menu-item",
        },
        {
          key: "csv",
          label: "Export to CSV",
          onClick: handleExportToCsv,
          className: "export-menu-item",
        },
      ]}
    />
  );

  return (
    <div className="FilterEmployeeVehicle">
      <div className="breadcrumb-container">
        <Breadcrumb separator=">">
          <Breadcrumb.Item onClick={navigateToReport}>
            <span className="breadcrumb-reports">Reports</span>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <span className="breadcrumb-fleet-management">
              Fleet Management
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="right-container">
        <div className="custom-date-range-picker">
          <DatePicker
            format="DD-MMM-YYYY"
            value={startDate}
            onChange={onStartDateChange}
            suffixIcon={<CalendarOutlined />}
          />
          <span className="separator">→</span>
          <DatePicker
            format="DD-MMM-YYYY"
            value={endDate}
            onChange={onEndDateChange}
            suffixIcon={<CalendarOutlined />}
          />
        </div>
        <Spin spinning={loading}>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button
              type="primary"
              className="customExportButton"
              icon={<DownloadOutlined />}
              disabled={loading}
            >
              Export
            </Button>
          </Dropdown>
        </Spin>
      </div>
    </div>
  );
};

export default VehicleReportNavBar;
