import React, { useState } from 'react';
import { Button, Breadcrumb, Spin } from 'antd';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './employeeDataNavBar.css';
import { useNavigate } from 'react-router-dom';

const EmployeeVehicleFilterNavbar = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const accountid = localStorage.getItem("accountid");

    const handleAddEmployeeClick = () => {
        navigate('/addemployee');
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const exportEmployeeData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.React_App_API_URL}/employee/users?accountid=${accountid}`);
            const employees = response.data;

            const doc = new jsPDF();
            const tableColumn = ["Sr. No", "Name", "Phone Number", "Role", "Joining Date", "Project"];
            const tableRows = [];

            employees.forEach((employee, index) => {
                const employeeData = [
                    index + 1, // Sr. No
                    employee.name,
                    employee.phonenumber,
                    employee.role_name,
                    formatDate(employee.joining_date),
                    employee.projectname,
                ];
                tableRows.push(employeeData);
            });

            doc.setFillColor(240, 240, 240);
            doc.rect(10, 10, 190, 10, 'F');

            // Set the text color to black
            doc.setTextColor(0, 0, 0);
            doc.text('Employee List', 80, 17);
            doc.autoTable({
                head: [tableColumn],
                body: tableRows,
                startY: 30,
                theme: 'grid',
                tableWidth: 'auto',
            });

            // Add logo to each page at right bottom corner
            const logo = `${process.env.PUBLIC_URL}/logopdf.png`;
            const logoWidth = 30;
            const logoHeight = 10;
            const marginRight = 15;
            const marginBottom = 5;
            const marginTop = 1;
            const x = doc.internal.pageSize.width - logoWidth - marginRight;
            const y = doc.internal.pageSize.height - logoHeight - marginBottom - marginTop;
            doc.addImage(logo, 'PNG', x, y, logoWidth, logoHeight);

            doc.save('employee_List.pdf');
        } catch (error) {
            // console.error(error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <div className='FilterEmployeeVehicle'>
            <div className="breadcrumb-container">
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <span className="employee">Employees</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className="right-container">
                <Spin spinning={loading}>
                    <Button type="primary"
                        className="customExportButton"
                        icon={<DownloadOutlined />}
                        onClick={exportEmployeeData}
                        disabled={loading}
                    >
                        Export to PDF
                    </Button>
                </Spin>
                <Button className="customAddButton" icon={<PlusOutlined />} onClick={handleAddEmployeeClick}>
                    <span className='addEmployee'>New Employee</span>
                </Button>
            </div>
        </div>
    );
};

export default EmployeeVehicleFilterNavbar;
