// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customEditColor{
color: #E76F51;
font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/New/EmployeeData/employeeList.css"],"names":[],"mappings":"AAAA;AACA,cAAc;AACd,iBAAiB;AACjB","sourcesContent":[".customEditColor{\ncolor: #E76F51;\nfont-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
