import React, { useState } from "react";
import { Button, Breadcrumb, Spin } from "antd";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import jsPDF from 'jspdf';

const accountid = localStorage.getItem("accountid");

function VehicleDataNavbar() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAddVehicleClick = () => {
    navigate("/AddVehicle");
  };

  const handleExportToPdf = async () => {
    setLoading(true);
    try {
        const response = await axios.get(`${process.env.React_App_API_URL}/vehicle/getvehicles?account_id=${accountid}`);
        const vehicles = response.data; // Adjusted to match the provided response structure

        const doc = new jsPDF();
        const tableColumn = ["Sr. No", "Vehicle Number", "Vehicle Type", "Driver Name", "Driver Phone Number", "Project Name"];
        const tableRows = [];

        vehicles.forEach((vehicle, index) => {
            const vehicleData = [
                index + 1,              
                vehicle.vehiclenumber,  
                vehicle.vehicletype,    
                vehicle.user_name,     
                vehicle.user_phonenumber, 
                vehicle.project_name    
            ];
            tableRows.push(vehicleData);
        });

        doc.setFillColor(240, 240, 240);
        doc.rect(10, 10, 190, 10, 'F');

        // Set the text color to black
        doc.setTextColor(0, 0, 0);
        doc.text('Vehicle List', 80, 17);
        
        // Add the table
        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 30,
            theme: 'grid',
            tableWidth: 'auto',
        });

        // Add logo to each page at right bottom corner
        const logo = `${process.env.PUBLIC_URL}/logopdf.png`;
        const logoWidth = 30;
        const logoHeight = 10;
        const marginRight = 15;
        const marginBottom = 5;
        const marginTop = 1;
        
        // Add logo on every page
        const totalPages = doc.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
            doc.setPage(i);
            const x = doc.internal.pageSize.width - logoWidth - marginRight;
            const y = doc.internal.pageSize.height - logoHeight - marginBottom - marginTop;
            doc.addImage(logo, 'PNG', x, y, logoWidth, logoHeight);
        }

        doc.save('Vehicle_List.pdf');

    } catch (error) {
        // console.error('Error fetching vehicles: ', error);
    } finally {
        setLoading(false);
    }
  };

  return (
    <div className="FilterEmployeeVehicle">
      <div className="breadcrumb-container">
        <Breadcrumb>
          <Breadcrumb.Item>
            <span className="employee">Vehicles</span>
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="right-container">
        <Spin spinning={loading}>
          <Button
            type="primary"
            className="customExportButton"
            icon={<DownloadOutlined />}
            onClick={handleExportToPdf}
            disabled={loading}
          >
            Export to PDF
          </Button>
        </Spin>
        <Button
          className="customAddButton"
          icon={<PlusOutlined />}
          onClick={handleAddVehicleClick}
        >
          <span className="addEmployee">New Vehicle</span>
        </Button>
      </div>
    </div>
  );
}

export default VehicleDataNavbar;
