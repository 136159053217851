// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.breadcrumb-reports {
    color: #E76F51;
    font-weight: bold;
    cursor: pointer;
}

.breadcrumb-fleet-management {
    color: #001233;
    font-weight: bold;
}
.export-menu-item {
    font-weight: bold !important;
    color: #001233 !important;
  }

.FilterEmployeeVehicle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.breadcrumb-container {
    flex: 1 1;
}

.right-container {
    display: flex;
    align-items: center;
    gap: 20px; 
}

.customExportButton {
    margin-left: 20px;
}

.custom-date-range-picker {
    display: flex;
    align-items: center;
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    padding: 4px;
}

.custom-date-range-picker .ant-picker {
    border: none;
    box-shadow: none;
}

.separator {
    margin: 0 8px;
    font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/Report/reportNavBar.css"],"names":[],"mappings":";AACA;IACI,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,4BAA4B;IAC5B,yBAAyB;EAC3B;;AAEF;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,SAAO;AACX;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["\n.breadcrumb-reports {\n    color: #E76F51;\n    font-weight: bold;\n    cursor: pointer;\n}\n\n.breadcrumb-fleet-management {\n    color: #001233;\n    font-weight: bold;\n}\n.export-menu-item {\n    font-weight: bold !important;\n    color: #001233 !important;\n  }\n\n.FilterEmployeeVehicle {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n}\n\n.breadcrumb-container {\n    flex: 1;\n}\n\n.right-container {\n    display: flex;\n    align-items: center;\n    gap: 20px; \n}\n\n.customExportButton {\n    margin-left: 20px;\n}\n\n.custom-date-range-picker {\n    display: flex;\n    align-items: center;\n    border: 1px solid #d9d9d9;\n    border-radius: 20px;\n    padding: 4px;\n}\n\n.custom-date-range-picker .ant-picker {\n    border: none;\n    box-shadow: none;\n}\n\n.separator {\n    margin: 0 8px;\n    font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
