import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import HeaderNav from "../Navbar/HeaderNav";
import {
  Form,
  Input,
  Select,
  Button,
  Breadcrumb,
  Row,
  Col,
  Typography,
  message,
} from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import "./addEmployee.css";
import axios from "axios";
import axiosInstance from "../Axios/AxiosInstance";

const { Title } = Typography;
const { Option } = Select;

const AddEmployee = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    departmentid: "",
    roleid: "",
    phoneNumber: "",
    projectName: "",
    aadhaarcard: "",
    pancard: "",
  });

  const [departments, setDepartments] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [projects, setProjects] = useState([]);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/project/getprojects");

        if (response.status === 200) {
          const projectData = response.data.projects;
          setProjects(projectData);
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/role/getroles`
        );

        if (response.status === 200) {
          const departmentsData = response.data.data;
          setDepartments(departmentsData);
        }
      } catch (error) {
        console.error("Error fetching roles:", error.message);
      }
    };

    fetchProjects();
    fetchDepartments();
  }, [accountid]);

  // Handle department change and filter roles accordingly
  const handleDepartmentChange = (value) => {
    setFormData({ ...formData, departmentid: value, roleid: "" });
    const selectedDepartment = departments.find((d) => d.id === value);
    if (selectedDepartment) {
      setFilteredRoles(selectedDepartment.roles);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataWithAccountId = {
        name: formData.name,
        phonenumber: formData.phoneNumber,
        roleid: formData.roleid,
        accountid,
        project_ids: [formData.projectName],
        aadhaarcard: formData.aadhaarcard,
        pancard: formData.pancard,
      };

      const response = await axios.post(
        `${process.env.React_App_API_URL}/employee/registration`,
        formDataWithAccountId
      );

      if (response.status === 201) {
        setFormData({
          name: "",
          departmentid: "",
          roleid: "",
          phoneNumber: "",
          projectName: "",
          aadhaarcard: "",
          pancard: "",
        });

        message.open({
          type: "success",
          content: "Employee added successfully",
          duration: 3,
          placement: "bottomRight",
        });

        if (location.state?.role) {
          navigate("/AddVehicle");
        } else {
          navigate("/EmployeeData");
        }
      } else {
        message.open({
          type: "error",
          content: "Failed to add employee",
          duration: 3,
        });
      }
    } catch (error) {
      message.open({
        type: "error",
        content: "Failed to add employee",
        duration: 3,
      });
    }
  };

  const handleCancel = () => {
    navigate("/EmployeeData");
  };

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span className="employee">Employee Registration</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            className="Exit"
            icon={<CloseOutlined />}
            onClick={handleCancel}
          >
            Exit
          </Button>
          <Button
            className="customAddButton"
            icon={<PlusOutlined />}
            onClick={handleSubmit}
          >
            <span className="addEmployee">Add</span>
          </Button>
        </div>
      </div>

      <div style={{ backgroundColor: "#f0f2f5", padding: "50px" }}>
        <div
          style={{
            padding: "20px",
            maxWidth: "700px",
            margin: "auto",
            backgroundColor: "#ffffff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4} className="title-color">
                Employee Details
              </Title>
            </Col>
            <Col span={24}>
              <Form layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Name</span>}
                      required
                      rules={[{ required: true, message: "Please enter name" }]}
                    >
                      <Input
                        value={formData.name}
                        onChange={(e) =>
                          setFormData({ ...formData, name: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Phone Number</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter phone number",
                        },
                        {
                          pattern: /^[0-9]{10}$/,
                          message: "Phone number must be exactly 10 digits",
                        },
                      ]}
                    >
                      <Input
                        addonBefore="+91"
                        value={formData.phoneNumber}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            setFormData({
                              ...formData,
                              phoneNumber: value,
                            });
                          }
                        }}
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Department</span>}
                      required
                      rules={[
                        { required: true, message: "Please Select Department" },
                      ]}
                    >
                      <Select
                        placeholder="Select department"
                        value={formData.departmentid}
                        onChange={handleDepartmentChange}
                      >
                        {departments.map((d) => (
                          <Option key={d.id} value={d.id}>
                            {d.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Role</span>}
                      required
                      rules={[
                        { required: true, message: "Please Select Role" },
                      ]}
                    >
                      <Select
                        placeholder="Select role"
                        value={formData.roleid}
                        onChange={(value) =>
                          setFormData({ ...formData, roleid: value })
                        }
                        disabled={!formData.departmentid}
                      >
                        {filteredRoles.map((r) => (
                          <Option key={r.id} value={r.id}>
                            {r.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Project Name</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please Select a Project",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select from the list"
                        value={formData.projectName}
                        onChange={(value) =>
                          setFormData({ ...formData, projectName: value })
                        }
                      >
                        {projects
                          .filter((site) => !site.isdeleted)
                          .map((site) => (
                            <Option key={site.id} value={site.id}>
                              {site.projectname}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Aadhar Card</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter Aadhar card number",
                        },
                        {
                          pattern: /^[0-9]{12}$/,
                          message: "Aadhar card must be exactly 12 digits",
                        },
                      ]}
                    >
                      <Input
                        value={formData.aadhaarcard}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (/^\d*$/.test(value)) {
                            setFormData({
                              ...formData,
                              aadhaarcard: value,
                            });
                          }
                        }}
                        maxLength={12}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label={<span className="label-name">Pan Card</span>}
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please enter Pan card number",
                        },
                        {
                          pattern: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                          message: "Pan card must be in the format ABCDE1234F",
                        },
                      ]}
                    >
                      <Input
                        value={formData.pancard}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            pancard: e.target.value.toUpperCase(),
                          })
                        }
                        maxLength={10}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default AddEmployee;
