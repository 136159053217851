import React, { useState, useRef, useEffect } from "react";
import { ReactComponent as CbLogo } from "../assets/SignUp/Civil Brain Logo Color.svg";
import { ReactComponent as CbFrame } from "../assets/SignUp/Frame.svg";
import { Form, Input, Button, Card, Typography, message, Modal } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./SignUp.css";

const { Title, Text } = Typography;

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [emailForVerification, setEmailForVerification] = useState("");
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [timer, setTimer] = useState(0);
  const [resendEnabled, setResendEnabled] = useState(true);

  const otpRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (timer === 0) return;

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1000) {
          clearInterval(interval);
          setResendEnabled(true);
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const startTimer = () => {
    setResendEnabled(false);
    setTimer(1 * 60 * 1000);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `Resend in ${String(minutes).padStart(2, "0")}:${String(
      seconds
    ).padStart(2, "0")}. `;
  };

  const onFinish = async () => {
    if (password !== confirmPassword) {
      message.error("Passwords do not match!");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.React_App_API_URL}/otp/sendOTP`,
        { email }
      );

      switch (response.status) {
        case 200:
          message.success("OTP sent successfully to your email.");
          setEmailForVerification(email);
          setIsOtpModalVisible(true);
          startTimer();
          break;
        case 409:
          message.warning(response.data.message);
          break;
        default:
          message.error(
            "Unexpected response from server. Please try again later."
          );
      }
    } catch (error) {
      console.error("Failed to send OTP:", error);
      if (error.response && error.response.data) {
        message.error(
          error.response.data.message ||
            "Failed to send OTP. Please try again later."
        );
      } else {
        message.error("Failed to send OTP. Please try again later.");
      }
    }
  };

  const handleOtpChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value && index < otp.length - 1) {
      otpRefs.current[index + 1].focus();
    }
  };

  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && index > 0 && !otp[index]) {
      otpRefs.current[index - 1].focus();
    }
  };

  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");

    if (/^\d{6}$/.test(pastedData)) {
      const newOtp = pastedData.split("");
      setOtp(newOtp);
      otpRefs.current[otp.length - 1].focus();
    } else {
      message.error("Please paste a valid 6-digit OTP.");
    }
  };

  const handleOtpVerification = async () => {
    const otpValue = otp.join("");
    try {
      const response = await axios.post(
        `${process.env.React_App_API_URL}/otp/verifyOTP`,
        { email: emailForVerification, otp: otpValue }
      );

      console.log("OTP verified:", response.data);
      message.success("OTP verified successfully!");
      setIsOtpModalVisible(false);

      navigate("/additional-info", { state: { email, password } });
    } catch (error) {
      console.error("Failed to verify OTP:", error);
      message.error("Failed to verify OTP. Please try again.");
    }
  };

  const resendOtp = async () => {
    if (resendEnabled) {
      await onFinish();
    }
  };

  return (
    <div className="signup-container">
      <div className="frame-container">
        <Text className="frame-text">
          Embark on a transformative journey with Civil Brain AI
        </Text>
        <CbFrame className="frame" />
      </div>

      <div className="cb-logo-container">
        <CbLogo className="cb-logo" />

        <Card className="form-card">
          <>
            <Title className="signup-title">Sign up</Title>
            <Form name="signup-form" layout="vertical" onFinish={onFinish}>
              <Form.Item
                label="Email Address"
                rules={[
                  { required: true, message: "Please enter your email!" },
                ]}
              >
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                rules={[
                  { required: true, message: "Please enter your password!" },
                ]}
              >
                <Input.Password
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
              </Form.Item>

              <Form.Item
                label="Confirm Password"
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                ]}
              >
                <Input.Password
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your password"
                />
              </Form.Item>

              <Form.Item>
                <Button className="su-btn" htmlType="submit">
                  Send OTP
                </Button>

                <Typography.Text className="terms-text">
                  By signing up, you agree to Civil Brain’s{" "}
                  <a href="/terms-of-service">Terms of Service</a> and{" "}
                  <a href="/privacy-policy">Privacy Policy</a>.
                </Typography.Text>
              </Form.Item>
            </Form>
          </>
        </Card>
        <Typography.Text className="signin-text">
          Already have an account? <a href="/">Sign in</a>
        </Typography.Text>
      </div>

      <Modal
        title="Check your inbox"
        visible={isOtpModalVisible}
        onCancel={() => setIsOtpModalVisible(false)}
        footer={null}
        centered
        className="otp-modal"
        maskClosable={false}
      >
        <div className="otp-verification">
          <Text className="verification-email-text">
            We've sent an OTP to <strong>{emailForVerification}</strong> to
            verify your email
          </Text>
          <div className="otp-inputs">
            {otp.map((digit, index) => (
              <Input
                key={index}
                ref={(el) => (otpRefs.current[index] = el)}
                value={digit}
                onChange={(e) => handleOtpChange(e.target.value, index)}
                onKeyDown={(e) => handleOtpKeyDown(e, index)}
                maxLength={1}
                onPaste={handleOtpPaste}
                className="otp-input"
              />
            ))}
          </div>
          <div className="otp-timer">
            <span>{formatTime(timer)}</span>
            <span
              className="resend-btn"
              onClick={resendOtp}
              style={{
                color: "#E76F51",
                cursor: "pointer",
              }}
            >
              Resend OTP
            </span>
          </div>

          <Button
            className="cancel-btn"
            onClick={() => setIsOtpModalVisible(false)}
          >
            Cancel
          </Button>
          <Button className="verify-btn" onClick={handleOtpVerification}>
            Verify
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Register;
