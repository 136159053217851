import React, { createContext, useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const accountid = localStorage.getItem("accountid");
    const isLoggedIn = localStorage.getItem("isLoggedIn");

    if (isLoggedIn && accountid) {
      const newSocket = io(process.env.React_App_API_URL, {
        query: { accountid },
      });
      setSocket(newSocket);

      newSocket.on("connect", () => {
        // console.log("Socket connected:", newSocket.id);
      });

      return () => {
        newSocket.disconnect();
        setSocket(null);
      };
    }
  }, []);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
