import React, { useState, useEffect } from "react";
import { Card, Collapse, Avatar, Space, Tag, Row, Col } from "antd";
import { EnvironmentOutlined } from "@ant-design/icons";
import activeAvatar from "../../../assets/VehicleEmployeeMap/employeeonlinemarker.svg";
import inactiveAvatar from "../../../assets/VehicleEmployeeMap/employeeofflinemarker.svg";
import "./EmployeeSideBar.css";

const { Panel } = Collapse;

const StatusTag = ({ status }) => {
  return status ? (
    <Tag color="green" style={{ marginLeft: "2rem" }}>
      Active
    </Tag>
  ) : (
    <Tag color="gray" style={{ marginLeft: "2rem" }}>
      Inactive
    </Tag>
  );
};

const GeofenceTag = ({ status }) => {
  return status === "Inside" ? (
    <Tag color="purple">{status}</Tag>
  ) : (
    <Tag color="red">{status}</Tag>
  );
};

const CustomCardHeader = ({ count }) => (
  <div className="custom-card-header">
    <span className="header-title">Employees&nbsp;</span>
    <span className="header-badge">{count}</span>
  </div>
);

const EmployeeSideBar = ({
  selectedProject,
  selectedRole,
  selectedStatus,
  searchQuery,
  EmployeeSideBarClick,
}) => {
  const [EmployeeData, setEmployeeData] = useState([]);
  const accountid = localStorage.getItem("accountid");
  let count = 0;

  useEffect(() => {
    if (!accountid) {
      console.error("Account ID is required");
      return;
    }

    let eventSource;

    const connectToSSE = () => {
      const url = new URL(`${process.env.React_App_API_URL}/home/getemployees`);
      url.searchParams.append("accountid", accountid);
      if (selectedProject) {
        url.searchParams.append("projectid", selectedProject);
      }
      if (selectedRole) {
        url.searchParams.append("roleid", selectedRole);
      }

      eventSource = new EventSource(url);

      eventSource.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log(`emp sse data : ${count++}`, data);
        if (data.status === "success") {
          let employeeData = data.data.results;

          if (Array.isArray(employeeData)) {
            if (searchQuery) {
              employeeData = employeeData.filter(
                (item) =>
                  item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                  item.phonenumber.includes(searchQuery)
              );
            }
            const employeeDataWithKeys = employeeData.map((item, index) => ({
              ...item,
              key: index,
            }));

            let filteredData;
            if (selectedStatus === "active") {
              filteredData = employeeDataWithKeys.filter(
                (item) => item.onlinestatus === true
              );
            } else if (selectedStatus === "inactive") {
              filteredData = employeeDataWithKeys.filter(
                (item) => item.onlinestatus === false
              );
            } else {
              filteredData = employeeDataWithKeys;
            }

            setEmployeeData(filteredData);
          } else {
            console.error(
              "Error: Fetched data is not in the expected format:",
              data
            );
          }
        } else {
          // console.error(
          //   "Error: Failed to fetch employee details",
          //   data.message
          // );
        }
      };

      eventSource.onerror = (error) => {
        // console.error("Error in SSE connection:", error);
        eventSource.close();

        setTimeout(() => {
          console.log("Attempting to reconnect...");
          connectToSSE();
        }, 5000);
      };

      // Clean up on unmount
      return eventSource;
    };

    connectToSSE();

    return () => {
      if (eventSource) {
        eventSource.close();
        console.log("Employee EventSource closed");
      }
    };
  }, [
    selectedProject,
    selectedRole,
    selectedStatus,
    accountid,
    searchQuery,
    count,
  ]);

  return (
    <Card
      title={<CustomCardHeader count={EmployeeData.length} />}
      className="Employee-sidebar-card"
    >
      <Collapse accordion className="ant-collapse-borderless">
        {EmployeeData.map((employee, index) => (
          <Panel
            header={
              <Space>
                <Avatar
                  src={employee.onlinestatus ? activeAvatar : inactiveAvatar}
                  style={{
                    backgroundColor: employee.onlinestatus ? "green" : "gray",
                  }}
                />
                <div className="employee-info">
                  {employee.name}
                  {employee.onlinestatus ? (
                    <StatusTag status={employee.onlinestatus} />
                  ) : (
                    <span>
                      <StatusTag status={employee.onlinestatus} />
                      <span style={{ marginLeft: 8 }}>
                        {employee.lastactivity}
                      </span>
                    </span>
                  )}
                  <div className="employee-role">{employee.role}</div>
                </div>
              </Space>
            }
            key={index}
          >
            <Row
              className="employee-details"
              onClick={() => EmployeeSideBarClick(employee)}
            >
              <Col span={12} className="left-column">
                <p>
                  <strong className="description-color">Phone Number</strong>
                  <br />
                  {employee.phonenumber}
                </p>
                <p className="margin-top">
                  <strong className="description-color">Project Name</strong>
                  <br />
                  {employee.projectnames}
                </p>
                <p className="margin-top">
                  <strong className="description-color">
                    {employee.onlinestatus
                      ? "Current Location"
                      : "Last Location"}
                  </strong>
                  <br />
                  <EnvironmentOutlined className="location-color" />{" "}
                  <span className="location-color">{employee.location}</span>
                </p>
              </Col>
              <Col span={12} className="right-column">
                <p>
                  <strong className="description-color">
                    Last Activity Updated
                  </strong>
                  <br />
                  {employee.lastactivity}
                </p>
                <p className="margin-top">
                  <strong className="description-color">Geofence Status</strong>
                  <br />
                  <GeofenceTag status={employee.geofencestatus} />
                </p>
                <p className="margin-top">
                  <strong className="description-color">Battery Status</strong>
                  <br />
                  {employee.batterystatus}%
                </p>
              </Col>
            </Row>
          </Panel>
        ))}
      </Collapse>
    </Card>
  );
};

export default EmployeeSideBar;
