import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import { EditOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function VehicleListData({
  selectedProject,
  selectedVehicleType,
  searchQuery,
}) {
  const [vehicleData, setVehicleData] = useState([]);
  const [loading, setLoading] = useState(false);
  const accountid = localStorage.getItem("accountid");
  const navigate = useNavigate();

  const columns = [
    {
      title: "Sr No.",
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 80,
      render: (text, record, index) => (
        <span className="customTextColor">{index + 1}</span>
      ),
    },
    {
      title: "Reg No.",
      dataIndex: "imei",
      key: "imei",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Vehicle No.",
      dataIndex: "vehiclenumber",
      key: "vehiclenumber",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Driver Name",
      dataIndex: "user_name",
      key: "user_name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Driver Phone No.",
      dataIndex: "user_phonenumber",
      key: "user_phonenumber",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicletype",
      key: "vehicletype",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Project Name",
      dataIndex: "project_name",
      key: "project_name",
      render: (text) => <span className="customTextColor">{text}</span>,
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <span
          className="customEditColor"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate(`/EditVehicle`, { state: { vehicle: record } })
          }
        >
          <EditOutlined style={{ marginRight: 8 }} />
          Edit
        </span>
      ),
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        let url = `${process.env.React_App_API_URL}/vehicle/getvehicles?account_id=${accountid}`;
        if (selectedProject) {
          url += `&project_id=${selectedProject}`;
        }
        if (selectedVehicleType) {
          url += `&vehicletype=${selectedVehicleType}`;
        }
        const response = await axios.get(url);

        let fetchedData = response.data;

        if (Array.isArray(fetchedData)) {
          if (searchQuery) {
            fetchedData = fetchedData.filter(
              (item) =>
                item.vehiclenumber
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                item.user_name.toLowerCase().includes(searchQuery.toLowerCase())
            );
          }
          const vehicleDataWithKeys = fetchedData.map((item, index) => ({
            ...item,
            key: index,
          }));
          setVehicleData(vehicleDataWithKeys);
        }
      } catch (error) {
        console.error("Error fetching vehicle data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accountid, selectedProject, selectedVehicleType, searchQuery]);

  return (
    <Table
      columns={columns}
      dataSource={vehicleData}
      pagination={{ pageSize: 100, showSizeChanger: false }}
      bordered
      style={{ margin: "20px" }}
      className="custom-table-header"
      loading={loading}
      scroll={{ x: 1000, y: 430 }}
    />
  );
}

export default VehicleListData;
