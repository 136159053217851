import React from 'react'

import HeaderNav from "../Navbar/HeaderNav"
import MapVehicleEmployeeFilter from './MapFilter/MapVehicleEmployeeFilter'
import EmployeeMap from './EmployeeMap/EmployeeMapDasboard'

export default function MapDashboard() {
  return (
    <>
    <HeaderNav/>
    <MapVehicleEmployeeFilter/>
    <EmployeeMap/>
    
    </>
  )
}
