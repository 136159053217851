//project view dashboard
import React from 'react';
import HeaderNav from "../../Navbar/HeaderNav";
import ProjectViewNavbar from './projectViewNavbar'
import ProjectView from './projectView'
import ActivityLog from './ActivityLog'


const ProjectViewDashboard = () => {
  return (
    <>
    <HeaderNav/>
    <ProjectViewNavbar/>
    <ProjectView/>
    <ActivityLog/>
    </>
  );
};

export default ProjectViewDashboard;

