import React, { useState, useEffect } from "react";
import axios from "axios";
import { Input, Select, Space, Button, Row, Col } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./vehicleFilterNavbar.css";

const { Option } = Select;

const VehicleFilter = ({
  onSpeedSelect,
  selectedSpeed,
  onVehicleTypeSelect,
  selectedVehicleType,
  onSearch,
  searchText,
  onClearFilters,
  onStatusSelect,
  selectedStatus,
}) => {
  const [vehicleTypeData, setVehicleTypeData] = useState([]);

  useEffect(() => {
    fetchVehicleTypeData();
  }, []);

  const fetchVehicleTypeData = async () => {
    try {
      const response = await axios.get(
        `${process.env.React_App_API_URL}/vehicletype/fetchvehicletype`
      );
      setVehicleTypeData(response.data.data);
    } catch (error) {
      console.error("Error fetching vehicle type data:", error);
      setVehicleTypeData([]);
    }
  };

  const handleSpeedFilter = (speed) => {
    onSpeedSelect(speed);
  };

  const handleVehicleTypeChange = (value) => {
    onVehicleTypeSelect(value);
  };

  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };

  const handleStatusChange = (value) => {
    onStatusSelect(value);
  };

  const handleClearFilters = () => {
    onClearFilters();
  };

  return (
    <div className="vehiclefilterContainer">
      <Row className="vehicleFilterRow">
        <Col flex="auto">
          <Space size="large" className="vehicleFilterSpace">
            <Input
              placeholder="Search by vin no, driver name"
              prefix={<SearchOutlined />}
              style={{ width: 250, borderRadius: 15, color: "#001233" }}
              value={searchText}
              onChange={handleSearchChange}
            />
            <Select
              placeholder="Select status"
              className="vehiclecustomSelect"
              style={{ width: 220 }}
              onChange={handleStatusChange}
              value={selectedStatus || undefined}
            >
              {["Moving", "Idle", "Stopped"].map((status) => (
                <Option key={status} value={status}>
                  <input
                    type="checkbox"
                    checked={selectedStatus === status}
                    onChange={() => onStatusSelect(status)}
                    style={{
                      accentColor: "#E76F51",
                      marginRight: "8px",
                    }}
                  />
                  {status}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Select vehicle type"
              className="vehiclecustomSelect"
              style={{ width: 220 }}
              onChange={handleVehicleTypeChange}
              value={selectedVehicleType}
            >
              {Array.isArray(vehicleTypeData) &&
                vehicleTypeData.map((type) => (
                  <Option key={type.id} value={type.vehicletype}>
                    <input
                      type="checkbox"
                      checked={selectedVehicleType === type.vehicletype}
                      onChange={() => onVehicleTypeSelect(type.vehicletype)}
                      style={{
                        accentColor: "#E76F51",
                        marginRight: "8px",
                      }}
                    />
                    {type.vehicletype}
                  </Option>
                ))}
            </Select>
            <Select
              placeholder="Select Speed"
              className="vehiclecustomSelect"
              style={{ width: 220 }}
              onChange={handleSpeedFilter}
              value={selectedSpeed}
            >
              <Option value="80">
                <input
                  type="checkbox"
                  checked={selectedSpeed === "80"}
                  onChange={() => onSpeedSelect("80")}
                  style={{
                    accentColor: "#E76F51",
                    marginRight: "8px",
                  }}
                />
                Above 80
              </Option>
            </Select>
            <Button className="customclearButton" onClick={handleClearFilters}>
              Clear all filters
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default VehicleFilter;
