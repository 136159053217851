import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  Breadcrumb,
  Row,
  Col,
  Typography,
  message,
  Modal,
  Spin,
} from "antd";
import {
  CloseOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import axiosInstance from "../Axios/AxiosInstance";
import HeaderNav from "../Navbar/HeaderNav";
import "./editEmployee.css";

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const EditEmployee = () => {
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    role: "",
    projectId: "",
    aadhaarCard: "",
    panCard: "",
    departmentid: "",
  });

  const [projects, setProjects] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [filteredRoles, setFilteredRoles] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(false);
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const location = useLocation();
  const employeeId = location.state.id;
  const accountId = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/employee/${accountId}/user/${employeeId}`
        );
        const employeeData = response.data;
        setFormData({
          name: employeeData.name,
          phoneNumber: employeeData.phonenumber,
          role: employeeData.roleid,
          projectId: employeeData.project_id,
          aadhaarCard: employeeData.aadhaarcard,
          panCard: employeeData.pancard,
          departmentid: employeeData.departmentid,
        });
        // Set roles based on the department from fetched employee data
        const selectedDepartment = departments.find(
          (d) => d.id === employeeData.departmentid
        );
        if (selectedDepartment) {
          setFilteredRoles(selectedDepartment.roles);
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchEmployeeData();
  }, [accountId, employeeId, departments]);

  useEffect(() => {
    const fetchProjects = async () => {
      setLoadingProjects(true);
      try {
        const response = await axiosInstance.get("/project/getprojects");
        if (response.status === 200) {
          const projectData = response.data.projects;
          setProjects(projectData);
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      } finally {
        setLoadingProjects(false);
      }
    };

    const fetchDepartmentsAndRoles = async () => {
      setLoadingRoles(true);
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/role/getroles`
        );

        if (response.status === 200) {
          const departmentsData = response.data.data;
          setDepartments(departmentsData);
        } else {
          console.error("Failed to fetch roles");
        }
      } catch (error) {
        console.error("Error fetching roles:", error.message);
      } finally {
        setLoadingRoles(false);
      }
    };

    fetchProjects();
    fetchDepartmentsAndRoles();
  }, [accountId]);

  const navigate = useNavigate();

  const handleCancel = () => {
    navigate("/EmployeeData");
  };

  // Handle department change and filter roles accordingly
  const handleDepartmentChange = (value) => {
    setFormData({ ...formData, departmentid: value, role: "" });
    const selectedDepartment = departments.find((d) => d.id === value);
    if (selectedDepartment) {
      setFilteredRoles(selectedDepartment.roles);
    }
  };

  // Handle role change
  const handleRoleChange = (value) => {
    setFormData({ ...formData, role: value });
  };

  const handleSave = async () => {
    setLoadingUpdate(true);
    try {
      const { phoneNumber, role, projectId } = formData;
      const selectedProject = projects.find(
        (project) => project.id === projectId
      );
      const projectIds = [selectedProject.id];
      const data = {
        phonenumber: phoneNumber,
        roleId: role,
        projectIds: projectIds,
      };

      const response = await axios.put(
        `${process.env.React_App_API_URL}/employee/${accountId}/updateuser/${employeeId}`,
        data
      );

      if (response.status === 200) {
        message.success("Employee updated successfully");
        navigate("/EmployeeData");
      } else {
        message.error("Failed to update employee");
      }
    } catch (error) {
      console.error("Error updating employee:", error);
      message.error("Failed to update employee");
    } finally {
      setLoadingUpdate(false);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: (
        <span style={{ color: "#001233" }}>
          Are you sure you want to delete this employee?
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <span style={{ color: "#001233" }}>
          'This action cannot be undone'{" "}
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: {
        style: {
          backgroundColor: "#E76F51",
          borderColor: "#E76F51",
          color: "#001233",
          fontWeight: "bold",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: "#D9DCE1",
          borderColor: "#D9DCE1",
          color: "#001233",
          fontWeight: "bold",
        },
      },

      onOk() {
        handleDelete();
      },
      onCancel() {},
    });
  };

  const handleDelete = async () => {
    try {
      const response = await axios.delete(
        `${process.env.React_App_API_URL}/employee/delete/${employeeId}?accountid=${accountId}`
      );
      if (response.status === 200) {
        message.success("Employee deleted successfully");
        navigate("/EmployeeData");
      } else {
        message.error("Failed to delete employee");
      }
    } catch (error) {
      console.error("Error deleting employee:", error);
      message.error("Failed to delete employee");
    }
  };

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span className="employee">Update Employee</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            className="Exit"
            icon={<CloseOutlined />}
            onClick={handleCancel}
          >
            Exit
          </Button>
          <Button
            className="customDeleteButton"
            icon={<DeleteOutlined />}
            onClick={showDeleteConfirm}
            disabled={loadingUpdate}
          >
            <span>Delete</span>
          </Button>
        </div>
      </div>

      <div style={{ backgroundColor: "#f0f2f5", padding: "50px" }}>
        <div
          style={{
            padding: "20px",
            maxWidth: "700px",
            margin: "auto",
            backgroundColor: "#ffffff",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Title level={4} className="title-color">
                Basic Details
              </Title>
            </Col>
            <Col span={24}>
              <Spin spinning={loadingRoles || loadingProjects}>
                <Form layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Name"
                        required
                        rules={[
                          { required: true, message: "Please enter name" },
                        ]}
                      >
                        <Input
                          disabled={loadingRoles || loadingProjects}
                          value={formData.name}
                          onChange={(e) =>
                            setFormData({ ...formData, name: e.target.value })
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Phone Number"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please enter phone Number",
                          },
                          {
                            pattern: /^[0-9]{10}$/,
                            message: "Phone number must be exactly 10 digits",
                          },
                        ]}
                      >
                        <Input
                          addonBefore="+91"
                          value={formData.phoneNumber}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                              setFormData({
                                ...formData,
                                phoneNumber: value,
                              });
                            }
                          }}
                          maxLength={10}
                          disabled={loadingRoles || loadingProjects}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Department"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please Select Department",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select department"
                          value={formData.departmentid}
                          onChange={handleDepartmentChange}
                        >
                          {departments.map((d) => (
                            <Option key={d.id} value={d.id}>
                              {d.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Role"
                        required
                        rules={[
                          { required: true, message: "Please Select Role" },
                        ]}
                      >
                        <Select
                          placeholder="Select role"
                          value={formData.role}
                          onChange={handleRoleChange}
                          disabled={!formData.departmentid}
                        >
                          {filteredRoles.map((role) => (
                            <Option key={role.id} value={role.id}>
                              {role.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label="Project"
                        required
                        rules={[
                          { required: true, message: "Please Select Project" },
                        ]}
                      >
                        <Select
                          placeholder="Select project"
                          value={formData.projectId}
                          onChange={(value) =>
                            setFormData({ ...formData, projectId: value })
                          }
                        >
                          {projects.map((project) => (
                            <Option key={project.id} value={project.id}>
                              {project.projectname}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="Aadhaar Card">
                        <Input value={formData.aadhaarCard} disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item label="Pan Card">
                        <Input value={formData.panCard} disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Spin>
            </Col>
          </Row>
        </div>
        <Col span={24} style={{ maxWidth: "700px", margin: "auto" }}>
          <Button
            className="newcustomAddButton"
            icon={<PlusOutlined />}
            onClick={handleSave}
            disabled={loadingUpdate}
          >
            <span className="newaddEmployee">Save</span>
          </Button>
        </Col>
      </div>
    </>
  );
};

export default EditEmployee;
