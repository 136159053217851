import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../Axios/AxiosInstance";
import { Input, Select, Space, Button, Row, Col } from "antd";
import { SearchOutlined, EnvironmentOutlined } from "@ant-design/icons";

const { Option } = Select;

function VehicleDataFilter({
  onProjectSelect,
  onClearFilters,
  selectedProject,
  onVehicleTypeSelect,
  selectedVehicleType,
  onSearch,
}) {
  const [projects, setProjects] = useState([]);
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const accountid = localStorage.getItem("accountid");
  const location = useLocation();
  const projectIdFromState = location.state?.projectId;

  const [localSelectedProject, setLocalSelectedProject] = useState(
    projectIdFromState || null
  );

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/project/getprojects");

        if (response.status === 200) {
          const projectData = response.data.projects;
          setProjects(projectData);
          if (
            projectIdFromState &&
            projectData.some((project) => project.id === projectIdFromState)
          ) {
            setLocalSelectedProject(projectIdFromState);
          }
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    const fetchVehicleTypeData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/vehicletype/fetchvehicletype`
        );
        setVehicleTypeData(response.data.data);
      } catch (error) {
        console.error("Error fetching vehicle type data:", error);
        setVehicleTypeData([]);
      }
    };

    fetchProjects();
    fetchVehicleTypeData();
  }, [accountid, projectIdFromState]);

  useEffect(() => {
    if (localSelectedProject) {
      onProjectSelect(localSelectedProject);
    }
  }, [localSelectedProject, onProjectSelect]);

  const handleProjectChange = (value) => {
    setLocalSelectedProject(value);
    onProjectSelect(value);
  };

  const handleCheckboxChange = (projectId) => {
    if (localSelectedProject === projectId) {
      setLocalSelectedProject(null);
      onProjectSelect(null);
    } else {
      handleProjectChange(projectId);
    }
  };

  const handleVehicleTypeChange = (value) => {
    onVehicleTypeSelect(value);
  };

  const handleClearFilters = () => {
    setLocalSelectedProject(null);
    setSearchQuery("");
    onClearFilters();
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    onSearch(e.target.value);
  };

  // Determine if the Select component should be disabled
  const isDisabled = !!projectIdFromState;

  return (
    <div className="vehiclefilterContainer">
      <Row className="vehicleFilterRow">
        <Col flex="auto">
          <Space size="large" className="vehicleFilterSpace">
            <div className="customSelect">
              <EnvironmentOutlined className="dropdown-icon" />
              <Select
                style={{ width: 190 }}
                placeholder="Select Project"
                value={localSelectedProject}
                onChange={handleProjectChange}
                disabled={isDisabled}
              >
                {!isDisabled &&
                  projects
                    .filter((site) => !site.isdeleted)
                    .map((site) => (
                      <Option key={site.id} value={site.id}>
                        <input
                          type="checkbox"
                          checked={localSelectedProject === site.id}
                          onChange={() => handleCheckboxChange(site.id)}
                          style={{
                            accentColor: "#E76F51",
                            marginRight: 8,
                          }}
                        />
                        {site.projectname}
                      </Option>
                    ))}
                {isDisabled &&
                  projects
                    .filter((site) => site.id === localSelectedProject)
                    .map((site) => (
                      <Option key={site.id} value={site.id}>
                        <input
                          type="checkbox"
                          checked
                          disabled
                          style={{
                            accentColor: "#E76F51",
                            marginRight: 8,
                          }}
                        />
                        {site.projectname}
                      </Option>
                    ))}
              </Select>
            </div>
            <Input
              placeholder="Search by VIN no, driver name"
              prefix={<SearchOutlined />}
              style={{ width: 250, borderRadius: 15, color: "#001233" }}
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Select
              placeholder="Select Vehicle Type"
              className="vehiclecustomSelect"
              style={{ width: 220 }}
              onChange={handleVehicleTypeChange}
              value={selectedVehicleType}
            >
              {Array.isArray(vehicleTypeData) &&
                vehicleTypeData.map((type) => (
                  <Option key={type.id} value={type.vehicletype}>
                    <input
                      type="checkbox"
                      checked={selectedVehicleType === type.vehicletype}
                      onChange={() => onVehicleTypeSelect(type.vehicletype)}
                      style={{
                        accentColor: "#E76F51",
                        marginRight: 8,
                      }}
                    />
                    {type.vehicletype}
                  </Option>
                ))}
            </Select>
            <Button className="customclearButton" onClick={handleClearFilters}>
              Clear all filters
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
}

export default VehicleDataFilter;
