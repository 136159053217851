import { React, useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import EmployeeFilter from "../../New/Employee/EmployeeFilterNavbar/EmployeeFilter";
import EmployeeTable from "./employeeReportTable";
import ReportNavBar from "./EmployeeReportNavBar";

export default function EmployeeReport() {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [searchText, setSearchText] = useState("");

  const handleDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleProjectSelect = (project) => {
    setSelectedProject(project);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleStatusSelect = (status) => {
    setSelectedStatus(status);
  };

  const handleSearch = (text) => {
    setSearchText(text);
  };

  const handleClearFilters = () => {
    setSelectedProject(null);
    setSelectedRole(null);
    setSelectedStatus(null);
    setSearchText("");
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <HeaderNav />
      <ReportNavBar
        startDate={startDate}
        endDate={endDate}
        onDateChange={handleDateChange}
      />
      <EmployeeFilter
        onProjectSelect={handleProjectSelect}
        selectedProject={selectedProject}
        onRoleSelect={handleRoleSelect}
        selectedRole={selectedRole}
        onStatusSelect={handleStatusSelect}
        selectedStatus={selectedStatus}
        onSearch={handleSearch}
        searchText={searchText}
        onClearFilters={handleClearFilters}
      />
      <EmployeeTable
        startDate={startDate}
        endDate={endDate}
        selectedProject={selectedProject}
        selectedRole={selectedRole}
        selectedStatus={selectedStatus}
        searchText={searchText}
      />
    </>
  );
}
