import React, { useEffect, useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Form,
  Input,
  Select,
  Button,
  Breadcrumb,
  Row,
  Col,
  Typography,
  message,
  Spin,
  Modal,
} from "antd";
import {
  CloseOutlined,
  PlusOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import axios from "axios";
import axiosInstance from "../Axios/AxiosInstance";

const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

function EditVehicle() {
  const location = useLocation();
  const { vehicle } = location.state;
  const vehicleId = vehicle.vehicle_id || "";

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const accountid = localStorage.getItem("accountid");

  const [loading, setLoading] = useState(false);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [fetchedUserId, setFetchedUserId] = useState(null);

  // Fetch vehicle details
  useEffect(() => {
    const fetchVehicleDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/vehicle/getvehicle?account_id=${accountid}&id=${vehicleId}`
        );

        const matchedData = response.data[0];
        if (matchedData) {
          setFetchedUserId(matchedData.user_id);
          form.setFieldsValue({
            imei: matchedData.imei,
            vehiclenumber: matchedData.vehiclenumber,
            vehicletype: matchedData.vehicletype,
            userId: matchedData.user_id,
            driverPhoneNumber: matchedData.user_phonenumber,
            driverName: matchedData.user_name,
            projectId: matchedData.project_id,
          });

          // Set selectedProjectId for driver options fetching
          const selectedProject = projects.find(
            (project) => project.projectname === matchedData.project_name
          );
          setSelectedProjectId(selectedProject ? selectedProject.id : null);
        }
      } catch (error) {
        message.error("Error fetching vehicle details");
      } finally {
        setLoading(false);
      }
    };

    fetchVehicleDetails();
  }, [accountid, vehicleId, form, projects]);

  // Fetch options for vehicle types and projects
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        // Fetch vehicle types
        const vehicleTypeResponse = await axios.get(
          `${process.env.React_App_API_URL}/vehicletype/fetchvehicletype`
        );
        setVehicleTypeOptions(vehicleTypeResponse.data.data);

        // Fetch projects using axiosInstance
        axiosInstance
          .get(`/project/getprojects?accountid=${accountid}`)
          .then((response) => {
            setProjects(response.data.projects);
          })
          .catch((error) => {
            console.error("Error fetching Working Sites:", error);
          });
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, [accountid]);

  // Fetch driver options when a project is selected
  useEffect(() => {
    if (selectedProjectId) {
      axios
        .post(
          `${process.env.React_App_API_URL}/employee/drivernumber?accountid=${accountid}`,
          { projectIds: [selectedProjectId] }
        )
        .then((response) => {
          setDriverOptions(response.data.availableDrivers || []);
        })
        .catch((error) => {
          console.error("Error fetching driver phone numbers:", error);
        });
    }
  }, [selectedProjectId, accountid]);

  const handleProjectChange = (value) => {
    setSelectedProjectId(value);
    form.setFieldsValue({
      driverPhoneNumber: "",
      driverName: "",
      userId: "",
    });
  };

  const handleDriverPhoneNumberChange = (value) => {
    const selectedDriver = driverOptions.find(
      (driver) => driver.phonenumber === value
    );
    if (selectedDriver) {
      form.setFieldsValue({
        driverName: selectedDriver.name,
        userId: selectedDriver.id,
      });
    }
  };

  const handleSubmit = async (values) => {
    const { imei, vehiclenumber, vehicletype, driverPhoneNumber, projectId } =
      values;

    const selectedDriver = driverOptions.find(
      (driver) => driver.phonenumber === driverPhoneNumber
    );

    const userId = selectedDriver ? selectedDriver.id : fetchedUserId;

    if (!userId) {
      message.error("User ID is missing");
      return;
    }

    const accountidNumber = Number(accountid);
    const projectIds = Array.isArray(projectId) ? projectId : [projectId];

    try {
      const response = await axios.put(
        `${process.env.React_App_API_URL}/vehicle/editvehicle/${vehicleId}`,
        {
          imei,
          vehiclenumber,
          vehicletype,
          user_id: userId,
          account_id: accountidNumber,
          project_ids: projectIds,
        }
      );

      if (response.status === 200) {
        message.success("Vehicle updated successfully");
        navigate("/VehicleDashboardData");
      } else {
        message.error("Failed to update vehicle");
      }
    } catch (error) {
      message.error("Error updating vehicle");
    }
  };

  const handleCancel = () => {
    navigate("/VehicleDashboardData");
  };

  const handleDeleteVehicle = async () => {
    try {
      await axios.delete(
        `${process.env.React_App_API_URL}/vehicle/deletevehicle/${vehicleId}?accountid=${accountid}`
      );
      message.success("Vehicle deleted successfully");
      navigate("/VehicleDashboardData");
    } catch (error) {
      message.error("Error deleting vehicle");
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: (
        <span style={{ color: "#001233" }}>
          Are you sure you want to delete this vehicle?
        </span>
      ),
      icon: <ExclamationCircleOutlined />,
      content: (
        <span style={{ color: "#001233" }}>
          'This action cannot be undone'{" "}
        </span>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      centered: true,
      okButtonProps: {
        style: {
          backgroundColor: "#E76F51",
          borderColor: "#E76F51",
          color: "#001233",
          fontWeight: "bold",
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: "#D9DCE1",
          borderColor: "#D9DCE1",
          color: "#001233",
          fontWeight: "bold",
        },
      },
      onOk() {
        handleDeleteVehicle();
      },
      onCancel() {},
    });
  };

  return (
    <>
      <HeaderNav />
      <div className="FilterEmployeeVehicle">
        <div className="breadcrumb-container">
          <Breadcrumb>
            <Breadcrumb.Item>
              <span className="employee">Update Vehicle</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="right-container">
          <Button
            type="primary"
            className="Exit"
            icon={<CloseOutlined />}
            onClick={handleCancel}
          >
            Exit
          </Button>
          <Button
            className="customDeleteButton"
            icon={<DeleteOutlined />}
            onClick={showDeleteConfirm}
          >
            <span>Delete</span>
          </Button>
        </div>
      </div>

      <div style={{ backgroundColor: "#f0f2f5", padding: "50px" }}>
        <Spin spinning={loading}>
          <div
            style={{
              padding: "20px",
              maxWidth: "700px",
              margin: "auto",
              backgroundColor: "#ffffff",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "8px",
            }}
          >
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Title level={4} className="title-color">
                  Vehicle Details
                </Title>
              </Col>
              <Col span={24}>
                <Form layout="vertical" form={form} onFinish={handleSubmit}>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">IMEI Number</span>}
                        name="imei"
                        rules={[
                          {
                            required: true,
                            message: "Please enter IMEI Number",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={
                          <span className="label-name">Vehicle Number</span>
                        }
                        name="vehiclenumber"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Vehicle Number",
                          },
                        ]}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Vehicle Type</span>}
                        name="vehicletype"
                        rules={[
                          {
                            required: true,
                            message: "Please select Vehicle Type",
                          },
                        ]}
                      >
                        <Select placeholder="Select Vehicle Type">
                          {vehicleTypeOptions.map((option) => (
                            <Option
                              key={option.vtypeid}
                              value={option.vehicletype}
                            >
                              {option.vehicletype}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Project Name</span>}
                        name="projectId"
                        rules={[
                          {
                            required: true,
                            message: "Please select Project",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Project"
                          onChange={handleProjectChange}
                        >
                          {projects
                            .filter((project) => !project.isdeleted)
                            .map((project) => (
                              <Option key={project.id} value={project.id}>
                                {project.projectname}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={
                          <span className="label-name">
                            Driver Phone Number
                          </span>
                        }
                        name="driverPhoneNumber"
                        rules={[
                          {
                            required: true,
                            message: "Please select Driver Phone Number",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select Driver Phone Number"
                          onChange={handleDriverPhoneNumberChange}
                        >
                          {driverOptions.map((driver) => (
                            <Option
                              key={driver.phonenumber}
                              value={driver.phonenumber}
                            >
                              {driver.phonenumber}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                      <Form.Item
                        label={<span className="label-name">Driver Name</span>}
                        name="driverName"
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </div>
        </Spin>
        <Col span={24} style={{ maxWidth: "700px", margin: "auto" }}>
          <Button
            className="newcustomAddButton"
            icon={<PlusOutlined />}
            onClick={() => form.submit()}
          >
            <span className="newaddEmployee">Save</span>
          </Button>
        </Col>
      </div>
    </>
  );
}

export default EditVehicle;
