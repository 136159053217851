import React, { useState } from "react";
import { Button } from "antd";
import { useNavigate, useLocation } from "react-router-dom";


import MapIcon from "../../../assets/VehicleEmployeeMap/map.svg";
import ListIcon from "../../../assets/VehicleEmployeeMap/list.svg";

import "./EmployeeVehicleFilterNavbar.css";

const EmployeeVehicleFilterNavbar = () => {
  const [selected, setSelected] = useState("list");

  const navigate = useNavigate();
  const location = useLocation();

  const [selectedSiteId] = useState(null);

  const handleSelect = (selection) => {
    setSelected(selection);
    if (selection === "map") {
      navigate("/");
    } else if (selection === "list") {
      navigate("/EmployeeDashboard");
    }
  };

  const handleButtonClick = (buttonType) => {
    const route =
      buttonType === "employees" ? "/EmployeeDashboard" : "/VehicleDashboard";
    navigate(route, { state: { wsiteid: selectedSiteId } });
  };

  let activeButton = "employees";
  if (location.pathname === "/EmployeeDashboard") {
    activeButton = "employees";
  } else if (location.pathname === "/VehicleDashboard") {
    activeButton = "vehicles";
  }

  return (
    <div className="FilterEmployeeVehicle">
      <div className="CenterGroup">
        <Button.Group className="custom-button-group">
          <Button
            type="primary"
            className={`custom-button employees-button ${
              activeButton === "employees" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("employees")}
          >
            Employees
          </Button>
          <Button
            className={`custom-button vehicles-button ${
              activeButton === "vehicles" ? "active" : ""
            }`}
            onClick={() => handleButtonClick("vehicles")}
          >
            Vehicles
          </Button>
        </Button.Group>
      </div>
      <div className="live-indicator-container">
        <div className="live-indicator"></div>
        <span className="live-text">Live</span>
      </div>
      <div className="icon-buttons">
        <Button
          type={selected === "map" ? "primary" : "default"}
          onClick={() => handleSelect("map")}
          icon={<img src={MapIcon} alt="Map Icon" />}
          className={`icon-button ${
            selected === "map" ? "selected-button" : ""
          }`}
        />
        <Button
          type={selected === "list" ? "primary" : "default"}
          onClick={() => handleSelect("list")}
          icon={<img src={ListIcon} alt="List Icon" />}
          className={`icon-button ${
            selected === "list" ? "selected-button" : ""
          }`}
        />
      </div>

     
    </div>
  );
};

export default EmployeeVehicleFilterNavbar;
