// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table-header .ant-table-thead > tr > th {
  background-color: #e6e8eb;
  color: #001233;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 2;
}

.customTextColor {
  color: #001233;
  font-size: 14px;
}

.currentlocationColor {
  color: #e76f51;
  font-weight: bold;
}

.custom-table-header .ant-table-body {
  overflow-y: auto;
  max-height: 500px;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/Employee/table/EmployeeTable.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;EACd,iBAAiB;EACjB,gBAAgB;EAChB,MAAM;EACN,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":[".custom-table-header .ant-table-thead > tr > th {\n  background-color: #e6e8eb;\n  color: #001233;\n  font-weight: bold;\n  position: sticky;\n  top: 0;\n  z-index: 2;\n}\n\n.customTextColor {\n  color: #001233;\n  font-size: 14px;\n}\n\n.currentlocationColor {\n  color: #e76f51;\n  font-weight: bold;\n}\n\n.custom-table-header .ant-table-body {\n  overflow-y: auto;\n  max-height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
