import React, { useState } from "react";
import { Layout, Menu, Avatar, Dropdown } from "antd";
import { MenuOutlined, UserOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import Logo from "../../assets/Logo/civilbrainlogo.svg";
import TempGeofence from "../Geofence/TempGeofence";
import { useSocket } from "../../../Socket/SocketContext";
import { useProfileImage } from "../../../Socket/ProfileImageContext";
import "./Header.css";

const { Header } = Layout;

const pages = [
  { label: "Home", link: "/" },
  { label: "Projects", link: "/project" },
  { label: "Employees", link: "/EmployeeData" },
  { label: "Vehicles", link: "/VehicleDashboardData" },
  { label: "Reports", link: "/report" },
];

function HeaderNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const { imageUrl } = useProfileImage();
  const socket = useSocket();
  const [isDropdownVisible, setDropdownVisible] = useState(false);

  const isActive = (pageLink) => {
    return location.pathname === pageLink;
  };

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("adminid");
    localStorage.removeItem("accountid");

    if (socket) {
      socket.disconnect();
    }

    window.location.reload();
    navigate("/");
  };

  const profileMenu = (
    <Menu className="profile-menu">
      <Menu.Item
        onClick={() => navigate("/profile")}
        icon={<UserOutlined className="profile-menu-item-icon" />}
      >
        <span className="profile-menu-item-text">Profile</span>
      </Menu.Item>
      <Menu.Item
        onClick={handleLogout}
        icon={<LogoutOutlined className="profile-menu-item-icon" />}
      >
        <span className="profile-menu-item-text">Logout</span>
      </Menu.Item>
    </Menu>
  );

  const menuItems = (
    <Menu onClick={() => setDropdownVisible(false)}>
      {pages.map((page) => (
        <Menu.Item
          key={page.label}
          onClick={() => {
            if (!isActive(page.link)) {
              navigate(page.link);
            }
          }}
          onMouseDown={(e) => e.preventDefault()}
          className={`navbar-menu-item ${
            isActive(page.link) ? "navbar-menu-item-active" : ""
          }`}
        >
          <span className="profile-menu-item-text">{page.label}</span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Header
      className="navbar-sticky"
      style={{ backgroundColor: "#001233", padding: 0 }}
    >
      <div className="navbar-container">
        <Dropdown
          overlay={menuItems}
          trigger={["click"]}
          visible={isDropdownVisible}
          onVisibleChange={(flag) => setDropdownVisible(flag)}
        >
          <MenuOutlined className="menu-icon" />
        </Dropdown>
        <img
          src={Logo}
          alt="Logo"
          className="navbar-logo"
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
        />
        <Menu theme="dark" mode="horizontal" className="navbar-menu">
          {pages.map((page) => (
            <Menu.Item
              key={page.label}
              onClick={() => {
                if (!isActive(page.link)) {
                  navigate(page.link);
                }
              }}
              onMouseDown={(e) => e.preventDefault()}
              className={`navbar-menu-item ${
                isActive(page.link) ? "navbar-menu-item-active" : ""
              }`}
            >
              {page.label}
            </Menu.Item>
          ))}
        </Menu>
        <TempGeofence />
        <Dropdown
          overlay={profileMenu}
          trigger={["click"]}
          placement="bottomRight"
        >
          <div className="menu-avatar-container">
            <MenuOutlined
              style={{ color: "white", fontSize: "18px", marginRight: "10px" }}
            />
            <div className="navbar-profile-container">
              {imageUrl ? (
                <img
                  src={imageUrl}
                  alt="Profile"
                  className="navbar-profile-image"
                />
              ) : (
                <Avatar icon={<UserOutlined />} />
              )}
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  );
}

export default HeaderNav;
