import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../Axios/AxiosInstance";
import { Input, Select, Space, Button, Row, Col } from "antd";
import { SearchOutlined, EnvironmentOutlined } from "@ant-design/icons";

const { Option } = Select;

const VehicleFilter = ({
  onProjectSelect,
  selectedProject,
  onVehicleTypeSelect,
  selectedVehicleType,
  onStatusSelect,
  selectedStatus,
  onSearch,
  searchText,
  onClearFilters,
}) => {
  const [vehicleTypeData, setVehicleTypeData] = useState([]);
  const [projects, setProjects] = useState([]);
  const accountid = localStorage.getItem("accountid");

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axiosInstance.get("/project/getprojects");
        if (response.status === 200) {
          setProjects(response.data.projects);
        } else {
          console.error("Failed to fetch projects");
        }
      } catch (error) {
        console.error("Error fetching projects:", error.message);
      }
    };

    const fetchVehicleTypeData = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/vehicletype/fetchvehicletype`
        );
        setVehicleTypeData(response.data.data);
      } catch (error) {
        console.error("Error fetching vehicle type data:", error);
        setVehicleTypeData([]);
      }
    };

    fetchProjects();
    fetchVehicleTypeData();
  }, [accountid]);

  const handleProjectChange = (value) => {
    onProjectSelect(value);
  };

  const handleVehicleTypeChange = (value) => {
    onVehicleTypeSelect(value);
  };

  const handleStatusChange = (value) => {
    onStatusSelect(value);
  };

  const handleSearchChange = (e) => {
    onSearch(e.target.value);
  };

  const handleClearFilters = () => {
    onClearFilters();
  };

  return (
    <div className="vehiclefilterContainer">
      <Row className="vehicleFilterRow">
        <div className="customSelect">
          <EnvironmentOutlined className="dropdown-icon" />
          <Select
            style={{ width: 190 }}
            placeholder="Select Project"
            value={selectedProject}
            onChange={handleProjectChange}
          >
            {projects
              .filter((site) => !site.isdeleted)
              .map((site) => (
                <Option key={site.id} value={site.id}>
                  <input
                    type="checkbox"
                    checked={selectedProject === site.id}
                    onChange={() => handleProjectChange(site.id)}
                    style={{ accentColor: "#E76F51", marginRight: 8 }}
                  />
                  {site.projectname}
                </Option>
              ))}
          </Select>
        </div>
        <Col flex="auto">
          <Space size="large" className="vehicleFilterSpace">
            <Input
              placeholder="Search by vin no, driver name"
              prefix={<SearchOutlined />}
              style={{ width: 250, borderRadius: 15, color: "#001233" }}
              value={searchText}
              onChange={handleSearchChange}
            />
            <Select
              placeholder="Vehicle Status"
              className="vehiclecustomSelect"
              style={{ width: 220 }}
              onChange={handleStatusChange}
              value={selectedStatus}
            >
              {["Moving", "Idle", "Stopped"].map((status) => (
                <Option key={status} value={status}>
                  <input
                    type="checkbox"
                    checked={selectedStatus === status}
                    onChange={() => handleStatusChange(status)}
                    style={{ accentColor: "#E76F51", marginRight: 8 }}
                  />
                  {status}
                </Option>
              ))}
            </Select>
            <Select
              placeholder="Select vehicle type"
              className="vehiclecustomSelect"
              style={{ width: 220 }}
              onChange={handleVehicleTypeChange}
              value={selectedVehicleType}
            >
              {vehicleTypeData.map((type) => (
                <Option key={type.id} value={type.vehicletype}>
                  <input
                    type="checkbox"
                    checked={selectedVehicleType === type.vehicletype}
                    onChange={() => handleVehicleTypeChange(type.vehicletype)}
                    style={{ accentColor: "#E76F51", marginRight: 8 }}
                  />
                  {type.vehicletype}
                </Option>
              ))}
            </Select>
            <Button className="customclearButton" onClick={handleClearFilters}>
              Clear all filters
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default VehicleFilter;
