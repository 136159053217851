import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import EmployeeIcon from "../../../assets/projectview/totalEmployee.svg";
import VehicleIcon from "../../../assets/projectview/totalVehicle.svg";
// import CompletionRateIcon from "../../../assets/projectview/average.svg";
import { Row, Col, Typography, Card, Tooltip, Skeleton } from "antd";
import {
  CalendarOutlined,
  EnvironmentOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons";
import "./projectView.css";

const { Paragraph } = Typography;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).format(date);
};

const ProjectDetails = () => {
  const location = useLocation();
  const { site } = location.state;
  const projectId = site.id;
  const accountid = localStorage.getItem("accountid");
  const [projectData, setProjectData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalVehicles, setTotalVehicles] = useState(0);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `${process.env.React_App_API_URL}/project/getproject/${projectId}`,
          {
            params: { accountid },
          }
        );

        if (response.status === 200) {
          const projectData = response.data.project;
          setProjectData(projectData);
          setTotalEmployees(response.data.totalEmployees);
          setTotalVehicles(response.data.totalVehicles);
        }
      } catch (error) {
        console.error("Error fetching project data:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [accountid, projectId]);

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <Row gutter={[23, 23]}>
      <Col span={16}>
        <Card
          title={
            <span style={{ color: "#001233", fontWeight: "bold" }}>
              Project Details
            </span>
          }
          bordered={true}
          className="project-details-card"
        >
          <Row gutter={[16, 16]}>
            <Col span={8}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <span style={{ color: "#7F8898" }}>
                    <CalendarOutlined />
                  </span>
                  <span style={{ marginLeft: 8, color: "#7F8898" }}>
                    Start Date <br />
                    <span style={{ color: "#001233", fontSize: "16px" }}>
                      {formatDate(projectData?.startdate)}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <span style={{ color: "#7F8898" }}>
                    <CalendarOutlined />
                  </span>
                  <span style={{ marginLeft: 8, color: "#7F8898" }}>
                    End Date <br />
                    <span style={{ color: "#001233", fontSize: "16px" }}>
                      {formatDate(projectData?.enddate)}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <span style={{ color: "#7F8898" }}>
                    <CalendarOutlined />
                  </span>
                  <span style={{ marginLeft: 8, color: "#7F8898" }}>
                    Stipulated End Date <br />
                    <span style={{ color: "#001233", fontSize: "16px" }}>
                      {formatDate(projectData?.stipulateddate)}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <span style={{ color: "#7F8898" }}>
                    <EnvironmentOutlined />
                  </span>
                  <span style={{ marginLeft: 8, color: "#7F8898" }}>
                    Location <br />
                    <span style={{ color: "#001233", fontSize: "16px" }}>
                      {projectData?.location || "No Data"}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
            <Col span={8}>
              <Row gutter={[0, 0]}>
                <Col span={24}>
                  <span style={{ color: "#7F8898" }}>
                    <DollarCircleOutlined />
                  </span>
                  <span style={{ marginLeft: 8, color: "#7F8898" }}>
                    Tender Value <br />
                    <span style={{ color: "#001233", fontSize: "16px" }}>
                      {projectData
                        ? `₹${Number(projectData.tendervalue).toLocaleString()}`
                        : "NA"}
                    </span>
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>

          <Paragraph strong style={{ color: "#001233", marginTop: "2rem" }}>
            Description
          </Paragraph>
          <Paragraph style={{ color: "#001233" }}>
            {projectData?.descriptions || "No Data"}
          </Paragraph>
        </Card>
      </Col>

      <Col span={8}>
        <Card
          title={
            <span style={{ color: "#001233", fontWeight: "bold" }}>Stats</span>
          }
          bordered={true}
          className="stats-card"
        >
          <Row gutter={[16, 8]}>
            <Col span={24}>
              <Card style={{ height: 80, marginTop: 0, paddingTop: 0 }}>
                <Row
                  gutter={[0, 0]}
                  style={{ alignItems: "flex-start", height: "100%" }}
                >
                  <Col
                    span={16}
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <span
                      style={{
                        marginLeft: 1,
                        color: "#A6ACB8",
                        fontWeight: "bold",
                      }}
                    >
                      Total Employees
                    </span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Tooltip title="Total Employees">
                      <img
                        src={EmployeeIcon}
                        alt="Total Employees"
                        style={{ width: 30, height: 30 }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={[0, 0]}>
                  <Col span={24}>
                    <span className="ProjectViewValue">{totalEmployees}</span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              <Card style={{ height: 80, marginTop: 0, paddingTop: 0 }}>
                <Row
                  gutter={[0, 0]}
                  style={{ alignItems: "flex-start", height: "100%" }}
                >
                  <Col
                    span={16}
                    style={{ display: "flex", alignItems: "flex-start" }}
                  >
                    <span
                      style={{
                        marginLeft: 1,
                        color: "#A6ACB8",
                        fontWeight: "bold",
                      }}
                    >
                      Total Vehicles
                    </span>
                  </Col>
                  <Col
                    span={8}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <Tooltip title="Total Vehicles">
                      <img
                        src={VehicleIcon}
                        alt="Total Vehicles"
                        style={{ width: 30, height: 30 }}
                      />
                    </Tooltip>
                  </Col>
                </Row>
                <Row gutter={[0, 0]}>
                  <Col span={24}>
                    <span className="ProjectViewValue">{totalVehicles}</span>
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={24}>
              {/* <Card style={{ height: 80, marginTop: 0, paddingTop: 0 }}> */}
              <Row
                gutter={[0, 0]}
                style={{ alignItems: "flex-start", height: "100%" }}
              >
                <Col
                  span={16}
                  style={{ display: "flex", alignItems: "flex-start" }}
                >
                  {/* <span
                      style={{
                        marginLeft: 1,
                        color: "#A6ACB8",
                        fontWeight: "bold",
                      }}
                    >
                      Average Completion Rate
                    </span> */}
                </Col>
                <Col
                  span={8}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  {/* <Tooltip title="Average Completion Rate">
                      <img
                        src={CompletionRateIcon}
                        alt="Average Completion Rate"
                        style={{ width: 30, height: 30 }}
                      />
                    </Tooltip> */}
                </Col>
              </Row>
              {/* <Row gutter={[0, 0]}>
                  <Col span={24}>
                    <span className="ProjectViewValue">30%</span>
                  </Col>
                </Row> */}
              {/* </Card> */}
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default ProjectDetails;
