// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerAck {
  width: 80%;
  margin: 30px auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.containerAck h1 {
  color: #001233;
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

.containerAck p {
  margin: 0 0 15px;
  font-size: 16px;
  color: #001233;
}

.containerAck ul {
  padding-left: 20px;
  margin: 0 0 15px;
}

.containerAck ul li {
  margin-bottom: 10px;
  font-size: 16px;
  color: #001233;
}

.containerAck a {
  color: #ff5722;
  text-decoration: none;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.containerAck a:hover {
  text-decoration: underline;
  color: #ff7043;
}

.containerAck span {
  color: #ff5722;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.containerAck p:last-of-type {
  margin-top: 20px;
  font-size: 16px;
  color: #494882;
  text-align: center;
}

.containerAck p:last-of-type br {
  display: none;
}
.back-arrow {
  display: inline-block;
  margin-bottom: 20px;
  cursor: pointer;
  color: #ff7043 !important;
  font-size: 18px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/Login/SignUpAck.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,wCAAwC;EACxC,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,4DAA4D;AAC9D;;AAEA;EACE,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,4DAA4D;AAC9D;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;AACA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,eAAe;EACf,yBAAyB;EACzB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".containerAck {\n  width: 80%;\n  margin: 30px auto;\n  padding: 20px;\n  background: #ffffff;\n  border-radius: 8px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  font-family: Arial, sans-serif;\n  color: #333;\n  line-height: 1.6;\n}\n\n.containerAck h1 {\n  color: #001233;\n  font-size: 28px;\n  margin-bottom: 20px;\n  text-align: center;\n}\n\n.containerAck p {\n  margin: 0 0 15px;\n  font-size: 16px;\n  color: #001233;\n}\n\n.containerAck ul {\n  padding-left: 20px;\n  margin: 0 0 15px;\n}\n\n.containerAck ul li {\n  margin-bottom: 10px;\n  font-size: 16px;\n  color: #001233;\n}\n\n.containerAck a {\n  color: #ff5722;\n  text-decoration: none;\n  font-family: \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\n}\n\n.containerAck a:hover {\n  text-decoration: underline;\n  color: #ff7043;\n}\n\n.containerAck span {\n  color: #ff5722;\n  font-family: \"Segoe UI\", Tahoma, Geneva, Verdana, sans-serif;\n}\n\n.containerAck p:last-of-type {\n  margin-top: 20px;\n  font-size: 16px;\n  color: #494882;\n  text-align: center;\n}\n\n.containerAck p:last-of-type br {\n  display: none;\n}\n.back-arrow {\n  display: inline-block;\n  margin-bottom: 20px;\n  cursor: pointer;\n  color: #ff7043 !important;\n  font-size: 18px;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
