// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vehiclefilterContainer {
  position: sticky;
  top: 140px;
  z-index: 1000;
  padding: 10px;
  background: #ffffff;
  margin: 21px;
  border-radius: 12px;
  overflow-x: auto;
}

.vehicleFilterRow {
  display: flex;
  align-items: center;
  width: 100%;
}

.vehicleFilterSpace {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
}

.vehiclecustomSelect .ant-select-selector {
  border-radius: 15px !important;
  color: #001233;
}

.vehiclecustomSelect .ant-select-arrow {
  color: #001233;
}

.customclearButton {
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  background: none;
  color: #e76f51 !important;
  border: none;
}

.customclearButton:hover {
  background: none !important;
  color: #e76f5179 !important;
}

/* Media Queries */
@media (max-width: 1200px) {
  .vehicleFilterSpace {
    gap: 8px;
  }
}

@media (max-width: 768px) {
  .vehicleFilterSpace {
    flex-wrap: wrap;
    gap: 8px;
  }

  .vehiclecustomSelect,
  .customclearButton,
  .ant-input-affix-wrapper {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/New/Vehicle/Table/vehicleFilter/vehicleFilterNavbar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;EACV,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,SAAS;AACX;;AAEA;EACE,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,wBAAwB;EACxB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA,kBAAkB;AAClB;EACE;IACE,QAAQ;EACV;AACF;;AAEA;EACE;IACE,eAAe;IACf,QAAQ;EACV;;EAEA;;;IAGE,sBAAsB;EACxB;AACF","sourcesContent":[".vehiclefilterContainer {\n  position: sticky;\n  top: 140px;\n  z-index: 1000;\n  padding: 10px;\n  background: #ffffff;\n  margin: 21px;\n  border-radius: 12px;\n  overflow-x: auto;\n}\n\n.vehicleFilterRow {\n  display: flex;\n  align-items: center;\n  width: 100%;\n}\n\n.vehicleFilterSpace {\n  display: flex;\n  flex-wrap: nowrap;\n  gap: 16px;\n}\n\n.vehiclecustomSelect .ant-select-selector {\n  border-radius: 15px !important;\n  color: #001233;\n}\n\n.vehiclecustomSelect .ant-select-arrow {\n  color: #001233;\n}\n\n.customclearButton {\n  font-family: \"Work Sans\";\n  font-style: normal;\n  font-weight: 800;\n  font-size: 16px;\n  line-height: 24px;\n  background: none;\n  color: #e76f51 !important;\n  border: none;\n}\n\n.customclearButton:hover {\n  background: none !important;\n  color: #e76f5179 !important;\n}\n\n/* Media Queries */\n@media (max-width: 1200px) {\n  .vehicleFilterSpace {\n    gap: 8px;\n  }\n}\n\n@media (max-width: 768px) {\n  .vehicleFilterSpace {\n    flex-wrap: wrap;\n    gap: 8px;\n  }\n\n  .vehiclecustomSelect,\n  .customclearButton,\n  .ant-input-affix-wrapper {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
