// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employee {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 32px;
  color: #001233;
}
.customAddButton {
  background: #e76f51;
  border-radius: 8px;
  color: #001233;
  border: none;
}
.customAddButton:hover {
  border: none !important;
  background: #e76f51cc !important;
  color: #001233 !important;
}

.addEmployee {
  color: #001233;
  font-weight: bold;
}

.newcustomAddButton {
  background: #e76f51;
  border-radius: 8px;
  color: #001233;
  margin-top: 1rem;
  border: none;
}
.newcustomAddButton:hover {
  border: none !important;
  background: #e76f51cc !important;
  color: #001233 !important;
}

.newaddEmployee {
  color: #001233;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/EmployeeData/employeeDataNavBar.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,gCAAgC;EAChC,yBAAyB;AAC3B;;AAEA;EACE,cAAc;EACd,iBAAiB;AACnB","sourcesContent":[".employee {\n  font-style: normal;\n  font-weight: bold;\n  font-size: 22px;\n  line-height: 32px;\n  color: #001233;\n}\n.customAddButton {\n  background: #e76f51;\n  border-radius: 8px;\n  color: #001233;\n  border: none;\n}\n.customAddButton:hover {\n  border: none !important;\n  background: #e76f51cc !important;\n  color: #001233 !important;\n}\n\n.addEmployee {\n  color: #001233;\n  font-weight: bold;\n}\n\n.newcustomAddButton {\n  background: #e76f51;\n  border-radius: 8px;\n  color: #001233;\n  margin-top: 1rem;\n  border: none;\n}\n.newcustomAddButton:hover {\n  border: none !important;\n  background: #e76f51cc !important;\n  color: #001233 !important;\n}\n\n.newaddEmployee {\n  color: #001233;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
