// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDeleteButton {
  background: transparent;
  border-color: #bc0000;
  font-weight: bold;
  color: #bc0000;
}
.customDeleteButton:hover {
  border: none !important;
  background: #e76f51 !important;
  color: #001233 !important;
}
`, "",{"version":3,"sources":["webpack://./src/components/New/EmployeeData/editEmployee.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,iBAAiB;EACjB,cAAc;AAChB;AACA;EACE,uBAAuB;EACvB,8BAA8B;EAC9B,yBAAyB;AAC3B","sourcesContent":[".customDeleteButton {\n  background: transparent;\n  border-color: #bc0000;\n  font-weight: bold;\n  color: #bc0000;\n}\n.customDeleteButton:hover {\n  border: none !important;\n  background: #e76f51 !important;\n  color: #001233 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
