import React, { useState } from "react";
import HeaderNav from "../Navbar/HeaderNav";
import ProjectNavbar from "./ProjectNavbar";
import ProjectDataFilter from "./ProjectDataFilter";
import ProjectList from "./ProjectList";

function ProjectDashboard() {
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [searchQuery, setSearchQuery] = useState("");

  const handleStatusSelect = (data) => {
    setSelectedStatus(data);
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const handleClearFilters = () => {
    setSelectedStatus("active");
    setSearchQuery("");
  };

  return (
    <>
      <HeaderNav />
      <ProjectNavbar />
      <ProjectDataFilter
        onStatusSelect={handleStatusSelect}
        selectedStatus={selectedStatus}
        onSearch={handleSearch}
        searchQuery={searchQuery}
        onClearFilters={handleClearFilters}
      />
      <ProjectList selectedStatus={selectedStatus} searchQuery={searchQuery} />
    </>
  );
}

export default ProjectDashboard;
